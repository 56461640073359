import { RootState } from '@/store/types';
import { FilterGetterTree } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/getters';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';

export const getters = new (class extends FilterGetterTree<FilterState, RootState> {
  /**
   * Determina se o filtro avançado está aberto ou fechado.
   *
   * @param state
   * @return {boolean}
   */
  isAdvancedFilterOpen = (state: FilterState): boolean => {
    return state.isAdvancedFilterOpen;
  };
})();

export default getters;
