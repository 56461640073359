import Vue from 'vue';
import { ActionTree } from 'vuex';
import { HubApiResponse } from '@/components/api/hub-client/http';
import { Account } from '@/entities';

import { RootState } from '../../types';
import { AccountState, PayloadFetch } from './types';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';

export type MarketplaceAccountFinderPayload = {
  storeId: number;
};

const accounts_cache: { [key: string]: Promise<Account> } = {};

export const actions: ActionTree<AccountState, RootState> = {
  /**
   * Busca informaçoes da conta.
   * */
  async fetch({ commit, getters }, { storeId, accountId }: PayloadFetch) {
    const account = getters.getAccount(accountId);
    if (account) {
      return Promise.resolve(account);
    }

    const key = `${storeId}-${accountId}`;
    if (!accounts_cache[key]) {
      accounts_cache[key] = Vue.$apiClient
        .getHttpClient()
        .get<any, HubApiResponse>(`/stores/${storeId}/accounts/${accountId}`)
        .then((response) => {
          const result = response.getData();

          commit('account', { id: accountId, account: result });
          return getters.getAccount(accountId);
        })
        .catch((error) => {
          commit(
            'uiSettings/setNotification',
            handlerErrorActions({
              where: 'Offer advanced filter',
              error,
              toaster: 'b-toaster-top-center',
            }),
            { root: true }
          );
          commit('account', { id: accountId, account: null });
          return null;
        });
    }

    return accounts_cache[key];
  },

  /**
   * Busca todas as contas
   */
  async fetchAll({ commit, getters, rootGetters }): Promise<Account[] | null> {
    const activeMarketplace = rootGetters['marketplace/activeMarketplace'];
    const storeId = rootGetters['store/store'].id.toString();

    return Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/stores/${storeId}/accounts`, {
        params: {
          'filter[marketplace_id]': activeMarketplace.id,
        },
      })
      .then((response) => {
        const accounts = response.getData();

        commit('accounts', accounts);
        return getters.accounts;
      })
      .catch((error) => {
        commit(
          'uiSettings/setNotification',
          handlerErrorActions({
            where: 'Fech all accounts',
            error,
            toaster: 'b-toaster-top-center',
          }),
          { root: true }
        );
        commit('accounts', []);
        return null;
      });
  },

  removeAccount({ commit, getters }, accountId: number) {
    const { accounts } = getters;
    const account = accounts[accountId];

    if (!account) {
      return;
    }
    delete accounts[accountId];

    if (!accounts.length) {
      commit('accounts', []);
      return;
    }

    commit('accounts', accounts);
  },

  /**
   * Busca as contas pendentes com a loja e markeplace fornecidos.
   *
   * @return Promise<Account | null>
   * */
  async findPendingAccount({ rootGetters }, { storeId }: MarketplaceAccountFinderPayload) {
    const activeMarketplace = rootGetters['marketplace/activeMarketplace'];
    const accounts = await Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/stores/${storeId}/accounts`, {
        params: {
          'filter[wizard!=]': 'completed',
          'filter[marketplace_id]': activeMarketplace.id,
        },
      })
      .then(async (response) => {
        return response.getData().map((attributes: any) => Account.from(attributes));
      });

    return accounts[0] || null;
  },

  /**
   * Determina se existem contas cadastradas.
   *
   * @return Promise<boolean>
   * */
  async hasAccounts({ rootGetters }, payload: MarketplaceAccountFinderPayload): Promise<boolean> {
    const activeMarketplace = rootGetters['marketplace/activeMarketplace'];
    const accounts = await Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/stores/${payload.storeId}/accounts`, {
        params: {
          'page[size]': 1,
          'filter[marketplace_id]': +activeMarketplace.id,
        },
      })
      .then(async (response) => {
        return response.getData().map((attributes: any) => Account.from(attributes));
      });

    return accounts.length > 0;
  },
};

export default actions;
