import { Module } from 'vuex';
import { Storage } from '@/components/storage';

import { AuthState } from './types';
import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: AuthState = {
  token: Storage.get('token'),
  user: null,
  processToken: null,
  platform: null,
};

const namespaced = true;

export const authModule: Module<AuthState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
