import Vue from 'vue';
import { ActionTree } from 'vuex';

import { CategoriesState } from './types';
import { RootState } from '../../types';
import { CategoryLevelsFinder } from '@/components/marketplace/api/category-levels-finder';
import { Actions, Getters, Mutations } from './enum';
import Category from '@/entities/category';
import { actionsAttributes } from './actions-attributes';
import { handlerErrorActions } from '../../utils/handler-error-actions';
import { ApiResponse } from '@/components/api/client/http';
import { ListCategoryApi, ParamsToFilterCategoryList } from './type-categories-list';
import { generatesQueryParamsForCategoryList } from '@/pages/configurations/category-relationship/generates-query-params-for-category-list';

export const actions: ActionTree<CategoriesState, RootState> = {
  async [Actions.GET_CATEGORIES_ROOT]({ state, commit }) {
    try {
      if (state.categories.marketplace.root.length > 0) {
        commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING, state.categories.marketplace.root);

        return;
      }

      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, true);

      const categoriesApi = new CategoryLevelsFinder();
      const data = await categoriesApi.all();

      if (!data || data.length === 0) return;

      const categories = data.map((category) => {
        return Category.from(category);
      });

      commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_ROOT, categories);
      commit(Mutations.SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING, []);
      commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING, categories);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET categories-root', error })
      );
    } finally {
      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, false);
    }
  },

  async [Actions.GET_CATEGORY_MARKETPLACE_BY_REF]({ commit, getters }, ref: string) {
    try {
      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, true);
      commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING, []);

      const categoriesApi = new CategoryLevelsFinder();

      const savedCategory = getters[Getters.BY_REF_OF_MARKETPLACE][ref];

      if (savedCategory && savedCategory.has_children) {
        commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING, savedCategory.children);
      }

      if (savedCategory) {
        commit(Mutations.SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING, savedCategory.path_from_root);

        return;
      }
      const data = await categoriesApi.findByRef(ref);

      if (!data) return;

      const categories = [];
      for await (const category of data.children || []) {
        categories?.push(Category.from(category));
      }

      commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_BY_REF, {
        ...data,
        children: categories,
      });

      const isToListTheChildren = categories && categories.length > 0;
      if (isToListTheChildren) {
        commit(Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING, categories);
      }

      commit(Mutations.SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING, data.path_from_root);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET category-by-ref', error })
      );
    } finally {
      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, false);
    }
  },

  async [Actions.POST_BINDING_CATEGORY]({ state, commit, dispatch, rootGetters }) {
    try {
      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, true);

      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;
      const data = state.toBinding.linked.categories;

      const apiUrl = `/marketplaces/${marketplaceId}/categories-bindings`;
      await Vue.$apiClient.getHttpClient().post(apiUrl, data);

      const dataCategory = await dispatch(
        Actions.GET_CATEGORY_PLATFORM_BY_REF,
        data.platform_category?.ref
      );

      if (!dataCategory.linked_category) throw new Error('Category not linked in API');

      commit(Mutations.SET_CATEGORY_PLATFORM_IN_BINDING, dataCategory);
      commit(Mutations.SET_CATEGORY_MARKETPLACE_IN_BINDING, dataCategory);

      commit(
        'uiSettings/setNotification',
        {
          message: 'Relacionamento de categorias salvo com sucesso.',
          title: 'Sucesso',
          variant: 'success',
          autoHideDelay: 5000,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );

      commit(Mutations.CLEAR_CATEGORIES_SELECTION);
      commit(Mutations.SET_LINKED_CHANGED_TO, true);
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action POST category-binding', error })
      );
    } finally {
      commit(Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING, false);
    }
  },

  [Actions.CLEAR_ALL_OF_CATEGORY_BINDING]({ commit }) {
    commit(Mutations.CLEAR_CATEGORY_MARKETPLACE_LINKED);
    commit(Mutations.CLEAR_CATEGORIES_SELECTION);
    commit(Mutations.SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING, []);
  },

  [Actions.CLEAR_ALL_OF_CATEGORY_ATTRIBUTES_BINDING]({ commit }) {
    commit(Mutations.CLEAR_CATEGORIES_BINDING);
    commit(Mutations.CLEAR_ATTRIBUTES_BINDING);

    commit(Mutations.SET_LINKED_CHANGED_TO, false);
  },
  ...actionsAttributes,

  async [Actions.GET_CATEGORIES_LIST](
    { rootGetters, commit },
    params?: ParamsToFilterCategoryList
  ): Promise<ApiResponse> {
    try {
      const queryParams = new URLSearchParams();
      commit(Mutations.SET_LOADING_LIST_CATEGORY, true);
      const storeId = rootGetters['store/store'].id.toString();
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;

      queryParams.append('filter[marketplace_id]', String(marketplaceId));

      let currentQuery;

      if (params) {
        const newQuery = generatesQueryParamsForCategoryList(params?.paging, params?.filtering);

        currentQuery = new URLSearchParams({
          ...Object.fromEntries(newQuery),
          ...Object.fromEntries(queryParams),
        });
      }

      if (!params) {
        currentQuery = new URLSearchParams({
          ...Object.fromEntries(queryParams),
          'page[number]': '1',
          'page[size]': '25',
        });
      }

      const apiUrl = `/stores/${storeId}/platform-categories`;

      const client = Vue.$apiClient.getHttpClient();

      const response = await client.get<ListCategoryApi, ApiResponse>(apiUrl, {
        params: currentQuery,
      });

      commit(Mutations.SAVE_LIST_CATEGORY, response.data);

      return response;
    } catch (error) {
      commit(
        Mutations.SET_ERROR_IN_BINDIG,
        handlerErrorActions({ where: 'Action GET categories-List', error })
      );

      throw error;
    } finally {
      commit(Mutations.SET_LOADING_LIST_CATEGORY, false);
    }
  },

  async [Actions.GET_CATEGORY_PLATFORM_BY_REF]({ rootGetters }, ref: string) {
    const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;
    const storeId = rootGetters['store/store'].id.toString();
    const apiUrlGetCategory = `/stores/${storeId}/platform-categories/${ref}`;

    return Vue.$apiClient
      .getHttpClient()
      .get<Category, ApiResponse>(apiUrlGetCategory, {
        params: {
          'filter[marketplace_id]': marketplaceId,
        },
      })
      .then((response) => {
        return response.getData();
      });
  },

  async [Actions.SET_CATEGORIES_LIST_CREATE_OFFERS]({ commit }, categories: Array<Category>) {
    commit(Mutations.SAVE_LIST_CATEGORIES_CREATE_OFFERS, categories);
  },
};

export default actions;
