import { PluginFunction } from 'vue';
import Pusher from 'pusher-js';
import config from '@/config';
import { PusherWebSocket } from './pusher/pusher-web-socket';

Pusher.logToConsole = true;

const HubWebSocketInstaller: PluginFunction<any> = (vue) => {
  const appKey = config('HUB_PUSHER_APP_KEY');
  const appCluster = config('HUB_PUSHER_APP_CLUSTER');

  const pusher = new PusherWebSocket(
    new Pusher(appKey, {
      cluster: appCluster,
    })
  );

  vue.$hubWs = pusher;
  vue.prototype.$hubWs = pusher;
};

export default HubWebSocketInstaller;
