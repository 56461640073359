import { ActionContext, ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { ShopeeReviewState } from './types';

export const actions: ActionTree<ShopeeReviewState, RootState> = {
  /**
   * Exibe o componente de seleção de categorias da Shopee.
   *
   * @param {{ commit }: ActionContext<ShopeeReviewState, RootState>}
   * @param {any} item
   * @return {void}
   * */
  showCategoryPicker({ commit }: ActionContext<ShopeeReviewState, RootState>, item: any): void {
    commit('setCategoryPickerState', { isActive: true, item });
  },

  /**
   * Oculta o componente de seleção de categorias da Shopee.
   *
   * @param {{ commit }: ActionContext<ShopeeReviewState, RootState>}
   * @return {void}
   * */
  hideCategoryPicker({ commit }: ActionContext<ShopeeReviewState, RootState>): void {
    commit('setCategoryPickerState', { isActive: false, item: null });
  },
};

export default actions;
