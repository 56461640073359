export enum MutationsOrderLabels {
  /**
   * Salva a lista de contas
   * @param {OrderLabelGroup[]} accounts
   */
  SAVE_ACCOUNTS = 'SAVE_ACCOUNTS',

  /**
   * Salva os dados de um marketplace
   * @param {PayloadSaveMarketplace} payload
   */
  SAVE_MARKETPLACE = 'SAVE_MARKETPLACE',

  /**
   * Atualiza os dados de contas em todos marketplaces do lote de etiquetas
   */
  UPDATE_ACCOUNTS_IN_MARKETPLACES = 'UPDATE_ACCOUNTS_IN_MARKETPLACES',

  /**
   * Define se há erros no processo de impressão de etiquetas de pedidos.
   * @param {boolean} hasErrors
   */
  SET_ERROR = 'SET_ERROR',

  /**
   * Define o status do lote de etiquetas a imprimir
   * @param {string} batchStatus
   */
  SET_BATCH_STATUS = 'SET_BATCH_STATUS',

  /**
   * Salva o ID do lote de etiquetas a imprimir
   * @param {string} batchId
   */
  SET_BATCH_ID = 'SET_BATCH_ID',

  /**
   * Define o status de carregamento na página inicial de etiquetas
   * @param {boolean} isLoading
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Limpa todos os dados do módulo de etiquetas
   */
  CLEAR_ALL = 'CLEAR_ALL',
}
