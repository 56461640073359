import { FilterMutationTree } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/mutations';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';

export const mutations = new (class extends FilterMutationTree<FilterState> {
  /**
   * Alterado o estado do filtro avançado.
   *
   * @param {FilterState} state
   * @param {boolean} isOpen
   */
  isAdvancedFilterOpen = (state: FilterState, isOpen: boolean): void => {
    state.isAdvancedFilterOpen = isOpen;
  };
})();

export default mutations;
