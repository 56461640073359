import Vue, { PluginFunction } from 'vue';
import { ToggleManager as ToggleManagerContract } from './typing';
import { ToggleManager } from './toggle-manager/toggle-manager';

export const toggleManager = new ToggleManager();

declare global {
  interface Window {
    $toggle: ToggleManagerContract;
  }
}

const ToggleInstaller: PluginFunction<any> = () => {
  Vue.prototype.$toggle = toggleManager;
  Vue.$toggle = toggleManager;
  window.$toggle = toggleManager;
};

toggleManager.getSubject().subscribe(() => {
  if (toggleManager.isEnabled('hello-world')) {
    console.log(
      '%cFeature toogle is working!',
      'background: #5eba7d; color: #f4f4f4; font-size: 25px; padding: 10px'
    );

    toggleManager.disable('hello-world');
  }
});

export default ToggleInstaller;
