import { StorageConstructor } from './contracts/storage-constructor';

const Storage: StorageConstructor = class {
  /**
   * @inheritDoc
   * */
  static get<T extends string = string>(name: T, $default: any = null): any {
    const value = window.localStorage.getItem(name);
    if (value) {
      try {
        return JSON.parse(value);
      } catch (error) {
        //
      }
      return value;
    }

    return $default;
  }

  /**
   * @inheritDoc
   * */
  static set(name: string, value: any) {
    if (typeof value !== 'string') {
      try {
        value = JSON.stringify(value);
      } catch (error) {
        //
      }
    }
    window.localStorage.setItem(name, value);
  }
};

export default Storage;
