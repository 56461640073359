import { ActionContext } from 'vuex';
import { RootState } from '@/store/types';
import { FilterActionTree } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/actions';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';

export const actions = new (class extends FilterActionTree<FilterState, RootState> {
  /**
   * Abre o filtro avançado.
   *
   * @return void
   * */
  openAdvancedFilter = ({ commit }: ActionContext<FilterState, RootState>): void => {
    commit('isAdvancedFilterOpen', true);
  };

  /**
   * Fecha o filtro avançado.
   *
   * @return void
   * */
  closeAdvancedFilter = ({ commit }: ActionContext<FilterState, RootState>): void => {
    commit('isAdvancedFilterOpen', false);
  };
})();

export default actions;
