import Vue from 'vue';
import { ActionTree } from 'vuex';
import {
  ListOffersInProcessingApi,
  OffersInProcessingState,
  ParamsToFilterOfferProcessingList,
} from './types';
import { RootState } from '../../types';
import { Actions, Mutations } from './enum';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import { ApiResponse } from '@/components/api/client/http';
import { generatesQueryParamsForOffersInProcessing } from '@/pages/marketplaces/new-offers/offers-in-processing/generates-query-params-for-offers-in-processing';
import { QueryParamsForApiRequest } from '@/components/handle-listing/handle-query-params/enum-query-params-api-request';

export const actions: ActionTree<OffersInProcessingState, RootState> = {
  async [Actions.GET_OFFERS_API](
    { commit, rootGetters },
    params?: ParamsToFilterOfferProcessingList
  ): Promise<ApiResponse> {
    try {
      const queryParams = new URLSearchParams();
      const marketplace = rootGetters['marketplace/activeMarketplace'];
      commit(Mutations.SET_LOADING_LIST, true);

      queryParams.append(QueryParamsForApiRequest.MARKETPLACE, String(marketplace.id));
      queryParams.append(`${QueryParamsForApiRequest.CREATION_STATE}[]`, 'creating');
      queryParams.append(
        `${QueryParamsForApiRequest.CREATION_STATE}[]`,
        'waiting_marketplace_approval'
      );
      queryParams.append(QueryParamsForApiRequest.HAS_PENDING_ISSUES, '0');
      queryParams.append(QueryParamsForApiRequest.SORT, '-created_at');

      const paging = params?.paging ?? { size: 25, number: 1 };

      const currentQuery = generatesQueryParamsForOffersInProcessing(
        paging,
        params?.filtering,
        queryParams
      );

      const apiUrl = `/offers`;

      const client = Vue.$apiClient.getHttpClient();

      const response = await client.get<ListOffersInProcessingApi, ApiResponse>(apiUrl, {
        params: currentQuery,
      });

      commit(Mutations.SAVE_LIST_OFFERS_PROCESSING, response.data);

      return response;
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        handlerErrorActions({
          where: 'Action GET all-offers-in-processing',
          error,
          toaster: 'b-toaster-top-center',
        }),
        { root: true }
      );
      throw error;
    } finally {
      commit(Mutations.SET_LOADING_LIST, false);
    }
  },
};
