export enum Actions {
  /**
   * Define a listagem de Ofertas em processamento.
   */
  GET_OFFERS_API = 'GET_OFFERS_API',
}

export enum Mutations {
  /**
   * Salva status carregamento da listagem.
   */
  SET_LOADING_LIST = 'SET_LOADING_LIST',
  /**
   * Salva a lista de ofertas em processamento.
   */
  SAVE_LIST_OFFERS_PROCESSING = 'SAVE_LIST_OFFERS_PROCESSING',
}

export enum Getters {
  /**
   * Retorna o status de carregamento da listagem.
   */
  IS_LOADING = 'IS_LOADING',
  /**
   * Lista das ofertas com erro para listagem.
   */
  OFFERS_PROCESSING_LIST_CONTENT = 'OFFERS_PROCESSING_LIST_CONTENT',
  /**
   * Paginação da listagem de ofertas.
   */
  OFFERS_LIST_PAGINATE = 'OFFERS_LIST_PAGINATE',
}
