import { ActionContext, ActionTree } from 'vuex';

import { Theme, UiSettingsState } from './types';
import { RootState } from '../../types';

export const actions: ActionTree<UiSettingsState, RootState> = {
  /**
   * Define o tema da loja.
   * */
  setTheme({ commit }: ActionContext<UiSettingsState, RootState>, theme: Theme): void {
    commit('setTheme', theme);
  },
};

export default actions;
