import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { AttributesState, AddAttrPayload, RemoveAttrPayload } from './types';

export const actions: ActionTree<AttributesState, RootState> = {
  /**
   * Define os atributos condicionais.
   * */
  setAttributes({ commit }, payload: AddAttrPayload): void {
    commit('setAttributes', payload);
  },

  /**
   * Define os atributos condicionais.
   * */
  addAttributes({ commit }, payload: AddAttrPayload): void {
    commit('addAttributes', payload);
  },

  removeAttributes({ commit }, ids: Array<RemoveAttrPayload>): void {
    commit('removeAttributes', ids);
  },
};

export default actions;
