import Vue from 'vue';
import store from '@/store';
import vuex from '@/store';
import router from '@/router';
import { ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { Account, Marketplace } from '@/entities';
import { HubApiResponse } from '@/components/api/hub-client/http';
import { PagesName } from '@/router/routes/pages-enum';
import { accountInstalledAndComplete } from '@/pages/wizard/wizard-new/wizard-utils';
import { WizardState } from './types';
import { WizardActions, Mutations, Getters } from './enum';

type InitializerPayload = {
  storeId: number;
  marketplaceRef: string;
};

type NewAccountPayload = {
  storeId: number;
  marketplaceId: number;
};

type DeleteAccountPayload = {
  accountId: number;
  storeId: number;
};

export const actions: ActionTree<WizardState, RootState> = {
  [WizardActions.FRESH]({ commit }) {
    commit(Mutations.SET_NEW_ACCOUNT, null);
    commit(Mutations.SET_MARKETPLACE, null);
    commit(Mutations.SET_OFFER_IMPORT, null);
    commit(Mutations.SET_WIZARD_CONFIG, null);
    commit(Mutations.SET_ACTIVE_STEP, null);
  },

  async [WizardActions.INIT](
    { commit, dispatch },
    { storeId, marketplaceRef }: InitializerPayload
  ): Promise<void> {
    const isMultiAccountFlow = Vue.$toggle.isEnabled('multi-contas-listagem');

    const marketplace = await dispatch(WizardActions.FIND_MARKETPLACE, marketplaceRef);

    if (!isMultiAccountFlow && accountInstalledAndComplete()) {
      return;
    }

    // Se tem a instalação de uma conta em andamento, retornamos a conta.
    const pendingAccount = await dispatch(
      'account/findPendingAccount',
      {
        storeId,
        marketplaceId: marketplace.id,
      },
      { root: true }
    );
    if (pendingAccount) {
      commit(Mutations.SET_NEW_ACCOUNT, pendingAccount);
      return;
    }

    if (isMultiAccountFlow) {
      return;
    }
  },

  async [WizardActions.FIND_MARKETPLACE](
    { commit, getters },
    marketplaceRef: string
  ): Promise<Marketplace> {
    const marketplace = getters[Getters.MARKETPLACE];
    if (marketplace && marketplace.name === marketplaceRef) {
      return Promise.resolve(marketplace);
    }

    return Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/marketplaces/${marketplaceRef}`)
      .then((response) => {
        const marketplace = Marketplace.from(response.getData());
        commit(Mutations.SET_MARKETPLACE, marketplace);

        return marketplace;
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 404) {
          store.commit('uiSettings/setNotification', {
            where: 'Wizard Account Find Marketplace',
            error,
            toaster: 'b-toaster-top-center',
          });
        }

        throw new Error('Server error');
      });
  },

  async [WizardActions.MAKE_NEW_ACCOUNT](
    { commit },
    { storeId, marketplaceId }: NewAccountPayload
  ) {
    return Vue.$apiClient
      .getHttpClient()
      .post<any, HubApiResponse>(`/stores/${storeId}/accounts`, {
        marketplace_id: marketplaceId,
      })
      .then(async (response) => {
        const account = Account.from(response.getData());
        commit(Mutations.SET_NEW_ACCOUNT, account);

        return account;
      })
      .catch((error) => {
        store.commit('uiSettings/setNotification', {
          where: 'Wizard Account Make New Account',
          error,
          toaster: 'b-toaster-top-center',
        });
      });
  },

  async [WizardActions.DELETE_ACCOUNT]({ commit }, { accountId, storeId }: DeleteAccountPayload) {
    return Vue.$apiClient
      .getHttpClient()
      .delete<any, HubApiResponse>(`stores/${storeId}/accounts/${accountId}`)
      .then(async () => {
        router.push({ name: PagesName.PUBLISHED_OFFERS });
        return;
      })
      .catch((error) => {
        store.commit('uiSettings/setNotification', {
          where: 'Wizard Account Delete Account',
          error,
          toaster: 'b-toaster-top-center',
        });
      });
  },
};
