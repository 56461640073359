import { ActionContext, ActionTree } from 'vuex';

import { RootState } from '@/store/types';
import { OfferRelationState, OfferRelationStep, ParamsVariants } from './types';
import { Offer, OfferRelation, Product, ProductVariant } from '@/entities';
import Vue from 'vue';
import { AxiosResponse } from 'axios';
import store from '@/store';

export const actions: ActionTree<OfferRelationState, RootState> = {
  /**
   * Inicializa a Oferta a zera os demais atributos.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {Offer} offer
   * @return {void}
   *
   * */
  init({ commit }: ActionContext<OfferRelationState, RootState>, offer: Offer): void {
    commit('setOffer', offer);
    commit('setProduct', null);
    commit('setRelation', null);
    commit('setError', null);
  },

  /**
   * Insere a oferta na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {Offer} offer
   * @return {void}
   *
   * */
  setOffer({ commit }: ActionContext<OfferRelationState, RootState>, offer: Offer | null): void {
    commit('setOffer', offer);
  },

  /**
   * Insere o produto na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {Product | null} product
   * @return {void}
   * */
  setProduct(
    { commit }: ActionContext<OfferRelationState, RootState>,
    product: Product | null
  ): void {
    commit('setProduct', product);
  },

  /**
   * Insere o relacionamento na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {OfferRelation | null} relation
   * @return {void}
   * */
  setRelation({ commit }, relation: OfferRelation | null) {
    commit('setRelation', relation);
  },

  /**
   * Insere a possibilidade de salvar o relacionamento na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {boolean} isEnabled
   * @return {void}
   * */
  setSaveActionEnabled({ commit }, isEnabled: boolean) {
    commit('setSaveActionEnabled', isEnabled);
  },

  /**
   * Insere o passo da view na Store.
   *
   * @param {{ commit }: ActionContext<OfferRelationState, RootState>}
   * @param {OfferRelationStep} step
   * @return {void}
   * */
  setRelationStep({ commit }, step: OfferRelationStep) {
    commit('setRelationStep', step);
  },

  /**
   * Realiza o fetch para retornar as variações
   * do produto para o relacionamento.
   */
  async fetchVariantsProduct({ commit }, { storeId, productId }: ParamsVariants) {
    await Vue.$apiClient
      .getHttpClient()
      .get(`stores/${storeId}/products/${productId}/variants`)
      .then((result: AxiosResponse) => {
        const optionsVariants = result.data.data.map((item: any) => {
          const productVariant = ProductVariant.from(item);

          productVariant.optionLabel = productVariant.getDisplayValueName();

          return productVariant;
        });

        commit('setVariantsOptions', optionsVariants);
      })
      .catch((error) => {
        store.commit('uiSettings/setNotification', {
          where: 'Response variants products',
          error,
          toaster: 'b-toaster-top-right-center',
        });
      });
  },
};

export default actions;
