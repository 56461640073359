import AuthenticatedUser from '@/entities/authenticated-user';

export type ProcessToken = {
  token: string;
  expires_at: string;
};

export enum Platform {
  TRAY = 'Tray',
  BAGY = 'Bagy',
}

export interface AuthState {
  token: string | null;
  processToken: ProcessToken | null;
  user: AuthenticatedUser | null;
  platform: Platform.TRAY | Platform.BAGY | null;
}
