import { Channel as Dispatcher } from 'pusher-js';
import { Callback, Channel, Handler } from '../contracts/web-socket';

export class PusherChannel<T> implements Channel<T> {
  /**
   * Instância do canal do pacote Pusher js.
   *
   * @param {Dispatcher} dispatcher
   * */
  protected dispatcher: Dispatcher;

  /**
   * PusherChannel's constructor.
   *
   * @param {Dispatcher} pusherChannel
   * */
  constructor(pusherChannel: Dispatcher) {
    this.dispatcher = pusherChannel;
  }

  /**
   * return a instância do canal oriunda do pacote Pusher js.
   *
   * @return Dispatcher
   * */
  getDispatcher(): Dispatcher {
    return this.dispatcher;
  }

  /**
   * @inheritDoc
   * */
  bind(event: string, callback: Callback<T>): this {
    this.dispatcher.bind(event, callback);

    return this;
  }

  /**
   * @inheritDoc
   * */
  unbind(event?: string, handler?: Handler<T>): this {
    this.dispatcher.unbind(event, handler);

    return this;
  }
}
