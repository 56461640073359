/**
 * Enum como todos parâmetros de consulta usados na requisição de API de listagem.
 *
 * Observação: o CREATION_STATE precisa ser um array quando utilizado com multiplos
 * valores usando o sufixo `[]`.
 * Ex: queryParams.set(QueryParamsForApiRequest.CREATION_STATE + '[]', 'value1');
 */
export enum QueryParamsForApiRequest {
  PAGE = 'page[number]',
  SIZE = 'page[size]',
  SORT = 'sort',
  TITLE = 'filter[title]',
  CREATION_STATE = 'filter[creation_state]',
  FULLPATH = 'filter[fullpath]',
  IS_LINKED = 'filter[is_linked]',
  NAME = 'filter[name]',
  SLUG = 'filter[slug]',
  PRODUCT = 'filter[product]',
  PRODUCT_ID = 'filter[product_id]',
  STATUS = 'filter[status]',
  NOT_STATUS = 'filter[status!=]',
  PLATFORM_DISPATCHING_STATE = 'filter[platform_dispatching_state]',
  MARKETPLACE = 'filter[marketplace]',
  CODE = 'filter[code]',
  HAS_PENDING_ISSUES = 'filter[has_pending_issues]',
  TAGS = 'filter[tags]',
}
