import Vue from 'vue';
import { NavigationGuard } from 'vue-router/types/router';
import { Marketplace, Store } from '@/entities';
import vuex from '@/store';
import router from '@/router';
import { MarketplaceNotFoundError } from '@/store/modules/wizard/old-wizard/actions';
import { PagesName } from '@/router/routes/pages-enum';
import { WizardActions as Actions, Getters } from '@/store/modules/wizard/enum';
import { GetterMarketplaces } from '@/store/modules/marketplaces/enum-getter';
import { ActionMarketplaces } from '@/store/modules/marketplaces/enum-action';
import { accountInstalledAndComplete } from '@/pages/wizard/wizard-new/wizard-utils';

/**
 * Guard referente ao novo fluxo do Wizard. Valida os passos e redirecionamentos necessários.
 */
export const ValidatesWizardStep: NavigationGuard = async (to, from, next) => {
  try {
    const isMultiAccountFlow = Vue.$toggle.isEnabled('multi-contas-listagem');
    if (isMultiAccountFlow) {
      const configsMarketplaceError = await vuex.dispatch(
        'marketplaces/' + ActionMarketplaces.CONFIGS_CURRENT_MARKETPLACE
      );

      if (configsMarketplaceError) {
        return next({ name: PagesName.INTERNAL_ERROR });
      }
    }
    const hasReachedAccountLimit =
      vuex.getters['marketplaces/' + GetterMarketplaces.REACHED_ACCOUNT_LIMIT];

    const activeMarketplace = vuex.getters['marketplace/activeMarketplace'] as Marketplace;

    if (isMultiAccountFlow && hasReachedAccountLimit) {
      router.push({
        name: PagesName.LEGACY_ACCOUNTS,
        params: { marketplace: activeMarketplace.name },
      });

      setTimeout(() => {
        vuex.commit('uiSettings/setNotification', {
          where: 'Wizard Guard Reaches Account Limit',
          message:
            'Você atingiu o limite de contas para este marketplace. Não é possível criar mais contas.',
          title: 'Atenção! Limite de contas atingido',
          variant: 'warning',
          toaster: 'b-toaster-top-center',
        });
      }, 1000);

      return;
    }

    const marketplaceRef = vuex.getters['marketplace/activeMarketplaceRef'];
    const store = vuex.getters['store/store'] as Store;
    await vuex.dispatch('newWizard/' + Actions.INIT, {
      storeId: store.id,
      marketplaceRef,
    });

    const accountInCreation = vuex.getters['newWizard/' + Getters.NEW_ACCOUNT];

    if (isMultiAccountFlow && !accountInCreation) {
      return next();
    }

    const accounts = vuex.getters['account/accounts'];
    if (!isMultiAccountFlow && accountInstalledAndComplete()) {
      return router.push({
        name: PagesName.LEGACY_PUBLISHED_OFFERS,
        params: { marketplace: activeMarketplace.name },
      });
    }

    const hasAccounts = accounts && accounts.length > 0;
    if (!isMultiAccountFlow && !hasAccounts) {
      return next();
    }

    if (accountInCreation.isCompleting() && to.name !== PagesName.WIZARD_RELATE) {
      return router.push({ name: PagesName.WIZARD_RELATE, params: to.params });
    }

    if (
      (accountInCreation.isImporting() || accountInCreation.isImported()) &&
      to.name !== PagesName.WIZARD_IMPORT
    ) {
      return router.push({ name: PagesName.WIZARD_IMPORT, params: to.params });
    }

    const wizardIsAuthenticated =
      accountInCreation.isAuthenticating() && accountInCreation.isAuthenticated();

    if (wizardIsAuthenticated && to.name !== PagesName.WIZARD_STATUS) {
      return router.push({ name: PagesName.WIZARD_STATUS, params: to.params });
    }

    const wizardIsAuthenticatingOrStarting =
      (accountInCreation.isAuthenticating() && !accountInCreation.isAuthenticated()) ||
      accountInCreation.isStarting();

    if (wizardIsAuthenticatingOrStarting && to.name !== PagesName.WIZARD_AUTH) {
      return router.push({ name: PagesName.WIZARD_AUTH, params: to.params });
    }

    next();
  } catch (error) {
    if (error instanceof MarketplaceNotFoundError) {
      // Se o marketplace ou conta não existem
      return next({ name: 'not-found', query: { from: to.fullPath } });
    }
    next({ name: 'internal-error' });
  }
};
