import { MutationTree } from 'vuex';
import { PendingSalesListState } from './types';
import { MutationPendingSales } from './enum-mutation';
import { Order } from '@/entities';

export const mutations: MutationTree<PendingSalesListState> = {
  [MutationPendingSales.SAVE_LIST](state, list: Order[]) {
    state.list = list;
  },

  [MutationPendingSales.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
};

export default mutations;
