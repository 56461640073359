import { PluginFunction } from 'vue';
import { makeWindowSizeObservable } from './index';

const WindowSizeDetectInstaller: PluginFunction<any> = (vue) => {
  const $windowSize = makeWindowSizeObservable();
  vue.$windowSize = $windowSize;
  vue.prototype.$windowSize = $windowSize;
};

export default WindowSizeDetectInstaller;
