import { NavigationGuard } from 'vue-router';
import vuex from '@/store';
import { Store } from '@/entities';
import router from '@/router';

export const CheckPendingOrder: NavigationGuard = async (to, from, next) => {
  try {
    const store = vuex.getters['store/store'] as Store;
    await vuex.dispatch('orders/getQuantityPendingOrders', store.id);
    const quantityPendingOrders = vuex.getters['orders/quantityPendingOrders'];

    const cancelRedirectToTest = to.query.ignore_redirect === 'true';

    if (
      quantityPendingOrders > 0 &&
      to.name !== 'new-pending-orders' &&
      to.name !== 'marketplaces-pending-orders' &&
      !cancelRedirectToTest
    ) {
      return router.push({ name: 'marketplaces-pending-orders', params: to.params });
    }

    next();
  } catch (e) {
    return next();
  }
};
