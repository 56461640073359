export enum GetterFilterByRoute {
  /**
   * Retorna os filtros atuais pelo nome da rota passado por parâmetro.
   */
  ALL_FILTERS = 'ALL_FILTERS',

  /**
   * Retorna o filtro atual pelo nome da rota e chave passado por parâmetro.
   */
  FILTER_BY_KEY = 'FILTER_BY_KEY',
}
