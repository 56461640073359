import moment from 'moment';
import { Installer, Locale } from './installer';

export class MomentInstaller implements Installer {
  /**
   *  @inheritDoc
   */
  getLocale(locale: Locale): any {
    return import(`../locales/${locale}/moment-locale.ts`);
  }

  /**
   *  @inheritDoc
   */
  setLocale(locale: Locale): void {
    this.getLocale(locale).then((module: any) => {
      moment.locale(locale);
      // Define a formatação de horários e datas para o português
      moment.locale(locale);
      moment.updateLocale(locale, module.default);
    });
  }
}

export default MomentInstaller;
