/**
 * Enum com os nomes das páginas do app
 * Toda página do fluxo antigo deve ter o sufixo V1.
 */
export enum PagesName {
  /** Rota usada para validar qual página de anúncios irá abrir  */
  LOAD_OFFERS = 'offers',

  /** Anúncios publicados */
  PUBLISHED_OFFERS = 'new-published-offers',

  /** Anúncios em processamento */
  OFFERS_IN_PROCESSING = 'offers-in-processing',

  /** Anúncios publicados */
  WAITING_CORRECTION = 'offers-waiting-correction',

  /** Lista de vendas pendentes sem relaciomaneto de oferta */
  PENDING_SALES = 'new-pending-orders',

  /** Anúncios aguardando correção */
  OFFERS_AWAITING_CORRECTION = 'awaiting-correction-of-offers',

  /** Página para criação de anúncios */
  OFFERS_ADD = 'add-offer',

  /** Relacionamento de categorias */
  CATEGORY_RELATIONSHIP = 'category-relationship',

  /** Lista de contas */
  ACCOUNTS = 'accounts',

  /** Rota de inicialização do APP */
  INIT = 'init',

  /** Rota de autenticação do front, que valida a página a carregar */
  AUTH = 'auth',

  /** Nova landing paging */
  LETS_START = 'lets-start',

  /** Lista de marketplaces */
  MARKEPLACES_LIST = 'marketplaces-list',

  /** Página de geração de etiquetas */
  ORDERS_LABELS = 'orders-labels',

  /** Página de erro 500 */
  INTERNAL_ERROR = 'internal-error',

  /** Página de usuário não autorizado */
  UNAUTHORIZED = 'unauthorized',

  /** Página não encontrada */
  NOT_FOUND = 'not-found',

  /** Página principal do marketplace */
  MARKETPLACE_MAIN = 'marketplace-main',

  /** Página de autenticação com o marketplace */
  WIZARD_AUTH = 'wizard-auth',

  /** Página com o status da autenticação com o marketplace */
  WIZARD_STATUS = 'wizard-status',

  /** Página de importação de anúncios */
  WIZARD_IMPORT = 'wizard-import',

  /** Página de sincronização de preços */
  WIZARD_PRICE_SYNC = 'wizard-price-sync',

  /** Página de relacionamento de anúncios */
  WIZARD_RELATE = 'wizard-relate',

  /** Página de listagem de anúncios para RELACIONAMENTO DE ANÚNCIOS */
  /* eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values */
  WIZARD_OFFER_RELATION = 'offers',

  /** Página de listagem de categorias para RELACIONAMENTO DE CATEGORIAS */
  /* eslint-disable-next-line @typescript-eslint/no-duplicate-enum-values */
  WIZARD_CATEGORY_RELATION = 'category-relationship',

  /** Página de landing page com informações do marketplace */
  LEGACY_LANDING_PAGE = 'landing-page',

  /** Anúncios ativos no fluxo antigo */
  LEGACY_PUBLISHED_OFFERS = 'published-offers',

  /** Página de contas no fluxo antigo */
  LEGACY_ACCOUNTS = 'marketplaces-accounts',

  /** Página de pedidos pendentes no fluxo antigo */
  LEGACY_PENDING_ORDERS = 'marketplaces-pending-orders',

  /** Página de anúncios aguardando correção no fluxo antigo */
  LEGACY_ADD_OFFER = 'catalog-add-offer',

  /** Página de seleção de produtos no fluxo antigo */
  LEGACY_ADD_OFFER_PRODUCTS = 'catalog-add-offer-products',

  /** Página de revisão de anúncios no fluxo antigo */
  LEGACY_ADD_OFFER_REVIEW = 'catalog-add-offer-review',

  /** Página de calculadora de preços no fluxo antigo */
  LEGACY_PRICING = 'catalog-pricing',

  /** Página de detalhes do marketplace */
  LEGACY_WIZARD_ABOUT = 'wizard-about',

  /** Página de autenticação do marketplace */
  LEGACY_WIZARD_AUTHENTICATION = 'wizard-authentication',

  /** Página de importação de anúncios */
  LEGACY_WIZARD_IMPORTING = 'wizard-importing',

  /** Página de finalização do wizard */
  LEGACY_WIZARD_COMPLETING = 'wizard-completing',
}
