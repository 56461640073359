import { RootState } from '@/store/types';
import { PendingSalesListState } from './types';
import { Module } from 'vuex';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';
import { FiltersPendingSales } from '@/pages/marketplaces/new-offers/pending-sales/list/pending-filter/filter-type';

export const emptyFiltersPendingSales: FiltersPendingSales = {
  clientName: '',
  clientDocument: '',
  account: '',
  order: '',
  dateInit: '',
  dateFinish: '',
};

/**
 * Estado vuex do módulo de ofertas publicadas pertencentes ao new-flow.
 */
export const state: PendingSalesListState = {
  list: [],
  isLoading: false,
};

const namespaced = true;

export const PendingSales: Module<PendingSalesListState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
