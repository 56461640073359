import { RootState } from '@/store/types';
import { OrderLabelByMarketplace, OrderLabelsState } from './types';
import { GetterTree } from 'vuex';
import { GetterOrderLabels } from './getters-enum';
import { OrderLabelsGroupStatus } from '@/pages/sales/orders-labels/order-labels-status.enum';
import { OrderLabelGroup, OrderLabelItem } from '@/entities';

export const getters: GetterTree<OrderLabelsState, RootState> = {
  [GetterOrderLabels.ALL_MARKETPLACES](state): any {
    return state.marketplaces;
  },

  [GetterOrderLabels.HAS_ERRORS](state): boolean {
    return state.hasErrors;
  },

  [GetterOrderLabels.COMPILED_STATUS_OF_ALL_ACCOUNTS](state): string {
    const allErrorsOfAccounts = state.marketplaces.flatMap((marketplace: OrderLabelByMarketplace) =>
      marketplace.accounts.flatMap((account) => account.status)
    );

    const isCompleted = (status: string) => status === OrderLabelsGroupStatus.COMPLETED;

    if (allErrorsOfAccounts.every(isCompleted)) {
      return OrderLabelsGroupStatus.COMPLETED;
    }

    const isCanceled = (status: string) => status === OrderLabelsGroupStatus.CANCELED;
    const isCanceledOrCompleted = (status: string) => isCanceled(status) || isCompleted(status);

    if (allErrorsOfAccounts.every(isCanceledOrCompleted)) {
      return OrderLabelsGroupStatus.CANCELED;
    }

    return OrderLabelsGroupStatus.PENDING;
  },

  [GetterOrderLabels.LIST_OF_ALL_LABELS](state): OrderLabelItem[] {
    return state.marketplaces?.flatMap((marketplace: OrderLabelByMarketplace) => {
      return marketplace.accounts?.flatMap((account: OrderLabelGroup) => {
        return account.items;
      });
    });
  },

  [GetterOrderLabels.BATCH_STATUS](state): string {
    return state.batchStatus;
  },

  [GetterOrderLabels.BATCH_ID](state): string {
    return state.batchId;
  },

  [GetterOrderLabels.IS_LOADING](state): boolean {
    return state.isLoading;
  },
};
