export enum GetterPendingSales {
  /**
   * Retorna se a lista de anúncios está carregando
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Retorna todos os anúncios publicados.
   */
  LIST = 'LIST',

  /**
   * Verifica se existe algum campo preenchido no filtro da página.
   */
  HAS_FILTERS_APPLY = 'HAS_FILTERS_APPLY',
}
