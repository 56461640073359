import { Module } from 'vuex';
import { Storage } from '@/components/storage';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';
import { RootState } from '@/store/types';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const state: FilterState = {
  /**
   * Contexto de lista de contas.
   */
  context: 'account-list',

  /**
   * Ordenação da lista de contas.
   * */
  sort: Storage.get('account-sorting') || '-id',

  /**
   * Determina se o filtro avançado está aberto.
   */
  isAdvancedFilterOpen: false,

  /**
   * Número de itens por paginação.
   * */
  pageSize: +Storage.get('account-paging') || 25,

  /**
   * Número da página.
   * */
  pageNumber: 1,

  /**
   * Lista de possíveis filtros da lista de contas.
   *
   * */
  instances: {},
};

const namespaced = true;

export const FiltersModule: Module<FilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
