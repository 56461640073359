export enum MutationListPublishedOffers {
  /**
   * Salva a lista de anúncios publicados passada por parâmetro.
   * @param {Offers[]} list - Lista de anúncios publicados a salvar
   */
  SAVE_LIST = 'SAVE_LIST',

  /**
   * Seleciona o anúncio passado por parâmetro.
   * @param {Offers[]} offer - anúncio a ser selecionado
   */
  SELECT_OFFER = 'SELECT_OFFER',

  /**
   * Seleciona ou remove a seleção de todos anúncios da lista.
   */
  SELECT_OR_DESELECT_ALL = 'SELECT_OR_DESELECT_ALL',

  /**
   * Altera o estado de seleção de todos itens da lista de anúncios publicados.
   * @param {boolean} selected - Estado de seleção a definir para os itens da lista
   */
  SET_SELECTION_AS = 'SET_SELECTION_AS',

  /**
   * Altera o estado de carregamento da lista de anúncios publicados.
   * @param {boolean} isLoading - Estado de carregamento da lista de anúncios publicados
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Reseta os filtros da lista de anúncios publicados.
   */
  RESET_FILTERS = 'RESET_FILTERS',

  /**
   * Salva os filtros da lista de anúncios publicados.
   * @param {FiltersPublishedOffers} filters - Filtros da lista de anúncios publicados
   */
  SET_FILTERS = 'SET_FILTERS',

  /**
   * Define a precificação do anúncio a ser editado.
   * @param {PricingRule} pricing - Precificação do anúncio a ser editada
   */
  SET_PRICING_TO_EDIT = 'SET_PRICING_TO_EDIT',

  /**
   * Salva uma lista de ids do anúncio a ser editado.
   * @param {number[]} idsOffer - lista com ids do anúncio a ser editado
   */
  SET_PRICING_LIST_IDS_OFFER = 'SET_PRICING_LIST_IDS_OFFER',

  /**
   * Reseta a vuex com a precificação de anúncios.
   */
  RESET_PRICING = 'RESET_PRICING',

  /**
   * Recarrega a lista de anúncios publicados.
   * @param {boolean} reloadList - quando true recarrega a lista de anúncios publicados
   */
  SET_RELOAD_LIST = 'SET_RELOAD_LIST',
}
