import moment, { Moment } from 'moment';
import Entity from './entity';
import OfferPricing from './offer-pricing';

export type AccountWizardState =
  | 'starting'
  | 'authenticating'
  | 'importing'
  | 'imported'
  | 'completing'
  | 'completed';

export type AccountIdAndDisplayName = {
  id: number;
  name: string;
};

export interface AccountDetails {
  status: string;
  integration_expire_time: string;
}

export default class Account extends Entity<Account> {
  /**
   * Id do marketplace.
   * */
  marketplace_id!: number;

  /**
   * Id da loja.
   * */
  store_id!: number;

  /**
   * Apelido com o marketplace.
   * */
  mkt_shop_id: number | null = null;

  /**
   * Nome de exibição da conta.
   * */
  display_name: string | null = null;

  /**
   * Determina se a conta está autenticada.
   * 0 = não autenticada
   * 1 = autenticada
   * */
  authenticated!: number;

  /**
   * Data e hora de autenticação da conta.
   */
  authenticated_at!: string | null;

  /**
   * Data e hora de expiração da autenticação da conta.
   */
  expires_at!: string | null;

  /**
   * Passo que a conta está.
   * */
  wizard!: AccountWizardState;

  /**
   * Regra de precificação por conta.
   * Disponível apenas no new-flow
   */
  pricing: OfferPricing | null = null;

  /**
   * Detalhes da conta contendo o status e período de expiração
   */
  details!: string | object | null;

  setAuthenticated() {
    this.authenticated = 1;
  }

  setUnauthenticated() {
    this.authenticated = 0;
  }

  isAuthenticated(): boolean {
    return +this.authenticated === 1;
  }

  isStarting(): boolean {
    return this.wizard === 'starting';
  }

  isAuthenticating(): boolean {
    return this.wizard === 'authenticating';
  }

  isImporting(): boolean {
    return this.wizard === 'importing';
  }

  isImported(): boolean {
    return this.wizard === 'imported';
  }

  isCompleting(): boolean {
    return this.wizard === 'completing';
  }

  isWizardCompleted(): boolean {
    return this.wizard === 'completed';
  }

  /**
   * Retorna o nome de exibição da conta ou a referência da loja no marketplace.
   *
   * @var string
   * */
  getDisplayName(): string {
    return this.display_name || this.mkt_shop_id?.toString() || '';
  }

  getIdAndDisplayName(): AccountIdAndDisplayName {
    const name = this.getDisplayName();
    const id = this.marketplace_id;

    return {
      name,
      id,
    };
  }

  /**
   * Retorna a data de expiração da conta formatada com o padrão retornado na API, e
   * como um objeto Moment que pode ser manipulado.
   */
  expirationDate(): Moment {
    return moment(this.expires_at, 'YYYY-MM-DD HH:mm:ss');
  }

  /**
   * Está no período de expiração de 30 dias para a conta do marketplace Shopee.
   */
  isInTheShopeeExpirationPeriod(): boolean {
    if (!this.expires_at) return false;

    const expirationDate = this.expirationDate();

    const now = moment();
    const expirationMinusThirty = expirationDate.clone().subtract(30, 'days');

    return now.isBetween(expirationMinusThirty, expirationDate);
  }

  /**
   * A conta do marketplace Shopee está expirada validando pela data de expiração.
   */
  isShopeeExpirated(): boolean {
    if (!this.expires_at) return false;

    const now = moment();

    return now.isSameOrAfter(this.expirationDate());
  }
}
