export enum WizardActions {
  /**
   * Faz a inicialização do wizard:
   * - Encontra o marketplace
   * - Busca e valida se existem contas já instaladas
   * - Valida se existe uma instalação de conta em andamento
   * - Se os dois últimos passos forem negativos ele cria uma nova conta
   */
  INIT = 'INIT',

  /**
   * Faz a limpeza do wizard
   */
  FRESH = 'FRESH',

  /**
   * Busca e define o marketplace que será usado para o wizard.
   */
  FIND_MARKETPLACE = 'FIND_MARKETPLACE',

  /**
   * Cria uma nova conta para o marketplace.
   */
  MAKE_NEW_ACCOUNT = 'MAKE_NEW_ACCOUNT',

  /**
   * Deleta a conta sem autenticação no wizard.
   */
  DELETE_ACCOUNT = 'DELETE_ACCOUNT',
}

export enum Mutations {
  /**
   * Define a conta criada no wizard.
   */
  SET_NEW_ACCOUNT = 'SET_NEW_ACCOUNT',

  /**
   * Define a importação de ofertas para a conta criada no wizard.
   */
  SET_OFFER_IMPORT = 'SET_OFFER_IMPORT',

  /**
   * Define o marketplace atual que será utilizado para o wizard.
   */
  SET_MARKETPLACE = 'SET_MARKETPLACE',

  /**
   * Define a configuração do wizard para o marketplace atual.
   */
  SET_WIZARD_CONFIG = 'SET_WIZARD_CONFIG',

  /**
   * Define a etapa ativa do wizard.
   */
  SET_ACTIVE_STEP = 'SET_ACTIVE_STEP',
}

export enum Getters {
  /**
   * Retorna a conta criada no wizard.
   */
  NEW_ACCOUNT = 'NEW_ACCOUNT',

  /**
   * Retorna importação de ofertas para a conta criada no wizard.
   */
  OFFER_IMPORT = 'OFFER_IMPORT',

  /**
   * Retorna o marketplace atual.
   */
  MARKETPLACE = 'MARKETPLACE',

  /**
   * Retorna a aplicação do marketplace atual.
   */
  MARKETPLACE_APPLICATION = 'MARKETPLACE_APPLICATION',

  /**
   * Retorna a a configuração do wizard para o marketplace atual presente dentro de application.
   */
  WIZARD_CONFIG_FROM_API = 'WIZARD_CONFIG_FROM_API',

  /**
   * Retorna a configuração do wizard para o marketplace atual.
   */
  WIZARD_CONFIG = 'WIZARD_CONFIG',

  /**
   * Retorna a etapa ativa do wizard.
   */
  ACTIVE_STEP = 'ACTIVE_STEP',
}
