export enum ActionListPublishedOffers {
  GET_LIST = 'GET_LIST',

  /**
   * Salva uma nova regra de precificação para o anúncio
   */
  SAVE_NEW_PRICING_RULE = 'SAVE_NEW_PRICING_RULE',

  /**
   * Atualiza uma regra de precificação para o anúncio
   */
  UPDATE_PRICING_RULE = 'UPDATE_PRICING_RULE',

  /**
   * Exclui uma regra de precificação do anúncio
   */
  DELETE_PRICING_RULE = 'DELETE_PRICING_RULE',

  /**
   * Salva regra de precificação para múltiplos anúncios
   */
  SAVE_MULTIPLES_OFFERS_PRICING_RULE = 'SAVE_MULTIPLES_OFFERS_PRICING_RULE',

  RESET_FILTERS = 'RESET_FILTERS',
}
