import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { ListOffersModule } from './list';
import { HighlightsOffersModule } from './highlights';

const namespaced = true;

export const OffersWaitingCorrectionModule: Module<any, RootState> = {
  namespaced,
  modules: {
    list: ListOffersModule,
    highlights: HighlightsOffersModule,
  },
};
