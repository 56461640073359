import { GetterTree } from 'vuex';
import { PendingSalesListState } from './types';
import { RootState } from '@/store/types';
import { GetterPendingSales } from './enum-getter';
import { Order } from '@/entities';
import { FiltersPendingSales } from '@/pages/marketplaces/new-offers/pending-sales/list/pending-filter/filter-type';

export const getters: GetterTree<PendingSalesListState, RootState> = {
  [GetterPendingSales.LIST]: (state): Order[] => state.list,
  [GetterPendingSales.IS_LOADING]: (state): boolean => {
    return state.isLoading;
  },
};

export default getters;
