import { Module } from 'vuex';

import { RootState } from '@/store/types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { WizardState } from './types';

export const state: WizardState = {
  marketplace: null,
  newAccount: null,
  offerImport: null,
  wizardConfig: [],
  activeStep: null,
};

const namespaced = true;

export const wizardModule: Module<WizardState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
