import { RouteConfig } from 'vue-router';
import { PagesName } from '@/router/routes/pages-enum';

const wizardLegacyRoutes: RouteConfig[] = [
  {
    name: PagesName.LEGACY_WIZARD_ABOUT,
    path: 'about',
    meta: {
      step: 0,
    },
    component: () => import('@/pages/wizard/wizard-legacy/about/resolver.vue'),
  },
  {
    name: PagesName.LEGACY_WIZARD_AUTHENTICATION,
    path: 'authentication',
    meta: {
      step: 1,
    },
    component: () => import('@/pages/wizard/wizard-legacy/authentication/resolver.vue'),
  },
  {
    name: PagesName.LEGACY_WIZARD_IMPORTING,
    path: 'importing',
    meta: {
      step: 2,
    },
    component: () => import('@/pages/wizard/wizard-legacy/importing/importing.vue'),
  },
  {
    name: PagesName.LEGACY_WIZARD_COMPLETING,
    path: 'completing',
    meta: {
      step: 3,
    },
    component: () => import('@/pages/wizard/wizard-legacy/completing/completing.vue'),
  },
];

export default wizardLegacyRoutes;
