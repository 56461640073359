import { MutationTree } from 'vuex';
import { Storage } from '@/components/storage';
import { Theme, UiNotification, UiSettingsState } from './types';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import { MutationUiSettings } from './enum';
import { PricingRule } from '@/entities';

export const mutations: MutationTree<UiSettingsState> = {
  /**
   * Define a classe com o tema da loja.
   * */
  setTheme(state, theme: Theme) {
    state.theme = normalizeTheme(theme);
    Storage.set('theme', state.theme);
  },

  /**
   * Define a notificação a ser exibida para a aplicação
   *
   * Caso seja passado um objeto contendo o `error` e `where`, o título e mensagem da
   * notificação serão gerados automaticamente de forma genérica baseado no code, e
   * imprimindo o erro no console. Para imprimir um erro com formatação específica é
   * necessário passar por parâmetro um `error` com o code `1001`.
   *
   * Apenas notificações com `error` serão exibidas no console.
   */
  setNotification(state, notification: UiNotification | null) {
    if (!notification?.error) {
      state.notification = notification;
    }

    if (notification?.error) {
      const notificationWithError = handlerErrorActions({
        ...notification,
        error: notification?.error,
        where: notification?.where ?? 'Unknown',
      });

      if (!notificationWithError) return;

      state.notification = notificationWithError;
    }

    if (notification?.noAutoHide) return;

    setTimeout(() => {
      state.notification = null;
    }, notification?.autoHideDelay);
  },

  [MutationUiSettings.SET_COMPATIBILITY_WITH_NEW_PRICING_RULE](
    state,
    isCompatibleWithNewPricingRule: boolean
  ) {
    state.isCompatibleWithNewPricingRule = isCompatibleWithNewPricingRule;
  },

  [MutationUiSettings.SET_PRICING_ACCOUNT](state, pricing: PricingRule) {
    state.accountPricingRule = pricing;
  },
};

export function normalizeTheme(newTheme: Theme): Theme {
  if (['light', 'dark'].includes(newTheme)) {
    return newTheme;
  }

  return 'light';
}

export default mutations;
