import { Paging } from '@tray-libs/design-system-components/src/components/ui/pagination/paginator/types';
import { PlatformCategory } from './types';
import { QueryParamsToListing } from '@/components/handle-listing/type-handle-listing';

export interface PaginateList {
  size: number;
  number: number;
  total?: number;
}

export enum ValuesOfOptions {
  ALL = 'all',
  IS_LINKED = 'is_linked',
  NOT_LINKED = 'not_linked',
}

type ValuesOfOptionsToFilter = Lowercase<keyof typeof ValuesOfOptions>;

export interface OptionSelect {
  label: string;
  value: ValuesOfOptionsToFilter;
}

export interface FilterList {
  word: string;
  optionSelected: OptionSelect;
  categoryName?: string;
}

export interface ParamsToFilterCategoryList extends Omit<QueryParamsToListing, 'filtering'> {
  filtering?: FilterList;
}
export interface ListCategoryApi {
  data: PlatformCategory[];
  paging: Paging;
  filtering: object;
}
