import { RootState } from '@/store/types';
import { Module } from 'vuex';
import { ListOffersModule } from './list';

const namespaced = true;

export const PublishedOffersModule: Module<any, RootState> = {
  namespaced,
  modules: {
    list: ListOffersModule,
  },
};
