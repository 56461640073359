export enum MutationPagingByRoute {
  /**
   * Salva a paginação atual da lista por rota.
   * @param {string} route - Rota atual
   * @param {ApiPaging} paging - Paginação atual
   */
  SAVE_PAGING = 'SAVE_PAGING',

  /**
   * Salva a pagina atual dos anúncios publicados.
   * @param {string} route - Rota atual
   * @param {ApiPaging} page - pagina a atualizar
   */
  SET_PAGE = 'SET_PAGE',

  /**
   * Salva a pagina atual dos anúncios publicados.
   * @param {string} route - Rota atual
   * @param {ApiPaging} size - Tamanho da página a atualizar
   */
  SET_SIZE = 'SET_SIZE',
}
