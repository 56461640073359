import axios, { AxiosInstance } from 'axios';
import config from '@/config';
import { Store } from 'vuex';
import { Authorization, Cancelable, ErrorHandler } from '../client/http';
import { HubApiResponse } from './http';
import { IClient } from '../client';

export class HubClient extends IClient {
  /**
   * Cria uma instância do cliente HTTP do Hub.
   * */
  constructor(store: Store<any>) {
    super(store, {
      requestInterceptors: [new Authorization(), new Cancelable()],
      responseErrorInterceptors: [new ErrorHandler()],
      withResponse: (response) => new HubApiResponse(response),
    });
  }

  /**
   * Cria a instância padrão do client.
   *
   * @return {AxiosInstance}
   * */
  protected createDefaultHttpClient(): AxiosInstance {
    const data = {}; // Axios não adiciona o cabeçalho Content-Type sem um corpo, mesmo que vazio.
    const timeout = 30 * 1000; // 30 segundos
    const baseURL = `${config('apiUrl')}`;
    const headers = {
      'Content-Type': 'application/json',
      Accept: 'application/json',
    };

    return axios.create({ timeout, baseURL, headers, data });
  }
}

export default HubClient;
