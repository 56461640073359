import Entity from './entity';

export type VariantAttributeType = 'color' | 'string';

type Value = {
  value: any;
  label?: string;
};

export default class ProductVariantAttribute extends Entity<ProductVariantAttribute> {
  /**
   * Label da variação.
   * Ex: Color, Tamanho.
   *
   * @var string
   * */
  id!: string;

  /**
   * Valor da variação.
   * Ex: amarelo, vermelho, azul.
   *
   * @var string
   * */
  value!: Value | string;

  /**
   * Tipo da variação.
   * Usado para a apresentação.
   *
   * @var VariantAttributeType
   * */
  type!: VariantAttributeType;

  getValue(): any {
    if (typeof this.value === 'object' && this.value?.value) {
      return this.value.value;
    }

    return this.value;
  }

  getName(): string {
    return this.name || this.id;
  }
}
