export enum ActionListWaitingCorrection {
  GET_LIST = 'GET_LIST',

  RESET_FILTERS = 'RESET_FILTERS',

  GET_STATUS_OPTIONS = 'GET_STATUS_OPTIONS',

  /**
   * Retorna a offer com erro
   */
  GET_OFFER_WITH_ERROR = 'GET_OFFER_WITH_ERROR',
  /**
   * Retorna os erros de uma draft offer
   */
  GET_OFFER_ERRORS = 'GET_OFFER_ERRORS',
  /**
   * Retorna as variações de uma draft offer
   */
  GET_OFFER_VARIANTS = 'GET_OFFER_VARIANTS',
  /**
   * Retorna os erros de variações de uma draft offer
   */
  GET_VARIANTS_ERRORS = 'GET_VARIANTS_ERRORS',
  /**
   * Atualiza o titulo da oferta com erro
   */
  UPDATE_TITLE = 'UPDATE_TITLE',
  /**
   * Remover anuncio.
   */
  REMOVE_OFFER = 'REMOVE_OFFER',
}
