import Vue from 'vue';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import { ActionMarketplaces } from './enum-action';
import { MutationMarketplaces } from './enum-mutation';
import { MarketplacesState, ConfigMarketplace } from './types';
import { GetterAuth } from '../auth/enum';
import { HubApiResponse } from '@/components/api/hub-client';

export const actions: ActionTree<MarketplacesState, RootState> = {
  async [ActionMarketplaces.GET_LIST]({ commit, rootGetters }): Promise<void> {
    try {
      commit(MutationMarketplaces.SET_LOADING, true);
      const storeRef = rootGetters['store/store'].ref;
      const platformId = rootGetters['auth/' + GetterAuth.PLATFORM_ID];

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<ConfigMarketplace[], HubApiResponse>(
        `/platforms/${platformId}/stores/${storeRef}/marketplaces`
      );

      const data = response.getData();

      commit(MutationMarketplaces.SAVE_LIST, data);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        handlerErrorActions({ where: 'Action GET LIST MarketplacesPage', error }),
        { root: true }
      );
    } finally {
      commit(MutationMarketplaces.SET_LOADING, false);
    }
  },
  async [ActionMarketplaces.CONFIGS_CURRENT_MARKETPLACE]({ commit, rootGetters }) {
    try {
      const storeRef = rootGetters['store/store'].ref;
      const marketplaceName = rootGetters['marketplace/activeMarketplace'].name;
      const platformId = rootGetters['auth/' + GetterAuth.PLATFORM_ID];

      const client = Vue.$apiClient.getHttpClient();

      const response = await client.get<ConfigMarketplace, HubApiResponse>(
        `/platforms/${platformId}/stores/${storeRef}/marketplaces/${marketplaceName}`
      );

      const data = response.getData();

      commit(MutationMarketplaces.SAVE_CONFIGS_CURRENT_MARKETPLACE, data);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        {
          where: 'Configs marketplace account',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
      return error;
    }
  },
};

export default actions;
