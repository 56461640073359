import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';
import { Storage } from '@/components/storage';
import {
  GenericFilter,
  SimpleFilterEqCondition,
  SimpleFilterInCondition,
} from '@tray-libs/design-system-components/src/components/ui/filter/models';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const ApiProductAttributes = {
  offer_query: '[query]',
  offer_statuses: '[status]',
  offer_is_linked_to_product: '[is_linked_to_product]',
  offer_title: '[title]',
  marketplace_account_id: '[marketplace_account_id]',
  marketplace_code: '[code]',
  stock: '[stock]',
  stock_more_than: '[stock>]',
  initial_price: '[price>=]',
  final_price: '[price<=]',
  sku: '[sku]',
};

export const state: FilterState = {
  /**
   * Contexto de lista de anúncios.
   */
  context: 'offer-list',

  /**
   * Ordenação da lista de produtos.
   * */
  sort: Storage.get('offer-sorting') || '-created_at',

  /**
   * Determina se o filtro avançado está aberto.
   */
  isAdvancedFilterOpen: false,

  /**
   * Número de itens por paginação.
   * */
  pageSize: +Storage.get('offer-paging') || 25,

  /**
   * Número da página.
   * */
  pageNumber: 1,

  /**
   * Lista de possíveis filtros da lista de produtos.
   * */
  instances: {
    offer_query: new GenericFilter({
      label: 'Filtro',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.offer_query,
        value: '',
      }),
    }),
    offer_statuses: new GenericFilter({
      label: 'Status do anúncio',
      isVisible: false,
      condition: SimpleFilterInCondition.from({
        apiAttribute: ApiProductAttributes.offer_statuses,
        value: [],
      }),
    }),
    offer_is_linked_to_product: new GenericFilter({
      label: 'Anúncios',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.offer_is_linked_to_product,
        value: '',
      }),
    }),
    offer_title: new GenericFilter({
      label: 'Nome do Anúncio',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.offer_title,
        value: '',
        // @TODO Alterar `isPartialSearch` para true após mudança no backend.
        isPartialSearch: false,
      }),
    }),
    marketplace_account_id: new GenericFilter({
      label: 'Apelidos',
      isVisible: false,
      condition: SimpleFilterInCondition.from({
        apiAttribute: ApiProductAttributes.marketplace_account_id,
        value: [],
      }),
    }),
    marketplace_code: new GenericFilter({
      label: 'Código do marketplace',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.marketplace_code,
        value: '',
        // @TODO Alterar `isPartialSearch` para true após mudança no backend.
        isPartialSearch: false,
      }),
    }),
    stock: new GenericFilter({
      label: 'Estoque',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.stock,
        value: '',
      }),
    }),
    stock_more_than: new GenericFilter({
      label: 'Estoque',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.stock_more_than,
        value: '',
      }),
    }),
    initial_price: new GenericFilter({
      label: 'Preço Inicial',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.initial_price,
        value: '',
      }),
    }),
    final_price: new GenericFilter({
      label: 'Preço Final',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.final_price,
        value: '',
      }),
    }),
    sku: new GenericFilter({
      label: 'SKU no marketplace',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.sku,
        value: '',
        // @TODO Alterar `isPartialSearch` para true após mudança no backend.
        isPartialSearch: false,
      }),
    }),
  },
};

const namespaced = true;

export const FiltersModule: Module<FilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
