import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { HighlightsType } from './types';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const namespaced = true;

export const state: HighlightsType = {
  counts: {
    active: 0,
    paused: 0,
    under_review: 0,
    unrelated: 0,
  },
};

export const HighlightsModule: Module<any, RootState> = {
  state,
  getters,
  actions,
  mutations,
  namespaced,
};

export default HighlightsModule;
