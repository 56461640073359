import { RootState } from '@/store/types';
import { Module } from 'vuex';
import getters from './getters';
import { HighlightsOffersState } from './types';
import { mutations } from './mutations';
import { actions } from './actions';

/**
 * Estado vuex do módulo de ofertas agurando correção.
 */
export const state: HighlightsOffersState = {
  unrelated: 0,
  failures: 0,
  with_issues: 0,
  isLoading: false,
};

const namespaced = true;

export const HighlightsOffersModule: Module<HighlightsOffersState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
