import { GetterTree } from 'vuex';

import { RootState } from '../../types';
import { Getters } from './enum';
import { Offer } from '@/entities';
import { OffersInProcessingState } from './types';

export const getters: GetterTree<OffersInProcessingState, RootState> = {
  [Getters.OFFERS_PROCESSING_LIST_CONTENT](state): Array<Offer> {
    return state.list.content;
  },
  [Getters.OFFERS_LIST_PAGINATE](state) {
    return state.list.paging;
  },
  [Getters.IS_LOADING](state): boolean {
    return state.list.loading;
  },
};
