import vuex from '@/store';
import { interval } from 'rxjs/internal/observable/interval';
import { first, map } from 'rxjs/operators';
import { AuthenticatedUser, Store } from '@/entities';
import {
  ToggleManager,
  ToggleBagConstructor,
  ToggleBag as ToggleBagContract,
  ToggleMatcherFn,
} from '../typing';

export const ToggleBag: ToggleBagConstructor = class implements ToggleBagContract {
  /**
   * Número da loja acessada.
   *
   * @var {Store} store
   * */
  protected store: Store;

  /**
   * Usuário autenticado.
   *
   * @var {AuthenticatedUser} user
   * */
  protected user: AuthenticatedUser;

  /**
   * Lista de toggles.
   *
   * @var {Array<string>} toggles
   * */
  protected toggles: Array<string>;

  /**
   * Instância que manipula a lista de toggles.
   *
   * @var {ToggleManager} manager
   * */
  protected manager: ToggleManager;

  /**
   * ToggleBag's constructor
   *
   * @param {Store} store
   * @param {AuthenticatedUser} user
   * @param {ToggleManager} manager
   * @param {Array<string>} toggles
   * */
  constructor(
    store: Store,
    user: AuthenticatedUser,
    manager: ToggleManager,
    ...toggles: Array<string>
  ) {
    this.store = store;
    this.user = user;
    this.manager = manager;
    this.toggles = toggles;
  }

  /**
   * {@inheritDoc}
   * */
  static async from(manager: ToggleManager, ...items: Array<string>): Promise<ToggleBagContract> {
    const storeInfo = await interval(50)
      .pipe(
        first(() => !!vuex.getters['store/store'].id),
        map(() => vuex.getters['store/store'])
      )
      .toPromise();

    const authenticatedUser = await interval(50)
      .pipe(
        first(() => !!vuex.getters['auth/user'].id),
        map(() => vuex.getters['auth/user'])
      )
      .toPromise();

    return new ToggleBag(storeInfo, authenticatedUser, manager, ...items);
  }

  /**
   * {@inheritDoc}
   * */
  only(stores: Array<number> | ToggleMatcherFn): void {
    const matches = this.matches(stores);
    this.toggles.forEach((toggle) => {
      this.manager[matches ? 'enable' : 'disable'](toggle);
    });
  }

  /**
   * {@inheritDoc}
   * */
  except(stores: Array<number> | ToggleMatcherFn): void {
    const matches = this.matches(stores);
    this.toggles.forEach((toggle) => {
      this.manager[matches ? 'disable' : 'enable'](toggle);
    });
  }

  /**
   * Determina se as condições fornecidas são verdadeiras.
   *
   * @return boolean
   * */
  protected matches(stores: Array<number> | ToggleMatcherFn): boolean {
    if (typeof stores === 'function') {
      return stores(this.store, this.user);
    }

    return stores.includes(this.store.id);
  }
};
