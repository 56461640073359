import Entity from './entity';
import ProductVariantAttribute from './product-variant-attribute';
import Image from './image';
import { ProductStatus } from './types';

export default class ProductVariant extends Entity<ProductVariant> {
  //@TODO mapear demais atributos do produto

  /**
   * Id da variação.
   *
   * @var string
   * */
  id!: string;

  /**
   * Código da variação no marketplace.
   *
   * @var string
   * */
  code!: string;

  /**
   * Status da variação
   *
   * @var ProductStatus
   * */
  status!: ProductStatus;

  /**
   * EAN da variação.
   *
   * @var string
   * */
  ean!: string;

  /**
   * Lista de atributos da variação
   *
   * @var Array<ProductVariantAttribute>
   * */
  protected _variation_attributes: Array<ProductVariantAttribute> = [];

  /**
   * Lista de imagens da variação
   *
   * @var {Array<Image>}
   * */
  protected _images: Array<Image> = [];

  /**
   * Setter para os atributos de variação.
   * Garante que os atributos de variação sejam uma lista de instâncias de ProductVariantAttribute.
   * */
  set variation_attributes(attributes: Array<ProductVariantAttribute>) {
    if (!(attributes as unknown)) {
      this._variation_attributes = [];
      return;
    }

    this._variation_attributes = attributes.map((attribute) => {
      if ((attribute as unknown) instanceof ProductVariantAttribute) {
        return attribute;
      }

      return ProductVariantAttribute.from(attribute as object);
    });
  }

  get variation_attributes(): Array<ProductVariantAttribute> {
    return this._variation_attributes;
  }

  /**
   * Setter para as imagens.
   * Garante que as variações sejam uma lista de instâncias de Image.
   * */
  set images(images: Array<Image>) {
    if (!(images as unknown)) {
      this._images = [];
      return;
    }

    this._images = images.map((image) => {
      if ((image as unknown) instanceof Image) {
        return image;
      }

      return Image.from(image as object);
    });
  }

  get images(): Array<Image> {
    return this._images;
  }

  /**
   * Determina se a oferta tem imagens.
   * */
  hasImage(): boolean {
    return this.images.length > 0;
  }

  /**
   * Retorna o nome de exibição
   * */
  getDisplayName(): string {
    return this.variation_attributes
      .map((attribute) => `${attribute.getName()} ${attribute.getValue()}`)
      .join(' | ');
  }

  /**
   * Retorna o nome de exibição referente ao Value.
   *
   * @return {string}
   * */
  getDisplayValueName(): string {
    return this.variation_attributes.map((attribute) => `${attribute.getValue()}`).join(' | ');
  }

  /**
   * Retorna os nomes dos atributos de variação do produto.
   *
   * @return {Array<string>}
   * */
  getVariationAttributesNames(): Array<string> {
    return this.variation_attributes.map((attribute) => `${attribute.getName()}`);
  }
}
