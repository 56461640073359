import { FilterList, PaginateList } from '@/store/modules/offers-in-processing/types';

export const generatesQueryParamsForOffersInProcessing = (
  paging?: PaginateList,
  filter?: FilterList,
  query?: URLSearchParams
): URLSearchParams => {
  const queryParams = query ?? new URLSearchParams();

  if (paging) {
    const { size, number } = paging;

    if (number) {
      queryParams.append('page[number]', String(number));
    }

    if (size) {
      queryParams.append('page[size]', String(size));
    }
  }

  if (filter) {
    const { title, code_product } = filter;

    if (title) {
      queryParams.append('filter[title]', `%${title}%`);
    }

    if (code_product) {
      queryParams.append('filter[product_id]', String(code_product));
    }
  }

  return queryParams;
};
