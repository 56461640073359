import { CalcBaseType, MarginType } from '@/components/pricing-rule/type';
import Entity from './entity';
import { ConvertAdditionalPrice } from './mixins/convert-additional-price';

export enum TypePricingRuleStrategy {
  DISABLED = 'disabled',
  STANDARD = 'standard',
  'DISCOUNT-VALUE' = 'discount-value',
  'ADDITIONAL-VALUE' = 'additional-value',
}

export type TypePricingRuleStrategySlug = Lowercase<keyof typeof TypePricingRuleStrategy>;

export enum TypePricingScope {
  ACCOUNT = 'account',
  OFFER = 'offer',
}

export type ScopePricingRule = Lowercase<keyof typeof TypePricingScope>;

export interface Scope {
  id: number;
  scope_priority: number;
  scope_type: ScopePricingRule;
}

export type PricingStrategy = {
  id: number;
  slug: TypePricingRuleStrategySlug;
  title: string;
  description: string;
};

/** Configurações que vem no padrão da API quando o preço é fixo */
export interface FixedPriceAPI {
  fixed_price: number;
  fixed_sale_price: number | null;
  fixed_sale_starts_on: string | null;
  fixed_sale_ends_on: string | null;
}

export interface AdjustmentValueAPI {
  type: MarginType;
  value: number;
  calc_base: CalcBaseType;
}

/**
 * Configurações da regra de precificação, que possui formatos diferentes conforme a
 * estratégia definida
 */
export interface PricingRuleConfigAPI extends Partial<FixedPriceAPI>, Partial<AdjustmentValueAPI> {}

export default class PricingRule extends Entity<PricingRule> {
  /**
   * Id da precificação.
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * Id da estratégia.
   *
   * @var {number} strategy_id
   * */
  strategy_id!: number;

  /**
   * Título da regra.
   *
   * @var {string} title
   * */
  title!: string;

  /**
   * Se a regra é reutilizável.
   *
   * @var {boolean} strategy_id
   * */
  is_reusable!: boolean;

  /**
   * Tipo de escopo da regra de precificação aplicada
   */
  scope!: Scope;

  /**
   * Ids dos itens que a regra se aplica.
   */
  scope_items!: object[];

  /**
   * Data de criação.
   */
  created_at!: string | null;

  /**
   * Data de atualização.
   */
  updated_at!: string | null;

  /**
   * Data de exclusão.
   */
  deleted_at!: string | null;

  /**
   * Configuração da regra selecionada.
   *
   * @var {PricingRuleConfigAPI | null} config
   * */
  private _config!: PricingRuleConfigAPI | null;

  set config(value: PricingRuleConfigAPI | null) {
    if (Array.isArray(value as unknown)) {
      value = null;
    }

    this._config = value;
  }

  get config(): PricingRuleConfigAPI | null {
    return this._config;
  }

  /**
   * Estratégia da precificação
   *
   * @var {PricingStrategy} strategy
   * */
  strategy!: PricingStrategy;

  /**
   * Valor adicional da precificação formatado em Real.
   *
   * @returns {string} preço adicional
   */
  adjustmentPrice(): string {
    const isAdjustmentPrice =
      this.strategy.slug === TypePricingRuleStrategy['ADDITIONAL-VALUE'] ||
      this.strategy.slug === TypePricingRuleStrategy['DISCOUNT-VALUE'];
    if (isAdjustmentPrice) {
      return ConvertAdditionalPrice.convertToRealOrPercentageByConfig(
        this.config as AdjustmentValueAPI
      );
    }

    return '';
  }
}
