import { Offer, OfferRelation, Product, ProductVariant } from '@/entities';
import { GetterTree } from 'vuex';

import { RootState } from '../../../types';
import { OfferRelationState, OfferRelationStep } from './types';

export const getters: GetterTree<OfferRelationState, RootState> = {
  /**
   * Retorna a Oferta salva na store;
   *
   * @param {state}
   * @return {Offer | null}
   * */
  getOffer(state): Offer | null {
    return state.offer;
  },

  /**
   * Retorna o Produto salvo na store;
   *
   * @param {state}
   * @return {Product | null}
   * */
  getProduct(state): Product | null {
    return state.product;
  },

  /**
   * Retorna o Relacionamento salvo na store;
   *
   * @param {state}
   * @return {OfferRelation | null}
   * */
  getRelation(state): OfferRelation | null {
    return state.relation;
  },

  /**
   * Retorna se o relacionamento pode ser salvo;
   *
   * @param {state}
   * @return {boolean}
   * */
  getSaveActionEnabled(state): boolean {
    return state.saveActionEnabled;
  },

  /**
   * Retorna a etapa do relacionamento;
   *
   * @param {state}
   * @return {OfferRelationStep}
   * */
  getRelationStep(state): OfferRelationStep {
    return state.relationStep;
  },

  /**
   * Retorna as variações do produto para seleção.
   *
   * @param {state}
   * @returns {Array<ProductVariant>}
   */
  getVariantsProduct(state): Array<ProductVariant> {
    return state.optionsVariantsProduct;
  },
};

export default getters;
