import Entity from './entity';
import Product from './product';
import Offer from './offer';

type RelationType = 'variant' | 'simple';

export default class OfferRelationItem extends Entity<OfferRelationItem> {
  /**
   * Id do relacionamento.
   * */
  id!: number;

  /**
   * Tipo de relacionamento do produto.
   *
   * @var {RelationType}
   * */
  product_type!: RelationType;

  /**
   * Id do produto
   *
   * @var {Product['id']}
   * */
  product_id!: Product['id'];

  /**
   * Tipo de relacionamento da oferta.
   *
   * @var {RelationType}
   * */
  offer_type!: RelationType;

  /**
   * Id da oferta.
   *
   * @var {Offer['id']}
   * */
  offer_id!: Offer['id'];
}
