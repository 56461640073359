import Vue from 'vue';
import Vuex, { StoreOptions } from 'vuex';
import { RootState } from './types';
import { authModule } from './modules/auth';
import { marketplaceModule } from './modules/marketplace';
import { wizardModule as wizardOldModule } from './modules/wizard/old-wizard';
import { wizardModule as wizardNewModule } from './modules/wizard';
import { storeModule } from '@/store/modules/store';
import offersModule from '@/store/modules/offers';
import { accountModule } from './modules/account';
import ordersModule from './modules/orders';
import { uiSettingsModule } from './modules/ui-settings';
import { categoriesModule } from './modules/categories';
import { offersInProcessingModule } from './modules/offers-in-processing';
import { orderLabelsModule } from './modules/order-labels';
import { pricingRuleModule } from './modules/pricing-rule';
import { PublishedOffersModule } from './modules/published-offers';
import { PagingByRouteModule } from './modules/paging-by-route';
import { PendingSales } from './modules/offers-pending-sales';
import { FilterByRouteModule } from './modules/filter-by-route';
import { OffersWaitingCorrectionModule } from './modules/offers-waiting-correction';
import { marketplacesModule } from './modules/marketplaces';
import { QuantityOfListsModules } from './modules/quantity-of-lists';

Vue.use(Vuex);

const store: StoreOptions<RootState> = {
  modules: {
    auth: authModule,
    marketplace: marketplaceModule,
    wizard: wizardOldModule,
    store: storeModule,
    offers: offersModule,
    account: accountModule,
    orders: ordersModule,
    uiSettings: uiSettingsModule,

    /** New-flow: */
    newWizard: wizardNewModule,
    paging: PagingByRouteModule,
    filter: FilterByRouteModule,
    quantityOfLists: QuantityOfListsModules,
    pricingRule: pricingRuleModule,
    categories: categoriesModule,
    offersProcessing: offersInProcessingModule,
    orderLabels: orderLabelsModule,
    publishedOffers: PublishedOffersModule,
    offersWaitingCorrection: OffersWaitingCorrectionModule,
    pendingSales: PendingSales,
    marketplaces: marketplacesModule,
  },
};

const vuex = new Vuex.Store<RootState>(store);

export default vuex;
