import numeral from 'numeral';
import { Installer, Locale } from './installer';

export class NumeralInstaller implements Installer {
  /**
   *  @inheritDoc
   */
  getLocale(locale: Locale): any {
    return import(`../locales/${locale}/numeral-locale.ts`);
  }

  /**
   *  @inheritDoc
   */
  setLocale(locale: Locale): void {
    this.getLocale(locale).then((module: any) => {
      numeral.register('locale', locale, module.default);
      numeral.locale(locale);
    });
  }
}

export default NumeralInstaller;
