export enum GetterListPublishedOffers {
  /**
   * Retorna se a lista de anúncios está carregando
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Retorna todos os anúncios publicados.
   */
  LIST = 'LIST',

  /**
   * Retorna uma lista com todos os anúncios publicados selecionados.
   */
  LIST_ALL_SELECTED = 'LIST_ALL_SELECTED',

  /**
   * Verifica se existe algum campo preenchido no filtro da página.
   */
  HAS_FILTERS_APPLY = 'HAS_FILTERS_APPLY',

  /**
   * Retorna a precificação do anúncio aberto para edição.
   */
  PRICING_TO_EDIT = 'PRICING_TO_EDIT',

  /**
   * Retorna os ids dos anúncios selecionados para precificação.
   */
  PRICING_IDS_OFFER = 'IDS_OFFER',

  /**
   * Indica se deve recarregar a lista de anúncios publicados.
   */
  RELOAD_LIST = 'RELOAD_LIST',
}
