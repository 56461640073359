import Vue from 'vue';

import { AppHubPageTitle } from './components/ui/hub-page-title';
import { AppPageHeader } from './components/ui/page-header';
import { AppPageTitle } from '@tray-libs/design-system-components/src/components/ui/page-title';

import { Paginator } from '@tray-libs/design-system-components/src/components/ui/pagination';

import {
  AppListCard,
  AppListCardHeader,
  AppListCardBody,
  AppListCardFooter,
  AppListCardError,
  AppListCardFilterAction,
  AppListCardIconAction,
} from '@tray-libs/design-system-components/src/components/ui/listable/list-card';

import {
  AppMobileCard,
  AppMobileCardTitle,
  AppMobileCardDescription,
  AppMobileCardActionButton,
} from '@tray-libs/design-system-components/src/components/ui/listable/mobile-card';

import {
  AppBottomSheet,
  AppBottomSheetBody,
  AppBottomSheetHeader,
  AppBottomSheetItem,
  AppBottomSheetSection,
} from '@tray-libs/design-system-components/src/components/ui/bottom-sheet';

Vue.component('app-page-header', AppPageHeader);
Vue.component('app-page-title', AppPageTitle);
Vue.component('app-hub-page-title', AppHubPageTitle);
Vue.component('app-paginator', Paginator);

Vue.component('app-list-card', AppListCard);
Vue.component('app-list-card-header', AppListCardHeader);
Vue.component('app-list-card-body', AppListCardBody);
Vue.component('app-list-card-footer', AppListCardFooter);
Vue.component('app-list-card-error', AppListCardError);
Vue.component('app-list-card-icon-action', AppListCardIconAction);
Vue.component('app-list-card-filter-action', AppListCardFilterAction);

Vue.component('app-mobile-card', AppMobileCard);
Vue.component('app-mobile-card-title', AppMobileCardTitle);
Vue.component('app-mobile-card-description', AppMobileCardDescription);
Vue.component('app-mobile-card-action-button', AppMobileCardActionButton);

Vue.component('app-bottom-sheet', AppBottomSheet);
Vue.component('app-bottom-sheet-body', AppBottomSheetBody);
Vue.component('app-bottom-sheet-header', AppBottomSheetHeader);
Vue.component('app-bottom-sheet-item', AppBottomSheetItem);
Vue.component('app-bottom-sheet-section', AppBottomSheetSection);
