import { MutationTree } from 'vuex';
import { HighlightsOffersState } from './types';
import { MutationHighlightsWaitingCorrection } from './enum-mutation';

export const mutations: MutationTree<HighlightsOffersState> = {
  [MutationHighlightsWaitingCorrection.SET_FAILED](state, value: number): void {
    state.failures = value;
  },
  [MutationHighlightsWaitingCorrection.SET_UNRELATED](state, value: number): void {
    state.unrelated = value;
  },
  [MutationHighlightsWaitingCorrection.SET_WITH_ISSUES](state, value: number): void {
    state.with_issues = value;
  },
  [MutationHighlightsWaitingCorrection.SET_LOADING](state, value: boolean): void {
    state.isLoading = value;
  },
};
