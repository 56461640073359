import { MutationTree } from 'vuex';
import { WizardState } from './types';

export const mutations: MutationTree<WizardState> = {
  /**
   * Define o marketplace fornecido.
   * */
  setNewAccount(state, account) {
    state.newAccount = account;
  },

  /**
   * Atualiza a importação de oferta do wizard
   * */
  setOfferImport(state, offerImport) {
    state.offerImport = offerImport;
  },

  /**
   * Atualiza o marketplace do wizard
   * */
  setMarketplace(state, marketplace) {
    state.marketplace = marketplace;
  },
};

export default mutations;
