import Vue from 'vue';
import { BehaviorSubject } from 'rxjs';

type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl';

type State = {
  [key: string]: boolean;
};

const grid: { [key in Breakpoint]: number } = {
  xs: 0,
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1441,
};

export interface WindowSize {
  /**
   * Determina se a dimensão da tela é para dispositivos móveis.
   * */
  isMobile: boolean;

  /**
   * Determina se a dimensão da tela é para dispositivos desktop.
   * */
  isDesktop: boolean;

  /**
   * Determina a exibição apenas nos valores acima dos breackpoints
   * especificados
   *
   * */
  up(breakpoint: Breakpoint): boolean;

  /**
   * Determina a exibição apenas nos valores abaixo dos breackpoints
   * especificados
   *
   * */
  down(breakpoint: Breakpoint): boolean;

  /**
   * Determina a exibição apenas nos valores abaixo dos breackpoints
   * especificados
   *
   * */
  only(breakpoint: Breakpoint): boolean;

  /**
   * Determina a exibição apenas nos valores abaixo dos breackpoints
   * especificados
   *
   * */
  between(lower: Breakpoint, upper: Breakpoint): boolean;
}

export function makeWindowSizeObservable(): WindowSize {
  const subject: BehaviorSubject<number> = new BehaviorSubject(0);

  /**
   * Atualiza os dados do estado.
   *
   * @return {void}
   * */
  const update = (): void => {
    subject.next(window.innerWidth);
  };

  window.addEventListener('resize', update);
  update();

  return new (class implements WindowSize {
    /**
     * Estado atual das definições vinculadas ao tamanho da tela.
     *
     * @var {WindowSize}
     * */
    protected state: State = Vue.observable<State>({});

    /**
     * {@inheritDoc}
     * */
    get isDesktop(): boolean {
      return this.up('md');
    }

    /**
     * {@inheritDoc}
     * */
    get isMobile(): boolean {
      return this.down('md');
    }

    protected getNextKey(breakpoint: Breakpoint): Breakpoint | null {
      const breakpoints = Object.keys(grid) as Array<Breakpoint>;
      const breakpointKey = breakpoints.indexOf(breakpoint);

      if (breakpointKey === -1 || breakpointKey + 1 >= breakpoints.length) {
        return null;
      }

      return breakpoints[breakpointKey + 1];
    }

    /**
     * {@inheritDoc}
     * */
    up(breakpoint: Breakpoint): boolean {
      const name = `up-${breakpoint}`;

      if (typeof this.state[name] === 'undefined') {
        subject.subscribe((width: number) => {
          Vue.set(this.state, name, width >= grid[breakpoint]);
        });

        update();
      }

      return this.state[name];
    }

    /**
     * {@inheritDoc}
     * */
    down(breakpoint: Breakpoint): boolean {
      const name = `down-${breakpoint}`;

      if (typeof this.state[name] === 'undefined') {
        subject.subscribe((width: number) => {
          Vue.set(this.state, name, width < grid[breakpoint]);
        });

        update();
      }

      return this.state[name];
    }

    only(breakpoint: Breakpoint): boolean {
      const name = `only-${breakpoint}`;

      if (typeof this.state[name] === 'undefined') {
        subject.subscribe((width: number) => {
          if (width < grid[breakpoint]) {
            Vue.set(this.state, name, false);
            return;
          }

          const nextPoint = this.getNextKey(breakpoint);
          if (!nextPoint) {
            Vue.set(this.state, name, true);
            return;
          }

          Vue.set(this.state, name, width < grid[nextPoint]);
        });

        update();
      }

      return this.state[name];
    }

    /**
     * {@inheritDoc}
     * */
    between(lower: Breakpoint, upper: Breakpoint): boolean {
      const name = `only-${lower}-${upper}`;

      if (typeof this.state[name] === 'undefined') {
        subject.subscribe((width: number) => {
          if (width < grid[lower]) {
            Vue.set(this.state, name, false);
            return;
          }

          const nextBreakpoint = this.getNextKey(upper);
          if (!nextBreakpoint) {
            Vue.set(this.state, name, true);
            return;
          }

          Vue.set(this.state, name, width < grid[nextBreakpoint]);
        });

        update();
      }

      return this.state[name];
    }
  })();
}
