/**
 * Regras de precificação disponíveis para definir as configurações de exibição da
 * sidebar de regra de precificação.
 * Algumas são equivalentes a slug da API, outras são regras de exibição.
 */
export enum PricingRuleDisplayConfig {
  /**
   * Define a aplicação da precificação por conta
   * Não possui slug equivalente na API
   */
  BY_ACCOUNT = 'by-account',

  /**
   * Define a visibilidade das opções de sincronização
   * Não possui slug equivalente na API
   */
  ENABLED = 'enabled',

  /**
   * Quando está desabilitada a sincronização dos anúncios com a loja e não possui um
   * preço fixo possível
   */
  DISABLED = 'disabled',

  /**
   * Estratégia definindo um valor de preço fixo por anúncio
   * Não possui slug equivalente na API
   */
  FIXED_PRICE = 'fixed-price',

  /** Estratégia que usa o mesmo preço do produto da loja virtual */
  STANDARD = 'standard',

  /** Estratégia que possibilita a aplicação de desconto ou margem adicional */
  'ADJUSTMENT-VALUE' = 'adjustment-value',
}
