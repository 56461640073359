import { ApiPaging } from '@/components/api/client/http';
import { PagingByRouteState } from './types';
import getters from './getters';
import { mutations } from './mutations';

/**
 * Módulo de paginação por rota, armazena a paginação atual de acordo com a rota.
 */
export const state: PagingByRouteState = {
  route: {
    'new-published-offers': {} as ApiPaging,
    'offers-waiting-correction': {} as ApiPaging,
    'new-pending-orders': {} as ApiPaging,
  },
};

const namespaced = true;

export const PagingByRouteModule = {
  namespaced,
  state,
  getters,
  mutations,
};
