import { RouteConfig } from 'vue-router';
import { PagesName } from '@/router/routes/pages-enum';

const offersLegacyRoutes: RouteConfig[] = [
  {
    path: 'offers',
    name: PagesName.LEGACY_PUBLISHED_OFFERS,
    meta: {
      title: 'Lista de anúncios',
      flow: 'old',
    },
    component: () => import('@/pages/marketplaces/offers/list.vue'),
  },
  {
    path: 'accounts',
    name: PagesName.LEGACY_ACCOUNTS,
    component: () => import('@/pages/marketplaces/accounts/accounts.vue'),
    meta: {
      title: 'Contas',
      flow: 'old',
    },
  },
  {
    path: 'pending-orders',
    name: PagesName.LEGACY_PENDING_ORDERS,
    meta: {
      title: 'Pedidos não inclusos',
      flow: 'old',
    },
    component: () => import('@/pages/marketplaces/pending-orders/pending-orders.vue'),
  },
];

export default offersLegacyRoutes;
