import Entity from './entity';

export type CancelReasonType = 'marketplace_authorization_error' | 'unhandled_error';

export default class OfferImport extends Entity<OfferImport> {
  /**
   * ID da importação.
   *
   * */
  id!: number;

  /**
   * ID da conta no marketplace.
   *
   * */
  marketplace_account_id!: number;

  /**
   * Quantidade de anuncios com erro.
   *
   * */
  errors!: number;

  /**
   * Estado da importação.
   * */
  state!: string;

  /**
   * Total de ofertas que serão importadas.
   * */
  total_offers: number | null = null;

  /**
   * Total de ofertas já importadas.
   * */
  total_imported: number | null = null;

  /**
   * Total de ofertas puladas.
   * */
  skipped: number | null = null;

  /**
   * Recebe a mensagem de erro com um dos valores mapeados
   * (The marketplace api responded with unauthenticated error | Something went wrong)
   *
   * */
  cancel_reason_message: string | null = null;

  /**
   * Recebe o tipo de cancelamento.
   *
   * */
  cancel_reason_type: CancelReasonType | null = null;

  /**
   * Retorna o total de ofertas.
   *
   * @return {number}
   * */
  total(): number {
    return this.total_offers || 0;
  }

  /**
   * Retorna o total de ofertas importadas.
   *
   * @return {number}
   * */
  totalImported(): number {
    return this.total_imported || 0;
  }

  /**
   * Retorna o total de ofertas puladas.
   *
   * @return {number}
   * */
  totalSkipped(): number {
    return this.skipped || 0;
  }

  /**
   * Retorna se a importação está em progresso.
   *
   * @return {boolean}
   * */
  isImporting(): boolean {
    return this.state === 'importing';
  }

  /**
   * Retorna se a importação foi completada.
   *
   * @return {boolean}
   * */
  isCompleted(): boolean {
    return this.state === 'completed';
  }

  /**
   * Retorna se a importação foi cancelada.
   *
   * @return {boolean}
   * */
  isCanceled(): boolean {
    return this.state === 'canceled';
  }
}
