import { Module } from 'vuex';

import { AttributesState } from './types';
import { RootState } from '../../../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: AttributesState = {
  attributes: {},
};

const namespaced = true;

export const attributesModule: Module<AttributesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
