import { MutationTree } from 'vuex';
import AuthenticatedUser from '@/entities/authenticated-user';
import { AuthState, Platform } from './types';

export const mutations: MutationTree<AuthState> = {
  /**
   * Define o usuário autenticado.
   * */
  user(state, newValue: object | null) {
    if (!newValue) {
      state.user = null;
      return;
    }
    state.user = AuthenticatedUser.from(newValue);
  },

  /**
   * Define a atual plataforma carregada no HUB.
   */
  platform(state, newValue: Platform.TRAY | Platform.BAGY) {
    state.platform = newValue;
  },

  /**
   * Define o token de autorização.
   * */
  token(state, newValue: string | null) {
    state.token = newValue;
  },

  /**
   * Define o token de processo.
   * */
  processToken(state, newValue: AuthState['processToken']) {
    state.processToken = newValue;
  },
};

export default mutations;
