export enum GetterOrderLabels {
  /**
   * Retorna todos os dados de etiquetas de pedidos agrupados por marketplace
   */
  ALL_MARKETPLACES = 'ALL_MARKETPLACES',
  /**
   * Permite verificar se há erros no processo de impressão de etiquetas de pedidos.
   */
  HAS_ERRORS = 'HAS_ERRORS',
  /**
   * Retorna um status compilado geral de todas as contas de todos os marketplaces.
   */
  COMPILED_STATUS_OF_ALL_ACCOUNTS = 'COMPILED_STATUS_OF_ALL_ACCOUNTS',
  /**
   * Retorna todos os items de etiquetas do lote com todas contas de marketplace em um
   * único array.
   */
  LIST_OF_ALL_LABELS = 'LIST_OF_ALL_LABELS',

  /**
   * Retorna o status do lote de etiquetas a imprimir
   */
  BATCH_STATUS = 'BATCH_STATUS',

  /**
   * Retorna o ID do lote de etiquetas a imprimir
   */
  BATCH_ID = 'BATCH_ID',

  /**
   * Retorna o status de carregamento na página inicial de etiquetas
   */
  IS_LOADING = 'IS_LOADING',
}
