import Entity, { Attributes } from './entity';
import Image from './image';
import OfferVariant from './offer-variant';
import Account from './account';
import OfferRelation from './offer-relation';
import { Dimensions } from '@/entities/types';
import isEqual from 'lodash/isEqual';
import moment from 'moment';

export type AdditionalAttributes = Record<string, any>;

export type OfferStatus =
  | 'active'
  | 'paused'
  | 'under_review'
  | 'deleted_marketplace'
  | 'account_removed'
  | 'creating'
  | 'failed'
  | 'reactivating';

export default class Offer<
  Attrs extends AdditionalAttributes = AdditionalAttributes,
> extends Entity<Offer<Attrs>> {
  /**
   * Id da oferta.
   * */
  id!: number;

  /**
   * Título da oferta.
   * */
  title!: string;

  /**
   * Descrição do produto.
   * */
  description!: string;

  /**
   * Id da conta do marketplace.
   * */
  marketplace_account_id!: number;

  /**
   * Conta do marketplace.
   * */
  account = Account.from({});

  /**
   * Url da oferta no marketplace.
   * */
  url = '';

  /**
   * Sku da oferta
   * */
  sku!: string;

  /**
   * EAN da oferta
   * */
  ean!: string;

  /**
   * Atributos adicionais do marketplace.
   * */
  additional_attributes: Attrs = {} as Attrs;

  /**
   * Lista de imagens da oferta.
   * */
  images: Array<Image> = [];

  /**
   * Lista de variações da oferta.
   * */
  variants: Array<OfferVariant> | null = [];

  /**
   * Quantidade de produtos
   * */
  stock = 0;

  weight!: number;

  dimensions: Dimensions = {};

  /**
   * Status do anúncio.
   * */
  status: OfferStatus = 'active';

  /**
   * Preço do produto.
   * */
  price!: number;

  /**
   * Preço promocional.
   * */
  sale_price!: number | null;

  /**
   * Inicio da promoção
   * */
  sale_starts_on!: string | null;

  /**
   * Fim da promoção
   * */
  sale_ends_on!: string | null;

  /**
   * Relacionamentos da oferta;
   *
   * */
  offer_relations!: OfferRelation;

  static from<A extends AdditionalAttributes, T extends Entity<Offer<A>>>(
    this: new () => T,
    attributes: Attributes<Offer<A>> = {}
  ): T {
    const instance = new this();

    instance.fill(attributes);
    return instance;
  }

  /**
   * Determina se a oferta tem relacionamento.
   *
   * */
  hasRelation(): boolean {
    return !!this.offer_relations;
  }

  /**
   * Retorna o id da variação de produto relacionada a variação de oferta com o id fornecido.
   *
   * @param {number} offerVariantId
   * @return string
   * */
  getRelatedProductOf(offerVariantId: number): string | null {
    if (!this.hasRelation()) {
      return null;
    }

    const relation = this.offer_relations.items.find((relation) => {
      return relation.offer_type === 'variant' && relation.offer_id === offerVariantId;
    });

    return relation?.product_id || null;
  }

  /**
   * Determina se a oferta tem imagens.
   * */
  hasImage(): boolean {
    return this.images.length > 0;
  }

  /**
   * Total de variações da oferta.
   * */
  totalOfVariations(): number {
    if (!this.variants) {
      return 0;
    }

    return this.variants.length;
  }

  /**
   * Determina se é uma oferta simples.
   *
   * @return boolean
   * */
  isSimple(): boolean {
    return this.totalOfVariations() === 0;
  }

  /**
   * Determina se é uma oferta composta.
   *
   * @return boolean
   * */
  isComposite(): boolean {
    return this.totalOfVariations() > 0;
  }

  /**
   * Determina se é uma variação composta de apenas um item.
   * */
  isSingleVariationComposite(): boolean {
    if (this.totalOfVariations() === 1) {
      return true;
    }

    return false;
  }

  /**
   * Determina se é uma variação simples ou composta de apenas um item.
   * */
  isSingleVariationOrSimple(): boolean {
    return this.isSingleVariationComposite() || this.isSimple();
  }

  /**
   * Determina se a oferta está com status falha
   *
   * @var {boolean}
   */
  isFailed(): boolean {
    return this.status === 'failed';
  }

  /**
   * Determina se a oferta está com status em fila
   *
   * @var {boolean}
   */
  isCreating(): boolean {
    return this.status === 'creating';
  }

  /**
   * Determina se a oferta é uma draft offer em criação.
   *
   * @var {boolean}
   */
  isDraftOfferCreating(): boolean {
    return (
      (this.creation_state === 'creating' ||
        this.creation_state === 'waiting_marketplace_approval') &&
      this.status === 'active'
    );
  }

  /**
   * Verifica se o item pode ser relacionado.
   *
   * @return {boolean}
   * */
  isRelatable(): boolean {
    const blockedStatuses = ['account_removed', 'reactivating', 'deleted_marketplace'];
    return !blockedStatuses.includes(this.status);
  }

  /**
   * Verifica se o item tem pendências na relação.
   *
   * @return {boolean}
   * */
  hasPendingRelation(): boolean {
    if (!this.hasRelation() || this.totalOfVariations() === 0) {
      return false;
    }

    const relationItemsIds = this.offer_relations.items.map((item) => item.offer_id).sort();
    const variantsIds = this.variants?.map((variant) => variant.id).sort();

    return !isEqual(relationItemsIds, variantsIds);
  }

  /**
   * Verifica se o oferta tem preço promocional ativo.
   *
   * @return {boolean}
   * */
  hasActiveSalePrice(): boolean {
    if (!this.sale_price || this.price <= this.sale_price) {
      return false;
    }
    return true;
  }

  /**
   * Verifica se o oferta com preço promocional possui um período de promoção.
   *
   * @return {boolean}
   * */
  hasPromotionalRange(): boolean {
    if (!this.hasActiveSalePrice() || !this.sale_starts_on || !this.sale_ends_on) {
      return false;
    }

    const lastPromotionDate = moment(this.sale_ends_on);
    const now = moment();

    const isTheSameDayOfPromotion =
      now.format('YYYY-MM-DD') === lastPromotionDate.format('YYYY-MM-DD');

    if (isTheSameDayOfPromotion) {
      return true;
    }

    return now.diff(lastPromotionDate) < 0;
  }
}
