import Entity from './entity';
import OrderLabelGroup from './order-label-group';

/**
 * Classe que representa um lote de etiquetas solicitadas.
 */
export default class OrderLabelBatchNew extends Entity<OrderLabelBatchNew> {
  /**
   * ID do batch
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * Chave da batch
   *
   * @var {number} id
   * */
  key!: string;

  /**
   * Grupos de itens da batch em que cada grupo representa uma conta
   * de um determinado marketplace
   *
   * @var {Array<OrderLabelGroup>} _groups
   * */
  protected _groups: Array<OrderLabelGroup> = [];

  /**
   * Quantidade de erros no lote de pedidos requisitados
   *
   * @var {number} failures
   * */
  failures!: number;

  /**
   * Status atual da batch
   *
   * @var {string} status - enquanto 'pending' está em processamento e 'completed' foi
   * concluída
   */
  status!: string;

  /**
   * ID do usuário
   *
   * @var {number} user_id
   * */
  user_id!: number;

  /**
   * Tipo do usuário
   *
   * @var {string} user_type
   * */
  user_type!: string;

  /**
   * Setter para as groups.
   * Garante que as groups sejam instâncias de OrderLabelGroup.
   * */
  set groups(groups: Array<OrderLabelGroup>) {
    if (!groups) {
      this._groups = [];
      return;
    }

    this._groups = groups.slice();
  }

  get groups(): Array<OrderLabelGroup> {
    return this._groups;
  }
}
