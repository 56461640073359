import { VueConstructor } from 'vue';
import numeral from 'numeral';
import { Filter } from './filter';

export class CurrencyFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para preço.
     *
     * @example {{ text | currency }}
     * */
    vue.filter('currency', function (value: string | number) {
      value = +value || 0;

      value = numeral(value).format('$ 0,0.00');

      return value;
    });
  }
}

export default CurrencyFilter;
