import { VueConstructor } from 'vue';
import numeral from 'numeral';
import { Filter } from './filter';

export class NumeralFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para cnpj.
     *
     * @example {{ text | cnpj }}
     * */
    vue.filter('numeral', function (value: any, format?: string) {
      if (!value && value !== 0) {
        return;
      }
      return numeral(value).format(format);
    });
  }
}

export default NumeralFilter;
