import { MutationTree } from 'vuex';
import { OfferRelationState, OfferRelationStep } from './types';
import { Offer, OfferRelation, Product, ProductVariant } from '@/entities';

export const mutations: MutationTree<OfferRelationState> = {
  /**
   * Define o produto.
   * */
  setProduct(state, product: Product | null) {
    state.product = product;
  },

  /**
   * Define a oferta.
   * */
  setOffer(state, offer: Offer | null) {
    state.offer = offer;
  },

  /**
   * Define o relacionamento.
   * */
  setRelation(state, relation: OfferRelation | null) {
    state.relation = relation;
  },

  /**
   * Define o erro.
   * */
  setError(state, error: any) {
    state.error = error;
  },

  /**
   * Define se a ação de salvar está ativa.
   * */
  setSaveActionEnabled(state, isEnabled) {
    state.saveActionEnabled = isEnabled;
  },

  /**
   * Define a etapa do relacionamento.
   * */
  setRelationStep(state, step: OfferRelationStep) {
    state.relationStep = step;
  },

  /**
   * Define as opções para o campo de variações do produto.
   * */
  setVariantsOptions(state, options: Array<ProductVariant>) {
    state.optionsVariantsProduct = options;
  },
};

export default mutations;
