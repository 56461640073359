import { RouteConfig } from 'vue-router';
import { ValidatesIntegrationAndPageToLoad } from '@/router/routes/guards';
import { PagesName } from '@/router/routes/pages-enum';

const hubLegacyRoutes: RouteConfig[] = [
  {
    path: '/ativar-marketplace',
    name: PagesName.LEGACY_LANDING_PAGE,
    component: () => import('@/pages/landing-page/landing-page.vue'),
    beforeEnter: ValidatesIntegrationAndPageToLoad,
  },
];

export default hubLegacyRoutes;
