import { GetterTree } from 'vuex';
import { PagingByRouteState, RoutesWithPaging } from './types';
import { RootState } from '@/store/types';
import { ApiPaging } from '@/components/api/client/http';
import { GetterPagingByRoute } from './enum-getter';

export const getters: GetterTree<PagingByRouteState, RootState> = {
  [GetterPagingByRoute.PAGING]:
    (state) =>
    (nameOfRoute: RoutesWithPaging): ApiPaging => {
      return state.route[nameOfRoute];
    },
};

export default getters;
