import { RouteConfig } from 'vue-router';
import { offersLegacyRoutes } from '@/router/routes/legacy-routes';
import { CheckAccount } from './guards';
import { PagesName } from '../../pages-enum';

const routesOffers: RouteConfig[] = [
  {
    path: '',
    name: PagesName.LOAD_OFFERS,
    component: () => import('@/pages/marketplaces/new-offers/layout-offers.vue'),
    beforeEnter: CheckAccount,
    redirect: { name: PagesName.LEGACY_PUBLISHED_OFFERS },
    children: [
      {
        path: 'offers',
        name: PagesName.PUBLISHED_OFFERS,
        meta: {
          title: 'Lista de anúncios',
          flow: 'new-flow',
        },
        component: () =>
          import('@/pages/marketplaces/new-offers/published-offers/published-offers.vue'),
      },
      {
        path: 'waiting-correction',
        name: PagesName.OFFERS_AWAITING_CORRECTION,
        meta: {
          title: 'Lista de anúncios',
          flow: 'new-flow',
        },
        component: () =>
          import(
            '@/pages/marketplaces/new-offers/offers-waiting-correction/offers-waiting-correction.vue'
          ),
      },
      {
        path: 'processing',
        name: PagesName.OFFERS_IN_PROCESSING,
        meta: {
          title: 'Lista de anúncios',
          flow: 'new-flow',
        },
        component: () =>
          import('@/pages/marketplaces/new-offers/offers-in-processing/offers-in-processing.vue'),
      },
      {
        path: 'pending-orders',
        name: PagesName.PENDING_SALES,
        meta: {
          title: 'Pedidos não inclusos',
          flow: 'new-flow',
        },
        component: () => import('@/pages/marketplaces/new-offers/pending-sales/pending-sales.vue'),
      },
      ...offersLegacyRoutes,
    ],
  },
  {
    path: 'offers/add',
    name: PagesName.OFFERS_ADD,
    meta: {
      title: 'Criar anúncio',
      flow: 'new-flow',
    },
    component: () => import('@/pages/marketplaces/new-offers/create-offer/create-offer.vue'),
  },
];

export default routesOffers;
