import { MutationTree } from 'vuex';
import { MarketplacesState, ConfigMarketplace } from './types';
import { MutationMarketplaces } from './enum-mutation';

export const mutations: MutationTree<MarketplacesState> = {
  [MutationMarketplaces.SAVE_LIST](state, list: ConfigMarketplace[]) {
    state.marketplaces = list;
  },
  [MutationMarketplaces.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },
  [MutationMarketplaces.SAVE_CONFIGS_CURRENT_MARKETPLACE](state, data: ConfigMarketplace) {
    state.configsCurrentMarketplace = data;
  },
};

export default mutations;
