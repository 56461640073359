import Vue from 'vue';
import { ActionTree } from 'vuex';

import { HubApiResponse } from '@/components/api/hub-client/http';
import { PayloadfetchStore, PayloadGetExists, StoreState } from './types';
import { RootState } from '../../types';
import { ActionsStore } from './enum';

export const actions: ActionTree<StoreState, RootState> = {
  async [ActionsStore.BY_ID](
    { commit, getters },
    { storeId, forceLoad = false }: PayloadfetchStore
  ) {
    const { store } = getters;
    if (store && store.id === storeId && !forceLoad) {
      return Promise.resolve(store);
    }

    return Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/stores/${storeId}`)
      .then((response) => {
        commit('setStore', response.getData());
        return getters.store;
      })
      .catch((error) => {
        commit(
          'uiSettings/setNotification',
          {
            where: 'Action get store by id',
            error,
            toaster: 'b-toaster-top-right',
          },
          {
            root: true,
          }
        );
      });
  },

  async [ActionsStore.CHECK_IF_EXISTS](
    context,
    { storeId, platform }: PayloadGetExists
  ): Promise<boolean> {
    try {
      if (!storeId || !platform) return false;

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<any, HubApiResponse>(`/stores/${storeId}/exists`, {
        params: {
          'filter[platform]': platform,
        },
      });
      const storeExists = response.getData().exists;

      return storeExists;
    } catch (error) {
      return false;
    }
  },
};

export default actions;
