// @ts-ignore
import flatpickr from 'flatpickr';
import { Installer, Locale } from './installer';

export class FlatpickrInstaller implements Installer {
  /**
   * @inheritDoc
   */
  getLocale(locale: Locale): Promise<any> {
    return import(`../locales/${locale}/flatpickr-locale.ts`);
  }

  /**
   *  @inheritDoc
   */
  setLocale(locale: Locale): void {
    this.getLocale(locale).then((module: any) => {
      flatpickr.localize(module.default);
    });
  }
}

export default FlatpickrInstaller;
