import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FiltersModule } from './filters';

const namespaced = true;

export const productsModule: Module<any, RootState> = {
  namespaced,
  modules: {
    filters: FiltersModule,
  },
};

export default productsModule;
