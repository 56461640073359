import { AdjustmentValueAPI } from '../pricing-rule';

/**
 * Mixin estático para converter o valor adicional de acordo com a configuração da regra
 * de precificação.
 */
export class ConvertAdditionalPrice {
  /**
   * Converte o valor adicional para o formato de real ou porcentage de acordo com a
   * config passada por parâmetro.
   */
  static convertToRealOrPercentageByConfig(config: AdjustmentValueAPI): string {
    if (config?.type === 'percentage') {
      return `${config?.value?.toString().replace('.', ',')}%`;
    }

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(config?.value || 0);
  }
}
