/**
 * Enum para os status de cada conta na geração de etiquetas de pedido
 */
export enum OrderLabelsBatchStatus {
  PENDING = 'pending',
  COMPLETED = 'completed',
}

/**
 * Enum para os status de cada conta na geração de etiquetas de pedido
 */
export enum OrderLabelsGroupStatus {
  PENDING = 'pending',
  CANCELED = 'canceled',
  COMPLETED = 'completed',
}

/**
 * Enum para os status das etiquetas de pedido por item "cada etiqueta".
 */
export enum OrderLabelsItemStatus {
  PENDING = 'pending',
  SUCCESS = 'success',
  FAILURE = 'failure',
}

/**
 * Enum para os motivos de falha na geração das etiquetas de pedido.
 */
export enum OrderLabelsItemFailureReason {
  CANCELLED = 'Invalid order status CANCELLED',
  SHIPPED = 'Invalid order status SHIPPED',
  INVOICE_REQUIRED = 'Order invoice is required',
}

/**
 * Enum para os tipos de falha na geração das etiquetas de pedido.
 */
export enum OrderLabelsItemFailureType {
  CANCELED = 'canceled',
  CANCELLED = 'cancelled',
  SHIPPED = 'shipped',
  INVOICE_REQUIRED = 'invoice_required',
  DELIVERED = 'delivered',
  UNKNOWN = 'unknown',
}
