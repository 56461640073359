import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';
import { Storage } from '@/components/storage';
import {
  GenericFilter,
  SimpleFilterEqCondition,
  SimpleFilterInCondition,
} from '@tray-libs/design-system-components/src/components/ui/filter/models';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const ApiOrderAttributes = {
  order_filter: '[marketplace_order_id]',
  initial_created_date: '[marketplace_created_at>=]',
  final_created_date: '[marketplace_created_at<=]',
  order_customer_id: '[marketplace_customer_id]',
  account_id: '[marketplace_account_id]',
};

export const state: FilterState = {
  /**
   * Contexto de lista de pedidos.
   */
  context: 'order-list',

  /**
   * Ordenação da lista de pedidos.
   * */
  sort: Storage.get('order-sorting') || '-marketplace_order_id',

  /**
   * Determina se o filtro avançado está aberto.
   */
  isAdvancedFilterOpen: false,

  /**
   * Número de itens por paginação.
   * */
  pageSize: +Storage.get('order-paging') || 25,

  /**
   * Número da página.
   * */
  pageNumber: 1,

  /**
   * Lista de possíveis filtros da lista de pedidos.
   * */
  instances: {
    order_filter: new GenericFilter({
      label: 'Pedido',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiOrderAttributes.order_filter,
        isPartialSearch: false,
        value: '',
      }),
    }),
    initial_created_date: new GenericFilter({
      label: 'Data Inicial',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiOrderAttributes.initial_created_date,
        value: '',
      }),
    }),
    final_created_date: new GenericFilter({
      label: 'Data Final',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiOrderAttributes.final_created_date,
        value: '',
      }),
    }),
    order_customer_id: new GenericFilter({
      label: 'CPF/CNPJ',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiOrderAttributes.order_customer_id,
        value: '',
      }),
    }),
    account_id: new GenericFilter({
      label: 'Apelidos',
      isVisible: false,
      condition: SimpleFilterInCondition.from({
        apiAttribute: ApiOrderAttributes.account_id,
        value: [],
      }),
    }),
  },
};

const namespaced = true;

export const FiltersModule: Module<FilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
