import Vue from 'vue';
import { ActionTree } from 'vuex';
import { OrderState } from './types';
import { RootState } from '@/store/types';
import { HubApiResponse } from '@/components/api/hub-client';

export const actions: ActionTree<OrderState, RootState> = {
  /**
   * Busca a quantidade de pedidos aguarndando relacionamento de ofertas.
   *
   * */
  async getQuantityPendingOrders(
    { commit, getters, rootGetters },
    storeId: number,
    forceLoad = false
  ) {
    const activeMarketplace = rootGetters['marketplace/activeMarketplace'];
    const { quantityPendingOrders } = getters;

    if (quantityPendingOrders && !forceLoad) {
      return Promise.resolve(quantityPendingOrders);
    }

    return Vue.$apiClient
      .getHttpClient()
      .get(`/stores/${storeId}/orders`, {
        params: {
          'filter[platform_dispatching_state]': 'awaiting-offers-relations',
          'filter[status!=][0]': 'delivered',
          'filter[status!=][1]': 'canceled',
          'filter[status!=][2]': 'shipped',
          'filter[marketplace]': activeMarketplace.id,
        },
      })
      .then((response) => {
        const result = response as HubApiResponse;
        commit('setQuantityPendingOrders', result.getPaging().total);
        return getters.quantityPendingOrders;
      })
      .catch(() => {
        commit('setQuantityPendingOrders', null);
        return null;
      });
  },
};

export default actions;
