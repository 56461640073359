import { MutationTree } from 'vuex';
import { CreateOfferState, storageKeys } from './types';
import { Storage } from '@/components/storage';

export const mutations: MutationTree<CreateOfferState> = {
  /**
   * Define os itens selecionados.
   * */
  setItems(state, items: CreateOfferState['items']) {
    state.items = items;
    Storage.set(storageKeys.selectedItems, items);
  },

  /**
   * Define a loja.
   * */
  setStoreId(state, storeId: CreateOfferState['storeId']) {
    state.storeId = storeId;
    Storage.set(storageKeys.storeIdStorageKey, storeId);
  },
};

export default mutations;
