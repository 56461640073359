import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';
import { Account, Marketplace, OfferImport } from '@/entities';
import Application from '@/entities/application';
import { WizardConfig, WizardState, WizardActiveStep } from './types';
import { Getters } from './enum';

export const getters: GetterTree<WizardState, RootState> = {
  [Getters.NEW_ACCOUNT](state): Account | null {
    return state.newAccount;
  },

  [Getters.OFFER_IMPORT](state): OfferImport | null {
    return state.offerImport;
  },

  [Getters.MARKETPLACE](state): Marketplace | null {
    return state.marketplace;
  },

  [Getters.MARKETPLACE_APPLICATION](state): Application | null {
    if (!state.marketplace) {
      return null;
    }
    return state.marketplace.application;
  },

  [Getters.WIZARD_CONFIG_FROM_API](state): WizardConfig | null {
    if (!state.marketplace || !state.marketplace.wizard_config) {
      return null;
    }
    return state.marketplace.wizard_config;
  },

  [Getters.WIZARD_CONFIG](state): WizardConfig | null {
    return state.wizardConfig;
  },

  [Getters.ACTIVE_STEP](state): WizardActiveStep | null {
    return state.activeStep;
  },
};

export default getters;
