<script lang="ts">
import Vue from 'vue';
import Component from 'vue-class-component';
import { Watch } from 'vue-property-decorator';
import { NotificationBvToast } from './store/modules/ui-settings/types';

@Component
export default class App extends Vue {
  get theme(): string {
    return this.$store.getters['uiSettings/theme'];
  }

  /**
   * Obtém alguma notificação ocorrida no APP para exibir o toast.
   */
  get notificationInApp(): NotificationBvToast | null {
    return this.$store.getters['uiSettings/notification'];
  }

  beforeMount(): void {
    this.addThemeEventListener();
    this.onThemeChange();
  }

  @Watch('theme')
  onThemeChange(): void {
    const html = document.querySelector('html');

    if (!html) {
      return;
    }

    html.classList.remove('theme--light');
    html.classList.remove('theme--dark');
    html.classList.add(`theme--${this.theme}`);
  }

  addThemeEventListener(): void {
    window.addEventListener('message', (event) => {
      if (!event.data || !event.data.theme) {
        return;
      }
      this.$store.dispatch('uiSettings/setTheme', event.data.theme);
    });
  }

  /**
   * Observa a mudança do estado de erro geral do projeto e exibe um toast com o erro.
   */
  @Watch('notificationInApp')
  onWatchError(notification: NotificationBvToast | null): void {
    if (notification) {
      this.$bvToast.toast(notification.message, {
        title: notification.title,
        autoHideDelay: notification.autoHideDelay,
        solid: true,
        variant: notification.variant,
        toaster: notification.toaster,
        appendToast: notification.appendToast,
        noAutoHide: notification.noAutoHide,
        bodyClass: 'text-break',
      });
    }
  }
}
</script>
<template>
  <div id="app">
    <router-view />
  </div>
</template>

<style lang="scss">
@import 'design';
@import '@sweetalert2/themes/bootstrap-4';
</style>
