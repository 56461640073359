import { Order } from '@/entities';

export enum MutationPendingSales {
  /**
   * Salva a lista de anúncios publicados passada por parâmetro.
   * @param {Order[]} list - Lista de anúncios publicados a salvar
   */
  SAVE_LIST = 'SAVE_LIST',

  /**
   * Altera o estado de carregamento da lista de anúncios publicados.
   * @param {boolean} isLoading - Estado de carregamento da lista de anúncios publicados
   */
  SET_LOADING = 'SET_LOADING',
}
