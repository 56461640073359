import Vue from 'vue';
import { MutationTree } from 'vuex';
import { MarketplaceState } from './types';
import { Marketplace } from '@/entities';

type MarketplacePayload = {
  ref: keyof MarketplaceState['marketplaces'];
  attributes: object;
};

export const mutations: MutationTree<MarketplaceState> = {
  /**
   * Define o marketplace ativo.
   * */
  setActiveMarketplaceRef(state, marketplaceRef: string | null) {
    state.activeMarketplaceRef = marketplaceRef || 'shopee';
  },

  /**
   * Define o marketplace ativo.
   * */
  setActiveMarketplace(state, marketplace: Marketplace) {
    if (!marketplace) {
      state.activeMarketplace = null;
      return;
    }
    state.activeMarketplace = marketplace;
  },

  /**
   * Define o marketplace fornecido.
   * */
  setMarketplace(state, { ref, attributes }: MarketplacePayload) {
    if (!attributes) {
      Vue.set(state.marketplaces, ref, null);
      return;
    }

    Vue.set(state.marketplaces, ref, Marketplace.from(attributes));
  },
};

export default mutations;
