import { Module } from 'vuex';

import { Storage } from '@/components/storage';
import { CreateOfferState, storageKeys } from './types';
import { RootState } from '../../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { ShopeeReviewModule } from './shopee-review';
import { attributesModule } from './attributes';

export const state: CreateOfferState = {
  storeId: Storage.get(storageKeys.storeIdStorageKey, null),
  items: Storage.get(storageKeys.selectedItems, []),
};

const namespaced = true;

export const CreateOfferModule: Module<CreateOfferState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    shopeeReview: ShopeeReviewModule,
    reviewAttributes: attributesModule,
  },
};
