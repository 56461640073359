export enum MutationListWaitingCorrection {
  /**
   * Salva a lista de anúncios aguardando correção passada por parâmetro.
   */
  SAVE_LIST = 'SAVE_LIST',

  /**
   * Altera o estado de carregamento da lista de anúncios agurdando correção.
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Salva as opções de status para seleção do filtro.
   */
  SET_STATUS_OPTIONS = 'SET_STATUS_OPTIONS',

  /**
   * Salva a oferta com erro
   */
  SET_OFFER_WITH_ERROR = 'SET_OFFER_WITH_ERROR',
  /**
   * Salva a verificação se a oferta com erro tem ações
   */
  SET_OFFER_WITH_ERROR_HAS_ACTIONS = 'SET_OFFER_WITH_ERROR_HAS_ACTIONS',
  /**
   * Salva a confirmação de que a oferta com erro pode ser salva
   */
  SET_OFFER_WITH_ERROR_CAN_SAVE = 'SET_OFFER_WITH_ERROR_CAN_SAVE',

  /**
   * Salva as variações de uma draft offer
   */
  SET_OFFER_VARIANTS = 'SET_OFFER_VARIANTS',

  /**
   * Salva os erros de uma draft offer
   */
  SET_OFFER_ERRORS = 'SET_OFFER_ERRORS',

  /**
   * Salva o novo titulo da oferta com erro
   */
  SET_OFFER_NEW_TITLE = 'SET_OFFER_NEW_TITLE',

  /**
   * Salva a oferta a ser removida.
   */
  SET_OFFER_REMOVE = 'SET_OFFER_REMOVE',
  /**
   * Reseta os erros de uma draft offer
   */
  RESET_ERRORS = 'RESET_ERRORS',
}
