import moment from 'moment';
import Entity from './entity';
import ProductVariant from './product-variant';
import Image from './image';
import { ProductType, ProductStatus, Dimensions, Characteristics } from './types';

export type SelectionInfo = {
  tag: string;
  message: string;
};

export default class Product extends Entity<Product> {
  //@TODO mapear demais atributos do produto

  /**
   * Id do produto.
   *
   * @var {string}
   * */
  id!: string;

  /**
   * Título do produto.
   *
   * @var {string}
   * */
  title!: string;

  /**
   * Descrição do produto.
   *
   * @var {string}
   **/
  description!: string;

  /**
   * Tipo do produto
   *
   * @var {ProductType}
   * */
  type!: ProductType;

  /**
   * EAN do produto
   *
   * @var {string}
   * */
  ean!: string;

  /**
   * Peso do produto
   *
   * @var {number}
   **/
  weight!: number;

  /**
   * Dimensões do produto
   *
   * @var {Dimensions}
   */
  dimensions: Dimensions = {};

  /**
   * Status do produto
   *
   * @var {ProductStatus}
   * */
  status!: ProductStatus;

  /**
   * Referencia do produto
   *
   * @var {string}
   * */
  reference!: string;

  /**
   * Marca do produto
   */
  brand!: {
    platform_brand_id: number;
    name: string;
  };

  /**
   * Características do produto
   */
  characteristics!: Characteristics;

  /**
   * Informações adicionais para a seleção do produto.
   */
  selection_info!: Array<SelectionInfo>;

  /**
   * Valor do produto.
   *
   * @var {number}
   * */
  price!: number;

  /**
   * Pra de disponibilidade.
   *
   * @var {number|undefined}
   * */
  availability_days!: number | undefined;

  /**
   * Contém as variações do produto. (Apenas para produtos compostos)
   *
   * @var {ProductType}
   * */
  protected _variants: Array<ProductVariant> | null = null;

  /**
   * Lista de imagens do produto
   *
   * @var {Array<Image>}
   * */
  protected _images: Array<Image> = [];

  /**
   * Quantidade em estoque.
   *
   * @var {number}
   * */
  private _stock = 0;

  set stock(value: number) {
    this._stock = +(value as any) || 0;
  }

  get stock(): number {
    return this._stock;
  }

  /**
   * Setter para as variações.
   * Garante que as variações sejam instâncias de ProductVariant.
   * */
  set variants(variants: Array<ProductVariant> | null) {
    if (!variants) {
      this._variants = null;
      return;
    }

    this._variants = variants.map((variant) => {
      if ((variant as unknown) instanceof ProductVariant) {
        return variant;
      }

      return ProductVariant.from(variant as object);
    });
  }

  get variants(): Array<ProductVariant> | null {
    return this._variants;
  }

  /**
   * Setter para as imagens.
   * Garante que as variações sejam uma lista de instâncias de Image.
   * */
  set images(images: Array<Image>) {
    if (!(images as unknown)) {
      this._images = [];
      return;
    }

    this._images = images.map((image) => {
      if ((image as unknown) instanceof Image) {
        return image;
      }

      return Image.from(image as object);
    });
  }

  get images(): Array<Image> {
    return this._images;
  }

  /**
   * Valor do produto na promoção.
   *
   * @var {number | null}
   * */
  private _promotional_price: number | null = null;

  set promotional_price(value: number | null) {
    this._promotional_price = +(value as any) || null;
  }

  get promotional_price(): number | null {
    return this._promotional_price;
  }

  /**
   * Data inicial da promoção.
   *
   * @var {string}
   * */
  private _promotion_start_date: string | null = null;

  set promotion_start_date(value: string | null) {
    const date = moment(value);
    this._promotion_start_date = date.isValid() ? value : null;
  }

  get promotion_start_date(): string | null {
    return this._promotion_start_date;
  }

  /**
   * Data final da promoção.
   *
   * @var {string}
   * */
  private _promotion_end_date: string | null = null;

  set promotion_end_date(value: string | null) {
    const date = moment(value);
    this._promotion_end_date = date.isValid() ? value : null;
  }

  get promotion_end_date(): string | null {
    return this._promotion_end_date;
  }

  /**
   * Verifica se o periodo de promoção esta ativo.
   *
   * @var {boolean} hasPeriodPromotion
   * */
  get hasPeriodPromotion(): boolean {
    if (!this.promotional_price || !this.promotion_start_date || !this.promotion_end_date) {
      return false;
    }

    const lastPromotionDate = moment(this.promotion_end_date);
    const now = moment();

    if (now.format('YYYY-MM-DD') === lastPromotionDate.format('YYYY-MM-DD')) {
      return true;
    }

    return now.diff(lastPromotionDate) < 0;
  }

  /**
   * Verifica se o produto possui estoque.
   *
   * @return {boolean}
   * */
  hasStock(): boolean {
    return this.stock > 0;
  }

  /**
   * Determina se a oferta tem imagens.
   * */
  hasImage(): boolean {
    return this.images.length > 0;
  }

  /**
   * Determina se é um produto simples.
   *
   * @return {boolean}
   * */
  isSimple(): boolean {
    return this.type === 'simple';
  }

  /**
   * Determina se é um produto composto.
   *
   * @return {boolean}
   * */
  isComposite(): boolean {
    return this.type === 'composite';
  }

  /**
   * Determina se é uma variação composta de apenas um item.
   * */
  isSingleVariationComposite(): boolean {
    if (this.variants && this.variants.length === 1) {
      return true;
    }

    return false;
  }

  /**
   * Determina se é uma variação simples ou composta de apenas um item.
   * */
  isSingleVariationOrSimple(): boolean {
    return this.isSingleVariationComposite() || this.isSimple();
  }

  /**
   * Determina se é um kit.
   *
   * @return {boolean}
   * */
  isKit(): boolean {
    return this.type === 'kit';
  }

  /**
   * Total de variações do produto.
   * */
  totalOfVariations(): number {
    if (!this.variants) {
      return 0;
    }

    return this.variants.length;
  }

  getVariant(productVariantId: string): ProductVariant | null {
    return this.variants?.find((variant) => variant.id === productVariantId) || null;
  }

  /**
   * Retorna as informações de seleção do produto.
   */
  getSelectionInfo(): Array<SelectionInfo> {
    return this.selection_info || [];
  }

  /**
   * Verifica se o produto possui uma tag de seleção.
   * */
  hasSelectionTag(): boolean {
    return this.selection_info && this.selection_info.length > 0;
  }

  /**
   * Verifica se o produto tem preço promocional ativo.
   *
   * @return {boolean}
   * */
  hasActiveSalePrice(): boolean {
    if (!this.promotional_price || this.price <= this.promotional_price) {
      return false;
    }
    return true;
  }

  /**
   * Verifica se o produto com preço promocional possui um período de promoção.
   *
   * @return {boolean}
   * */
  hasPromotionalRange(): boolean {
    if (!this.hasActiveSalePrice() || !this.promotion_start_date || !this.promotion_end_date) {
      return false;
    }

    const lastPromotionDate = moment(this.promotion_end_date);
    const now = moment();

    const isTheSameDayOfPromotion =
      now.format('YYYY-MM-DD') === lastPromotionDate.format('YYYY-MM-DD');

    if (isTheSameDayOfPromotion) {
      return true;
    }

    return now.diff(lastPromotionDate) < 0;
  }
}
