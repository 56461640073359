import { Module } from 'vuex';

import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { OrderLabelsState } from './types';

export const state: OrderLabelsState = {
  batchId: '',
  batchStatus: 'pending',
  accounts: [],
  marketplaces: [],
  hasErrors: false,
  isLoading: true,
};

const namespaced = true;

export const orderLabelsModule: Module<OrderLabelsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
