import { MutationTree } from 'vuex';
import { PublishedOffersListState } from './types';
import { MutationListPublishedOffers } from './enum-mutation';
import Offer from '@/entities/offer-new';
import Vue from 'vue';
import { PricingRule } from '@/entities';

export const mutations: MutationTree<PublishedOffersListState> = {
  [MutationListPublishedOffers.SAVE_LIST](state, list: Offer[]) {
    state.list = list;
  },

  [MutationListPublishedOffers.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  [MutationListPublishedOffers.SELECT_OFFER](state, offer: Offer) {
    const id = offer.id;
    const indexToChange = state.list.findIndex((item) => {
      return item.id === id;
    });

    /** Reolve a reatividade do componente de tabela do bootstrap e não pode ser removido, apesar do newItem não estar sendo atribuido */
    const toChange = state.list[indexToChange];
    toChange.selected = !toChange.selected;
    // eslint-disable-next-line
    const newItem: Offer = Object.assign({}, toChange);
    Vue.set(state.list[indexToChange], 'selected', !state.list[indexToChange].selected);
  },

  [MutationListPublishedOffers.SELECT_OR_DESELECT_ALL](state) {
    const isAllSelected = state.list.every((item) => item.selected);

    state.list.forEach((item) => {
      if (isAllSelected) {
        item.selected = false;
      }

      if (!isAllSelected) {
        item.selected = true;
      }
    });
  },

  [MutationListPublishedOffers.SET_SELECTION_AS](state, selected: boolean) {
    state.list.forEach((item) => {
      item.selected = selected;
    });
  },

  [MutationListPublishedOffers.SET_PRICING_TO_EDIT](state, pricing: PricingRule) {
    state.pricing.toEdit = pricing;
  },

  [MutationListPublishedOffers.SET_PRICING_LIST_IDS_OFFER](state, idsOffer: number[]) {
    state.pricing.idsOffer = idsOffer;
  },

  [MutationListPublishedOffers.RESET_PRICING](state) {
    state.pricing = {
      toEdit: null,
      idsOffer: null,
    };
  },

  [MutationListPublishedOffers.SET_RELOAD_LIST](state, reloadList: boolean) {
    state.reloadList = reloadList;

    setTimeout(() => {
      state.reloadList = false;
    }, 300);
  },
};

export default mutations;
