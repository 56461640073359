import Vue from 'vue';
import { AttributeValue } from '@/store/modules/categories/types';
import { Account } from '@/entities';
import CategoryAttribute from '@/entities/category-attributes';
import { ApiResponse } from '@/components/api/client/http';
import { endpoints } from './endpoints';

/**
 * Classe com os métodos de API para obter e salvar os atributos que são as
 * características do marketplace e plataforma.
 */
export class CategoryAttributesFinder {
  protected storeId: string | null = Vue.$apiClient.getStore().getters['store/store'].id.toString();

  /**
   * Identificador da conta ativa.
   * Ainda não compatível com multi-contas por isso é o primeiro item da lista.
   */
  protected accountId: string | null = (
    Vue.$apiClient.getStore().getters['account/accounts'][0] as Account
  )?.id.toString();

  protected marketplaceId: string | null = Vue.$apiClient
    .getStore()
    .getters['marketplace/activeMarketplace']?.id.toString();

  protected client = Vue.$apiClient.getHttpClient();

  /**
   * Busca as características relacionadas a categoria do marketplace
   *
   * @param categoryRef - Referência da categoria do martketplace
   * @returns {Promise<CategoryAttribute[]>}
   */
  async allMarketplaceByCategoryRef(categoryRef: string): Promise<CategoryAttribute[]> {
    if (!this.accountId) throw new Error('Account ID not found');

    const apiUrl = endpoints.getAttributesMarketplaceByCategory
      .replace(':accountId', this.accountId)
      .replace(':categoryRef', categoryRef);

    return this.client
      .get<CategoryAttribute[], ApiResponse>(apiUrl, {
        timeout: 90000,
      })
      .then((response) => {
        return response.getData();
      });
  }

  /**
   * Busca as características da plataforma relacionadas a categoria do marketplace
   *
   * @param categoryRef - Referência da categoria da plataforma
   * @param params - Parâmetros de busca das características, quando não informado é
   * criado um novo URLSearchParams vazio
   *
   * @returns {Promise<CategoryAttribute[]>}
   */
  async allPlatformByCategoryRef(
    categoryRef: string,
    params: URLSearchParams = new URLSearchParams()
  ): Promise<CategoryAttribute[]> {
    if (!this.storeId) throw new Error('Store ID not found');

    if (!this.marketplaceId) throw new Error('Marketplace ID not found');

    const apiUrl = endpoints.getAttributesPlatformByCategory
      .replace(':storeId', this.storeId)
      .replace(':categoryRef', categoryRef);

    params.set('filter[marketplace_id]', this.marketplaceId);
    params.set('page[size]', '50');
    params.set('sort', 'name');

    return this.client
      .get<CategoryAttribute[], ApiResponse>(apiUrl, { params })
      .then((response) => {
        return response.getData();
      });
  }

  /**
   * Busca os valores das características da plataforma relacionadas a categoria do
   * marketplace
   *
   * @param categoryRef - Referência da categoria da plataforma
   * @param attributeRef - Referência da característica da plataforma
   * @returns {Promise<AttributeValue[]>}
   */
  async allValuesOfPlatformByCategoryAndAttributeRef(
    categoryRef: string,
    attributeRef: string
  ): Promise<AttributeValue[]> {
    if (!this.storeId) throw new Error('Store ID not found');

    const apiUrl = `${endpoints.getAttributesPlatformByCategory
      .replace(':storeId', this.storeId)
      .replace(':categoryRef', categoryRef)}/${attributeRef}/values`;

    return this.client.get<AttributeValue[], ApiResponse>(apiUrl).then((response) => {
      return response.getData();
    });
  }

  /**
   * Busca os valores das características do marketplace
   *
   * @param categoryRef - Referência da categoria do marketplace
   * @returns {Promise<AttributeValue[]>}
   */
  async allValuesOfMarketplaceByCategoryAndAttributeRef(
    categoryRef: string,
    attributeRef: string
  ): Promise<AttributeValue[]> {
    if (!this.accountId) throw new Error('Account ID not found');

    const apiUrl = `${endpoints.getAttributesMarketplaceByCategory
      .replace(':accountId', this.accountId)
      .replace(':categoryRef', categoryRef)}/${attributeRef}/values`;

    return this.client
      .get<AttributeValue[], ApiResponse>(apiUrl, {
        timeout: 90000,
      })
      .then((response) => {
        return response.getData();
      });
  }
}
