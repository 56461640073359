import Vue from 'vue';
import VueRouter from 'vue-router';
import NProgress from 'nprogress';

import routes from './routes';
import { Authenticate, FetchStore, ThemeSetter } from './guards';
import { toggleNewFlow } from './routes/guards/toggle-new-flow';

Vue.use(VueRouter);

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

// Before each route evaluates...
router.beforeEach((routeTo, routeFrom, next) => {
  // If this isn't an initial page load...
  NProgress.start();
  next();
});

router.beforeEach(Authenticate);
router.beforeEach(FetchStore);
router.beforeEach(ThemeSetter);
router.beforeEach(toggleNewFlow);

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
