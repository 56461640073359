import moment from 'moment';
import Entity from './entity';
import { ImageThumb } from './image';
import { Dimensions } from './types';

export default class OrderOffer extends Entity<OrderOffer> {
  /**
   * Id do anuncio relacioando ao pedido.
   */
  id!: number;

  /**
   * ID do usuário
   */

  user_id!: number;
  /**
   * Id do pedido
   */

  order_id!: number;
  /**
   * Código no anuncio
   */
  code!: string | number;

  /**
   * EAN da oferta
   * */
  ean?: any;

  /**
   * Status do anúncio.
   * */
  status!: string;

  /**
   * Sku da oferta
   * */
  sku!: string;

  /**
   * Url da oferta no marketplace.
   * */
  url!: string;

  /**
   * Título da oferta.
   * */
  title!: string;

  /**
   * Descrição do produto.
   * */
  description!: string;

  /**
   * Preço do produto.
   * */
  price!: number;

  /**
   * Preço promocional.
   * */
  sale_price?: number | null;

  /**
   * Inicio da promoção
   * */
  sale_starts_on!: string | null;

  /**
   * Fim da promoção
   * */
  sale_ends_on!: string | null;

  /**
   * Quantidade de produtos
   * */
  stock!: number;

  /**
   * Data de criação
   */
  created_at!: string;

  /**
   * Data de atualização.
   */
  updated_at!: string;

  /**
   * Imagens do produto.
   */
  images!: ImageThumb[];

  /**
   * Tamanho do produto.
   */
  dimensions!: Dimensions | null;

  /**
   * Verifica se o oferta tem preço promocional ativo.
   *
   * @return {boolean}
   * */
  hasActiveSalePrice(): boolean {
    if (!this.sale_price || this.price <= this.sale_price) {
      return false;
    }
    return true;
  }

  /**
   * Verifica se o oferta com preço promocional possui um período de promoção.
   *
   * @return {boolean}
   * */
  hasPromotionalRange(): boolean {
    if (!this.hasActiveSalePrice() || !this.sale_starts_on || !this.sale_ends_on) {
      return false;
    }

    const lastPromotionDate = moment(this.sale_ends_on);
    const now = moment();

    const isTheSameDayOfPromotion =
      now.format('YYYY-MM-DD') === lastPromotionDate.format('YYYY-MM-DD');

    if (isTheSameDayOfPromotion) {
      return true;
    }

    return now.diff(lastPromotionDate) < 0;
  }
}
