import { GetterTree } from 'vuex';

import { Marketplace } from '@/entities';
import { MarketplaceState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<MarketplaceState, RootState> = {
  /**
   * Retorna o marketplace ativo.
   * */
  activeMarketplace(state): Marketplace | null {
    return state.activeMarketplace;
  },

  /**
   * Retorna o nome marketplace ativo começando com letra maiúscula.
   * */
  activeMarketplaceNameInCapitalize(state): string {
    return state.activeMarketplace?.name.replace(/\b(\w)/g, (s) => s.toUpperCase()) || '';
  },

  /**
   * Retorna o marketplace ativo.
   * */
  activeMarketplaceRef(state): string {
    return state.activeMarketplaceRef;
  },

  /**
   * Retorna o marketplace com a referência fornecida.
   * */
  getMarketplace(state): (ref: any) => Marketplace | null {
    return (ref: any) => {
      for (const marketplace in state.marketplaces) {
        if (
          marketplace === ref ||
          state.marketplaces[marketplace]?.ref === ref ||
          state.marketplaces[marketplace]?.id === ref
        ) {
          return state.marketplaces[marketplace];
        }
      }

      return null;
    };
  },
};

export default getters;
