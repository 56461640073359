import { NavigationGuard, Route } from 'vue-router/types/router';
import axios, { AxiosError } from 'axios';

import store from '@/store';
import { Storage } from '@/components/storage';
import router from '../index';
import { QueryParamsInitialPage } from '../routes/guards';

async function removeTokenFromQuery(routeTo: Route) {
  const { query, name, params, path } = Object.assign({}, routeTo);
  delete query.token;

  await router
    .replace({
      name: name ?? undefined,
      path,
      params,
      query,
    })
    .catch((error: Error) => {
      /**
       * O replace acima faz com que o Vue-router entenda como uma tentativa de navegação duplicada para a mesma rota, por isso esse catch trata e resolve o erro de navegação ao iniciar a autenticação do app
       * @see {@link https://github.com/vuejs/vue-router/issues/2881#issuecomment-520554378} sobre o erro de navegação duplicada
       */
      const isErrorRedirect = error.message.includes('Redirected when going from');
      if (isErrorRedirect) return;
      console.error(error);
    });
}

export const Authenticate: NavigationGuard = async (routeTo, routeFrom, next) => {
  const authRequired = routeTo.matched.some((route) => route.meta.authRequired);
  if (!authRequired) {
    return next();
  }

  const token = routeTo.query.token;
  if (token) {
    await store.dispatch('auth/setToken', token);
    await removeTokenFromQuery(routeTo);

    if (routeTo.query.redirect_to_name) {
      const currentQuery: QueryParamsInitialPage = {
        store: Number(routeTo.query?.store) ?? 0,
        marketplace: (routeTo.query?.marketplace as string) ?? '',
        platform: (routeTo.query?.platform as string) ?? '',
        url: (routeTo.query?.url as string) ?? '',
      };
      Storage.set('paramsInitHub', currentQuery);

      return next({
        name: routeTo.query.redirect_to_name as string,
        params: {
          marketplace: routeTo.query.marketplace as string,
        },
      });
    }

    return;
  }

  if (!store.getters['auth/isAuthenticated']) {
    return next({ name: 'unauthorized' });
  }

  let redirectTo = routeTo.query.redirect_to;
  if (redirectTo && typeof redirectTo === 'string') {
    redirectTo = decodeURIComponent(redirectTo);
    return next(redirectTo);
  }

  store
    .dispatch('auth/fetchUser')
    .then((user) => {
      if (!user) {
        return next({ name: 'unauthorized' });
      }

      return next();
    })
    .catch((error: AxiosError) => {
      if (axios.isCancel(error)) {
        return;
      }

      return next({
        name: 'internal-error',
        query: {
          reason: 'could not authenticate user',
        },
      });
    });
};
