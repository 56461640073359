import Vue from 'vue';
import BootstrapVue from 'bootstrap-vue';
import VueSweetalert2 from 'vue-sweetalert2';

import './common-components';

import App from './app.vue';
import router from './router';
import store from './store';

import HubClientInstaller from '@/components/api/hub-client/plugin/installer';
import { AppFiltersInstaller } from '@/filters';
import { testIdDirective } from '@/directives';
import {
  HubWebSocketInstaller,
  WindowSizeDetectInstaller,
  LocalesInstaller,
  GTMInstaller,
  ToggleInstaller,
  ClickOutsideInstaller,
} from '@/plugins';
// @ts-ignore
import VueFlatPickr from 'vue-flatpickr-component';
import ApexChart from 'vue-apexcharts';

Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(ApexChart);
Vue.use(VueSweetalert2);
Vue.use(VueFlatPickr);
Vue.use(AppFiltersInstaller);
Vue.use(ToggleInstaller);
Vue.use(ClickOutsideInstaller);
Vue.use(HubClientInstaller, { store });
Vue.use(LocalesInstaller, { locale: 'pt-br' });
Vue.use(GTMInstaller);
Vue.use(HubWebSocketInstaller);
Vue.use(WindowSizeDetectInstaller);
Vue.directive('test-id', testIdDirective);

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');
