import { Module } from 'vuex';

import { StoreState } from './types';
import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: StoreState = {
  store: null,
};

const namespaced = true;

export const storeModule: Module<StoreState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
