import Vue from 'vue';
import { GetterTree } from 'vuex';

import { ShopeeAttribute } from '@/components/marketplace/shopee';
import { AttributesState, AttrIdentifier } from './types';
import { RootState } from '../../../../types';

export const getters: GetterTree<AttributesState, RootState> = {
  /**
   * Retorna os atributos condicionais.
   * */
  get: (state: AttributesState): ((key: AttrIdentifier) => Array<ShopeeAttribute>) => {
    return ({ type, id }: AttrIdentifier) => {
      const key = `${type}_${id}`;
      if (!(key in state.attributes)) {
        Vue.set(state.attributes, key, []);
      }

      return state.attributes[key];
    };
  },
};

export default getters;
