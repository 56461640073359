import { Module } from 'vuex';

import { MarketplacesState } from './types';
import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MarketplacesState = {
  marketplaces: [],
  isLoading: true,
  configsCurrentMarketplace: null,
};

const namespaced = true;

export const marketplacesModule: Module<MarketplacesState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
