import axios, { AxiosRequestConfig, CancelTokenSource } from 'axios';
import { RequestInterceptor, Client } from '../../contracts';

export class Cancelable implements RequestInterceptor {
  private static cancelTokenSource: CancelTokenSource | null = null;

  static cancel(reason?: string) {
    if (!this.cancelTokenSource) {
      return;
    }

    console.log('canceling', reason);
    this.cancelTokenSource.cancel(reason);
    this.cancelTokenSource = null;
  }

  static getCancelToken(): CancelTokenSource {
    if (!this.cancelTokenSource) {
      this.cancelTokenSource = axios.CancelToken.source();
    }

    return this.cancelTokenSource;
  }

  /**
   * Anexa o token de cancelamento na requisição (caso ela não possua um).
   *
   * {@inheritDoc}
   * */
  handle(client: Client, config: AxiosRequestConfig): AxiosRequestConfig {
    if (!config.cancelToken) {
      config.cancelToken = Cancelable.getCancelToken().token;
    }

    return config;
  }
}

export default Cancelable;
