export enum GetterAccount {
  /**
   * Retorna uma lista de contas deve possuir alerta de precificação
   */
  HAS_PRICING_ALERT = 'HAS_PRICING_ALERT',
}

export enum MutationAccount {
  /**
   * Define se a listagem de contas deve exibir alerta de precificação
   *
   * @param hasAlert - indica se deve exibir o alerta
   */
  SET_PRICING_ALERT = 'SET_PRICING_ALERT',
}
