import { RootState } from '@/store/types';
import { PublishedOffersListState } from './types';
import { Module } from 'vuex';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const emptyFiltersPublishedOffers = {
  title: '',
  store: '',
  code: '',
  status: 'all',
  account: 'all',
  initial_price: '',
  final_price: '',
  stock: 'all',
};

/**
 * Estado vuex do módulo de ofertas publicadas pertencentes ao new-flow.
 */
export const state: PublishedOffersListState = {
  list: [],
  isLoading: false,
  pricing: {
    toEdit: null,
    idsOffer: null,
  },
  reloadList: false,
};

const namespaced = true;

export const ListOffersModule: Module<PublishedOffersListState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
