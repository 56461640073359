import cloneDeep from 'lodash/cloneDeep';
import camelCase from 'lodash/camelCase';

export type RawAttributes = {
  [key: string]: any;
};

// @TODO estudar mapeamento de atributos da entidade na tipagem.
// valor retornado está com 'any', seria bom retornar o tipo, mas usar T[K] gera vários problemas.
// no entanto, saber quais são os atributos (propriedades) é melhor do que nada.
export type Attributes<T extends Entity<T>> = {
  [K in keyof T]?: K extends PropertyKey ? any : never;
};

export default abstract class Entity<T> {
  [key: string]: any;

  /**
   * Cria uma nova instância da entidade a partir dos atributos fornecidos.
   *
   * @return T
   * */
  static from<T extends Entity<T>>(this: new () => T, attributes: Attributes<T> = {}): T {
    const instance = new this();

    instance.fill(attributes);
    return instance;
  }

  /**
   * Preenche a entidade com os atributos fornecidos.
   *
   * @param {RawAttributes} attributes
   * @return {void}
   * */
  fill(attributes: RawAttributes): void {
    if (!attributes) {
      return;
    }

    if (typeof attributes.toRawAttributes === 'function') {
      attributes = attributes.toRawAttributes();
    }

    Object.keys(attributes).forEach((key) => {
      const method = camelCase(`set_${key}_attribute`);
      if (typeof this[method] === 'function') {
        this[method](attributes[key]);
        return;
      }
      this[key] = attributes[key];
    });
  }

  /**
   * Retorna o valores dos atributos fornecidos.
   *
   * @return {RawAttributes}
   * */
  only(...keys: Array<string>): RawAttributes {
    const attributes: RawAttributes = {};
    keys.forEach((key: string) => {
      attributes[key] = this[key];
    });

    return attributes;
  }

  /**
   * Retorna o valores primitivos dos atributos fornecidos.
   *
   * @return {RawAttributes}
   * */
  toRawAttributes() {
    const attributes: RawAttributes = {};

    Object.keys(this).forEach((key: string) => {
      let value = this[key];
      if (value instanceof Entity) {
        value = value.toRawAttributes();
      }
      if (key.charAt(0) === '_') {
        key = key.slice(1);
      }
      attributes[key] = value;
    });

    return attributes;
  }

  /**
   * Retorna uma copia da entidade
   *
   * @return {T extends Entity<T>}
   */
  clone(overwrite: RawAttributes = {}): Entity<T> {
    const instance = cloneDeep(this);
    const attributes = this.toRawAttributes();

    instance.fill(cloneDeep({ ...attributes, ...overwrite }));

    return instance;
  }

  /**
   * Determina se todos os atributos fornecidos existem na entidade.
   *
   * @param {Array<string>} keys
   * @return {boolean}
   */
  hasAttributes(...keys: Array<string>): boolean {
    return !Object.keys(this).some((key: string) => !keys.includes(key));
  }
}
