import { GetterTree } from 'vuex';

import { Account, Marketplace, OfferImport } from '@/entities';
import { WizardState } from './types';
import { RootState } from '../../../types';
import Application from '@/entities/application';

export const getters: GetterTree<WizardState, RootState> = {
  newAccount(state): Account | null {
    return state.newAccount;
  },

  offerImport(state): OfferImport | null {
    return state.offerImport;
  },

  marketplace(state): Marketplace | null {
    return state.marketplace;
  },

  marketplaceApplication(state): Application | null {
    if (!state.marketplace) {
      return null;
    }

    return state.marketplace.application;
  },
};

export default getters;
