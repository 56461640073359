import { PluginFunction } from 'vue';
import { HubClient } from '../client';

const HubClientInstaller: PluginFunction<any> = (vue, { store }) => {
  const client = new HubClient(store);
  vue.$apiClient = client;
  vue.prototype.$apiClient = client;
};

export default HubClientInstaller;
