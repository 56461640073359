import { MutationTree } from 'vuex';
import { QuantityOfListsState, ResponseHighlightsOffers, ResponseHighlightsSales } from './types';
import { MutationQuantityOfLists } from './enum-mutation';
import { PagesName } from '@/router/routes/pages-enum';

export const mutations: MutationTree<QuantityOfListsState> = {
  [MutationQuantityOfLists.SET_HIGHLIGHT_ACTIVE](state, value: number): void {
    state.highlightsOffers.active = value;
  },

  [MutationQuantityOfLists.SET_HIGHLIGHT_PAUSED](state, value: number): void {
    state.highlightsOffers.paused = value;
  },

  [MutationQuantityOfLists.SET_QUANTITY_OFFERS](state, data: ResponseHighlightsOffers): void {
    state.tabs[PagesName.OFFERS_IN_PROCESSING] = data.processing ?? 0;
    state.tabs[PagesName.OFFERS_AWAITING_CORRECTION] = data.with_errors ?? 0;
  },

  [MutationQuantityOfLists.SET_QUANTITY_SALES](state, data: ResponseHighlightsSales): void {
    state.tabs[PagesName.PENDING_SALES] = data.awaiting_offers_relations ?? 0;
  },

  [MutationQuantityOfLists.SET_LOADING](state, value: boolean): void {
    state.isLoading = value;
  },

  [MutationQuantityOfLists.SET_FORCE_SHOW_PENDING_SALES](state, value: boolean): void {
    state.forceShowPendingSalesTab = value;
  },

  [MutationQuantityOfLists.SET_LOADING_HIGHLIGHTS](state, value: boolean): void {
    state.highlightsOffers.isLoading = value;
  },
};
