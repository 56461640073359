import { GetterTree } from 'vuex';
import { RootState } from '../../types';
import { PricingRuleState, PricingStrategyAPI } from './types';
import { GetterPricingRule } from './enum';
import { PricingRuleDisplayConfig } from '@/components/pricing-rule/pricing-configs.enum';
import { PricingRule } from '@/entities';
import { PricingConfigSummary, StepsPricingRule } from '@/components/pricing-rule/type';
import { TypePricingRuleStrategySlug } from '@/entities/pricing-rule';

const getters: GetterTree<PricingRuleState, RootState> = {
  [GetterPricingRule.PRICING](state): PricingRule | null {
    return state.pricing;
  },

  [GetterPricingRule.DISPLAY_CONFIG](state): PricingRuleDisplayConfig {
    return state.displayConfig;
  },

  [GetterPricingRule.IS_DISABLED_BUTTON_NEXT](state): boolean {
    return state.statusButtonNext;
  },

  [GetterPricingRule.ADDITIONAL_MARGIN_TYPE](state) {
    return state.config.type;
  },

  [GetterPricingRule.ADDITIONAL_VALUE](state) {
    return state.config.value;
  },

  [GetterPricingRule.ADDITIONAL_BASE_PRICE](state) {
    return state.config.calc_base;
  },

  [GetterPricingRule.CONFIG](state) {
    return state.config;
  },

  [GetterPricingRule.IS_LOADING](state) {
    return state.isLoading;
  },

  [GetterPricingRule.LIST_STRATEGIES](state): PricingStrategyAPI[] {
    return state.strategies;
  },

  [GetterPricingRule.GET_SLUG_BY_STRATEGY_ID](state) {
    return (strategy_id: number): TypePricingRuleStrategySlug | null => {
      const strategy = state.strategies.find((strategy) => strategy.id === strategy_id);
      if (strategy) {
        return strategy.slug;
      }

      return null;
    };
  },

  [GetterPricingRule.STRATEGIES_BY_SLUG](state) {
    return state.strategyBySlug;
  },

  [GetterPricingRule.ACCOUNT_NAME](state): string | null {
    return state.accountName;
  },

  [GetterPricingRule.MARKETPLACE_NAME](state): string | null {
    return state.marketplaceName;
  },

  [GetterPricingRule.MARKETPLACE_ID](state): number | null {
    return state.marketplaceId;
  },

  [GetterPricingRule.STEP](state): StepsPricingRule {
    return state.step;
  },

  [GetterPricingRule.CONFIG_SUMMARY](state): PricingConfigSummary {
    return state.configSummary;
  },

  [GetterPricingRule.QUANTITY_TO_APPLY](state) {
    return state.quantityToApply;
  },

  [GetterPricingRule.TYPE_OF_VALUE_ADJUSTMENT](state) {
    return state.typeOfValueAdjustment;
  },
};

export default getters;
