import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FiltersModule } from './filters';
import { OfferRelationModule } from './offer-relation';
import { CreateOfferModule } from './old-create-offer';
import { AddOfferModule } from './create-offer';
import { HighlightsModule } from './highlights';

const namespaced = true;

export const offersModule: Module<any, RootState> = {
  namespaced,
  modules: {
    filters: FiltersModule,
    relations: OfferRelationModule,
    highlights: HighlightsModule,
    // Inclusão de anúncios no fluxo antigo
    creating: CreateOfferModule,
    // Inclusão de anúncios no fluxo novo
    add: AddOfferModule,
  },
};

export default offersModule;
