import { GetterTree } from 'vuex';

import { RootState } from '../../../types';
import { HighlightsState, HighlightsCountsType } from './types';

export const getters: GetterTree<HighlightsState, RootState> = {
  /**
   * Retorna os valores de cada highlight;
   *
   * @param {state}
   * @return {HighlightsType | null}
   * */
  getHighlight(state): HighlightsCountsType | null {
    return state.counts;
  },
};

export default getters;
