import { Account } from '@/entities';
import { MutationTree } from 'vuex';
import { AccountState } from './types';
import { MutationAccount } from './enum';

type AccountSetterPayload = {
  id: number;
  account: Account | null;
};

export const mutations: MutationTree<AccountState> = {
  /**
   * Salva uma conta pelo ID
   */
  account(state, payload: AccountSetterPayload): void {
    const { id, account } = payload;
    if (!account) {
      return;
    }

    state.accountById[id] = Account.from(account);

    const hasAccountInList = state.accounts.some((currentAccount) => currentAccount.id === id);
    if (!hasAccountInList) state.accounts.push(Account.from(account));
  },

  /**
   * Salva uma lista de contas.
   */
  accounts(state, accounts: Account[]): void {
    const activeAccounts: Record<number, Account> = {};

    if (!accounts.length) {
      state.accounts = [];
      return;
    }

    for (const account of accounts) {
      activeAccounts[account.id] = Account.from(account);
    }
    state.accountById = activeAccounts;
    state.accounts = accounts.map((account) => Account.from(account));
  },

  /**
   * Define a data de expiração da integração com a Shopee.
   *
   * @param expirationDate - data de expiração da integração com a Shopee.
   */
  setExpirationDateShopee(state, expirationDate: string): void {
    state.expirationDateShopee = expirationDate;
  },

  [MutationAccount.SET_PRICING_ALERT](state, hasAlert: boolean): void {
    state.hasPricingAlert = hasAlert;
  },
};

export default mutations;
