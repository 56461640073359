import { GetterTree } from 'vuex';

import { NotificationBvToast, Theme, UiSettingsState } from './types';
import { RootState } from '../../types';
import { GetterUiSettings } from './enum';
import { PricingRule } from '@/entities';

export const getters: GetterTree<UiSettingsState, RootState> = {
  /**
   * Retorna o tema da loja.
   * */
  theme(state): Theme | null {
    return state.theme;
  },

  /**
   * Retorna a notificação a ser exibida.
   */
  notification(state): NotificationBvToast | null {
    return state.notification;
  },

  [GetterUiSettings.IS_COMPATIBLE_WITH_NEW_PRICING_RULE](state): boolean {
    return state.isCompatibleWithNewPricingRule;
  },

  [GetterUiSettings.ACCOUNT_PRICING_RULE](state): null | PricingRule {
    return state.accountPricingRule;
  },
};

export default getters;
