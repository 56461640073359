import Vue from 'vue';
import { MutationTree } from 'vuex';

import { AttributesState, AddAttrPayload, RemoveAttrPayload } from './types';

export const mutations: MutationTree<AttributesState> = {
  /**
   * Define os atributos condicionais.
   * */
  setAttributes(state, { type, id, attributes }: AddAttrPayload) {
    const key = `${type}_${id}`;
    if (!(key in state.attributes)) {
      Vue.set(state.attributes, key, attributes);
      return;
    }

    state.attributes[key] = attributes;
  },

  addAttributes(state, { type, id, attributes }: AddAttrPayload) {
    const key = `${type}_${id}`;
    if (!(key in state.attributes)) {
      Vue.set(state.attributes, key, attributes);
      return;
    }

    attributes = attributes.filter((attr) => {
      return !state.attributes[key].some(
        (existingAttr) => existingAttr.attribute_id === attr.attribute_id
      );
    });

    Vue.set(state.attributes, key, [...state.attributes[key], ...attributes]);
  },

  removeAttributes(state, { type, id, attrIds }: RemoveAttrPayload) {
    const key = `${type}_${id}`;
    if (!(key in state.attributes) || !Array.isArray(attrIds)) {
      return;
    }

    const attributes = state.attributes[key].filter((attr) => {
      return !attrIds.includes(attr.attribute_id);
    });

    Vue.set(state.attributes, key, attributes);
  },
};

export default mutations;
