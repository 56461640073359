import { Module } from 'vuex';

import { RootState } from '../../types';
import { OffersInProcessingState } from './types';

import { actions } from './actions';
import { mutations } from './mutations';
import { getters } from './getters';

export const state: OffersInProcessingState = {
  list: {
    paging: {
      from: 1,
      links: {},
      number: 1,
      size: 25,
      to: 1,
      total: 0,
    },
    filters: {},
    content: [],
    loading: true,
  },
};

const namespaced = true;

export const offersInProcessingModule: Module<OffersInProcessingState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
  actions,
};
