import { MutationTree } from 'vuex';
import { FilterByRouteState, FilterURL, ParamsSetFilter, PayloadSetFilterByKey } from './types';
import { MutationFilterByRoute } from './enum-mutation';
import { emptyFiltersPublishedOffers } from '../published-offers/list';
import { PagesName } from '@/router/routes/pages-enum';
import { emptyFiltersPendingSales } from '../offers-pending-sales';
import { emptyFiltersWaitingCorrection } from '../offers-waiting-correction/list';

export const mutations: MutationTree<FilterByRouteState> = {
  [MutationFilterByRoute.SAVE_FILTER](state, params: ParamsSetFilter) {
    state.route[params.route] = Object.assign({}, params.filters);
  },

  [MutationFilterByRoute.SAVE_FILTER_BY_KEY](state, param: PayloadSetFilterByKey) {
    Object.defineProperty(state.route[param.route], param.key, {
      value: param.value,
      writable: true,
      configurable: true,
    });
  },

  [MutationFilterByRoute.CLEAR_FILTERS](state) {
    state.route[PagesName.PUBLISHED_OFFERS] = { ...emptyFiltersPublishedOffers };
    state.route[PagesName.PENDING_SALES] = { ...emptyFiltersPendingSales };
    state.route[PagesName.WAITING_CORRECTION] = { ...emptyFiltersWaitingCorrection };
  },
};
