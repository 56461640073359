import { Module } from 'vuex';
import { Storage } from '@/components/storage';

import { UiSettingsState } from './types';
import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: UiSettingsState = {
  theme: Storage.get('theme', 'light'),
  notification: null,
  isCompatibleWithNewPricingRule: false,
  accountPricingRule: null,
};

const namespaced = true;

export const uiSettingsModule: Module<UiSettingsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
