export enum GetterHighlightsWaitingCorrection {
  /**
   * Total de anuncios sem relacionamento.
   */
  UNRELATED = 'UNRELATED',

  /**
   * Total de anúncios com falha na criação.
   */
  FAILED = 'FAILED',

  /**
   * Total de anúncios com infrações no canal de venda
   */
  WITH_ISSUES = 'WITH_ISSUES',

  /**
   * Retorna o status de carregamento dos destaques de anúncios aguardando correção.
   */
  IS_LOADING = 'IS_LOADING',
}
