import { GetterTree } from 'vuex';

import { RootState } from '../../types';
import {
  ApiCategoryRoot,
  CategoriesState,
  CategoryByRef,
  IdentifyPathCategory,
  LinkedState,
  PlatformCategory,
} from './types';
import { Getters } from './enum';
import Category from '@/entities/category';
import { Paging } from '@tray-libs/design-system-components/src/components/ui/pagination';
import { gettersAttributes } from './getters-attributes';

export const getters: GetterTree<CategoriesState, RootState> = {
  [Getters.ROOT_OF_MARKETPLACE](state): Category[] {
    return state.categories.marketplace.root;
  },

  [Getters.BY_REF_OF_MARKETPLACE](state): Record<number, CategoryByRef> {
    return state.categories.marketplace.byRef;
  },

  [Getters.SELECTED_CATEGORY_MARKETPLACE_IN_BINDING](
    state
  ): CategoryByRef | ApiCategoryRoot | null {
    return state.toBinding.marketplace.selectedCategory;
  },

  [Getters.CATEGORIES_MARKETPLACE_IN_BINDING](state): CategoryByRef[] | ApiCategoryRoot[] {
    return state.toBinding.marketplace.categories;
  },

  [Getters.PATH_CATEGORIES_MARKETPLACE_IN_BINDING](state): IdentifyPathCategory[] {
    if (state.toBinding.linked.categories.marketplace_category?.path_from_root) {
      return state.toBinding.linked.categories.marketplace_category.path_from_root;
    }

    return state.toBinding.marketplace.path;
  },

  [Getters.CATEGORY_PLATFORM_IN_BINDING](state): PlatformCategory {
    return state.toBinding.platform.category;
  },

  [Getters.ISLOADING_CATEGORIES_MARKETPLACE_IN_BINDING](state): boolean {
    return state.toBinding.isLoading;
  },

  [Getters.LINKED_CATEGORY_ATTRIBUTE_TO_BINDING](state): LinkedState {
    return state.toBinding.linked;
  },

  [Getters.CATEGORIES_LIST](state): PlatformCategory[] {
    return state.categories.list.content;
  },

  [Getters.CATEGORIES_LIST_PAGINATE](state): Paging {
    return state.categories.list.paging;
  },

  [Getters.ISLOADING_CATEGORIES_LIST](state): boolean {
    return state.categories.list.loading;
  },

  ...gettersAttributes,

  [Getters.SELECTED_CATEGORIES_TO_OFFERS](state): Array<Category> {
    return state.selectedCategoriesToOffers;
  },
};

export default getters;
