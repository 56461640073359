import { Module } from 'vuex';
import { Storage } from '@/components/storage';
import { RootState } from '@/store/types';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';
import {
  GenericFilter,
  SimpleFilterEqCondition,
} from '@tray-libs/design-system-components/src/components/ui/filter/models';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const ApiProductAttributes = {
  category_name: '[fullpath]',
};

const state: FilterState = {
  /**
   * Contexto de lista de categorias.
   */
  context: 'categories-list',

  /**
   * Ordenação da lista de categorias.
   * */
  sort: Storage.get('categories-sorting') || '-id',

  /**
   * Número de itens por paginação.
   * */
  pageSize: +Storage.get('categories-paging') || 25,

  /**
   * Número da página.
   * */
  pageNumber: 1,

  /**
   * Lista de possíveis filtros da lista de contas.
   *
   * */
  instances: {
    category_name: new GenericFilter({
      label: 'Nome da Categoria',
      isVisible: false,
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.category_name,
        value: '',
        isPartialSearch: true,
      }),
    }),
  },
};

const namespaced = true;

export const FiltersModule: Module<FilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
