import { MutationTree } from 'vuex';
import { OrderState } from './types';

export const mutations: MutationTree<OrderState> = {
  /**
   * Define a quantidade de pedidos pendentes
   *
   * @param {state}
   * @param {number | null} payload
   *
   * */
  setQuantityPendingOrders(state, payload: number | null) {
    state.quantityPendingOrders = payload;
  },
};

export default mutations;
