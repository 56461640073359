import { MutationTree } from 'vuex';
import { HighlightsState, HighlightsCountsType } from './types';

export const mutations: MutationTree<HighlightsState> = {
  /**
   * Atualiza os valores de highlight;
   *
   * @param {state}
   * @param {HighlightsCountsType} payload
   *
   * */
  setHighlightsCounts(state, payload: HighlightsCountsType) {
    state.counts = payload;
  },
};

export default mutations;
