import { MutationTree } from 'vuex';
import { ShopeeReviewState } from './types';

export const mutations: MutationTree<ShopeeReviewState> = {
  /**
   * Define o estado do componente de seleção de categorias.
   * */
  setCategoryPickerState(state, categoryPicker: ShopeeReviewState['categoryPicker']) {
    state.categoryPicker = categoryPicker;
  },
};

export default mutations;
