import config from '@/config';
import { AxiosError } from 'axios';
import { NotificationBvToast } from '../modules/ui-settings/types';

/**
 * Parâmetros para a função de tratamento de erro, que extende todos os params do
 * BV toast como opcional
 */
export interface HandlerErrorActionsParams extends Partial<NotificationBvToast> {
  where: string;
  onlyLog?: boolean;
  error: unknown;
}

/**
 * Função para tratamento de erros nas actions
 * Somente ao passar um error com status 1001 irá imprimir a message do erro no toast
 *
 * @param params.where - texto que identifica onde ocorreu o erro
 * @param params.error - erro recebido na action
 * @param params.onlyLog - quando true exibe apenas o log do erro no console sem exibir o
 * toast
 * @param {extends} - todos os parâmetros do ErrorBvToast como opcionais
 * @returns {ErrorBvToast} - objeto de erro para salvamento na vuex
 */
export const handlerErrorActions = (
  params: HandlerErrorActionsParams
): NotificationBvToast | null => {
  const { where, error, toaster, autoHideDelay, title, message, variant } = params;
  const isDevOrStaging = config('env') === 'development' || config('env') === 'staging';
  const nameToLog = isDevOrStaging ? `[${where}] ` : '';
  const errorMessage = (error as Error).message || error;

  console.error(`${nameToLog}${errorMessage}`);

  if (params.onlyLog) return null;

  const isFrontEndError = !(error as AxiosError).code;
  if (isFrontEndError) {
    return {
      title: 'Desculpe, ocorreu um erro no sistema',
      message:
        'Algo está errado em um lugar inesperado. Entre em contato com o suporte, para que eles possam determinar as próximas etapas.',
      autoHideDelay: autoHideDelay ?? 5000,
      variant: 'danger',
      toaster: toaster ?? 'b-toaster-top-right',
    };
  }

  if ((error as AxiosError).code === '401') {
    return {
      title: 'Desculpe, você não tem permissão para acessar esta página',
      message:
        'Tente efetuar o login novamente, caso persista entre em contato com o suporte para ajudá-lo.',
      autoHideDelay: autoHideDelay ?? 5000,
      variant: 'danger',
      toaster: toaster ?? 'b-toaster-top-right',
    };
  }

  const isErrorPersonalized = (error as AxiosError).code === '1001';
  if (isErrorPersonalized) {
    return {
      title: title ?? 'Opss... algo deu errado',
      message: message ?? (error as AxiosError).message,
      autoHideDelay: autoHideDelay ?? 5000,
      variant: variant ?? 'danger',
      toaster: toaster ?? 'b-toaster-top-right',
    };
  }

  const genericError = {
    title: 'Opss... algo deu errado',
    message:
      'Tente novamente mais tarde, caso persista entre em contato com o suporte para ajudá-lo.',
    autoHideDelay: autoHideDelay ?? 5000,
    variant: 'danger',
    toaster: toaster ?? 'b-toaster-top-right',
  };
  return genericError;
};
