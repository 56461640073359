import { DirectiveFunction } from 'vue';

type ClickOutside = {
  bind: DirectiveFunction;
  unbind: DirectiveFunction;
};

type HandleOutsideClick = (event: MouseEvent | TouchEvent) => void;

type RefElementValue = string;

export default function clickOutside(): ClickOutside {
  const clickedOutsideEvent = new Event('clicked-outside');

  let handleOutsideClick: HandleOutsideClick;

  function emitClickOutsideEvent(el: HTMLElement): void {
    el.dispatchEvent(clickedOutsideEvent);
  }

  return {
    bind(el, binding, vnode) {
      handleOutsideClick = (event) => {
        const clickedElement = event.target as HTMLElement;
        const exclusionElementList = binding.value as Array<RefElementValue>;

        let clickedInExcludedElement = false;

        if (exclusionElementList) {
          exclusionElementList.forEach((ref: string) => {
            if (!clickedInExcludedElement) {
              const excludedElement: any = vnode.context?.$refs[ref];
              clickedInExcludedElement = excludedElement.contains(clickedElement);
            }
          });
        }

        if (!el.contains(clickedElement) && !clickedInExcludedElement) {
          emitClickOutsideEvent(el);
        }
      };
      document.addEventListener('click', handleOutsideClick);
      document.addEventListener('touchstart', handleOutsideClick);
    },
    unbind() {
      document.removeEventListener('click', handleOutsideClick);
      document.removeEventListener('touchstart', handleOutsideClick);
    },
  };
}
