export enum GetterQuantityOfLists {
  /**
   * Retorna a quantidade de anúncios publicados ativos para os destaques da página
   */
  HIGHLIGHT_ACTIVE = 'HIGHLIGHT_ACTIVE',

  /**
   * Retorna a quantidade de anúncios publicados pausados para os destaques da página
   */
  HIGHLIGHT_PAUSED = 'HIGHLIGHT_PAUSED',

  /**
   * Retorna a quantidade de anúncios em processamento para as abas
   */
  OFFERS_IN_PROCESSING = 'OFFERS_IN_PROCESSING',

  /**
   * Retorna a quantidade de anúncios aguardando correção para as abas
   */
  OFFERS_AWAITING_CORRECTION = 'OFFERS_AWAITING_CORRECTION',

  /**
   * Retorna a quantidade de todas as abas
   */
  TABS = 'TABS',

  /**
   * Retorna a quantidade de vendas pendentes para as abas
   */
  PENDING_SALES = 'PENDING_SALES',

  /**
   * Retorna o status de carregamento dos destaques de anúncios publicados
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Retorna se deve forçar a exibição da aba de vendas pendentes
   */
  FORCE_SHOW_PENDING_SALES_TAB = 'FORCE_SHOW_PENDING_SALES_TAB',

  /**
   * Retorna se os destaques de anúncios publicados estão carregando
   */
  IS_LOADING_HIGHLIGHTS = 'IS_LOADING_HIGHLIGHTS',
}
