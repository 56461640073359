import { MutationTree } from 'vuex';
import { PagingByRouteState, ParamsSetPage, ParamsSetPaging, ParamsSetSize } from './types';
import { MutationPagingByRoute } from './enum-mutation';

export const mutations: MutationTree<PagingByRouteState> = {
  [MutationPagingByRoute.SAVE_PAGING](state, params: ParamsSetPaging) {
    state.route[params.route] = params.paging;
  },
  [MutationPagingByRoute.SET_PAGE](state, params: ParamsSetPage) {
    state.route[params.route].number = params.page;
  },
  [MutationPagingByRoute.SET_SIZE](state, params: ParamsSetSize) {
    state.route[params.route].size = params.size;
  },
};
