import { MutationTree } from 'vuex';
import { Store } from '@/entities';
import { StoreState } from './types';

export const mutations: MutationTree<StoreState> = {
  /**
   * Define a loja.
   * */
  setStore(state, attributes: object | null) {
    if (!attributes) {
      state.store = null;
      return;
    }
    state.store = Store.from(attributes);
  },
};

export default mutations;
