export enum Mutations {
  /**
   * Define o canal de venda selecionado para criação de ofertas.
   */
  SET_SALE_CHANNEL = 'SET_SALE_CHANNEL',

  /**
   * Define a listagem de categorias selecionadas para criação de ofertas.
   */
  SET_CATEGORIES_CREATE_OFFERS = 'SET_CATEGORIES_CREATE_OFFERS',
  /**
   * Adiciona uma categoria na listagem de categorias selecionadas para criação de ofertas.
   */
  ADD_CATEGORY_CREATE_OFFERS = 'ADD_CATEGORY_CREATE_OFFERS',
  /**
   * Remove uma categoria na listagem de categorias selecionadas para criação de ofertas.
   */
  REMOVE_CATEGORY_CREATE_OFFERS = 'REMOVE_CATEGORY_CREATE_OFFERS',

  /**
   * Define a listagem de produtos selecionadas para criação de ofertas.
   */
  SET_PRODUCTS_CREATE_OFFERS = 'SET_PRODUCTS_CREATE_OFFERS',
  /**
   * Adiciona um produto na listagem de produtos selecionadas para criação de ofertas.
   */
  ADD_PRODUCT_CREATE_OFFERS = 'ADD_PRODUCT_CREATE_OFFERS',
  /**
   * Remove um produto na listagem de produtos selecionadas para criação de ofertas.
   */
  REMOVE_PRODUCT_CREATE_OFFERS = 'REMOVE_PRODUCT_CREATE_OFFERS',
  /**
   * Salva a precificação padrão da conta.
   */
  SET_PRECIFICATION_ACCOUNT_SELECTED = 'SET_PRECIFICATION_ACCOUNT_SELECTED',
  /**
   * Salva a precificação para referenciar a sidebar de precificação e salvar os valores alterados.
   */
  SET_PRECIFICATION_EDIT = 'SET_PRECIFICATION_EDIT',
  /**
   * Precificação configurada da sidebar.
   */
  SET_CONFIG_PRICING = 'SET_CONFIG_PRICING',

  /**
   * Resumo da precificação para montagem do do resumo da criação de ofertas.
   */
  SET_CONFIG_SUMMARY_CREATE_OFFER = 'SET_CONFIG_SUMMARY_CREATE_OFFER',

  /**
   * Erro ao obter a resposta da Api de precificação.
   */
  SET_ERROR_LOAD_PRICING = 'SET_ERROR_LOAD_PRICING',
}

export enum Getters {
  /**
   * Canal de venda selecionado para criação de ofertas.
   */
  SALE_CHANNEL = 'SALE_CHANNEL',

  /**
   * Lista de categorias selecionadas para criação de oferta.
   */
  CATEGORIES_CREATE_OFFERS = 'CATEGORIES_CREATE_OFFERS',

  /**
   * Lista de produtos selecionadas para criação de oferta.
   */
  PRODUCTS_CREATE_OFFERS = 'PRODUCTS_CREATE_OFFERS',
  /**
   * Precificação padrão da conta.
   */
  GET_PRECIFICATION_ACCOUNT_SELECTED = 'GET_PRECIFICATION_ACCOUNT_SELECTED',
  /**
   * Precificação configurada da sidebar.
   */
  GET_CONFIG_PRICING = 'GET_CONFIG_PRICING',
  /**
   * Retorna a precificação para edição.
   */
  GET_PRECIFICATION_EDIT = 'GET_PRECIFICATION_EDIT',
  /**
   * Retorna o resumo das configurações apra cricação dos anuncios.
   */
  GET_CONFIG_SUMMARY_CREATE_OFFER = 'GET_CONFIG_SUMMARY_CREATE_OFFER',
  /**
   * Erro ao obter a resposta da Api de precificação.
   */
  GET_ERROR_LOAD_PRICING = 'GET_ERROR_LOAD_PRICING',
}
