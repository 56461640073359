import { ActionTree } from 'vuex';
import { OfferNewTitle, TagsError, WaitingCorrectionListState } from './types';
import { RootState } from '@/store/types';
import { ActionListWaitingCorrection } from './enum-action';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import { MutationListWaitingCorrection } from './enum-mutation';
import { MutationPagingByRoute } from '../../paging-by-route/enum-mutation';
import Vue from 'vue';
import { ApiResponse } from '@tray-libs/design-system-components/src/components/api/client/http/response';
import { ParamsSetPaging } from '../../paging-by-route/types';
import { ApiPaging } from '@/components/api/client/http';
import Offer from '@/entities/offer-new';
import { PagesName } from '@/router/routes/pages-enum';
import { MutationFilterByRoute } from '../../filter-by-route/enum-mutation';
import { emptyFiltersWaitingCorrection } from '.';

export const actions: ActionTree<WaitingCorrectionListState, RootState> = {
  async [ActionListWaitingCorrection.GET_LIST]({ commit }, params: URLSearchParams): Promise<any> {
    try {
      commit(MutationListWaitingCorrection.SET_LOADING, true);
      const apiUrl = '/offers';

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<Offer[], ApiResponse>(apiUrl, {
        params,
      });

      const data = response.getData().map((item: Offer) => {
        return Offer.from(item);
      });

      commit(MutationListWaitingCorrection.SAVE_LIST, data);

      const paramsPaging: ParamsSetPaging = {
        route: PagesName.WAITING_CORRECTION,
        paging: response.getPaging() as ApiPaging,
      };
      commit('paging/' + MutationPagingByRoute.SAVE_PAGING, paramsPaging, {
        root: true,
      });

      return response;
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        handlerErrorActions({ where: 'Action GET LIST Offers', error }),
        { root: true }
      );
    } finally {
      commit(MutationListWaitingCorrection.SET_LOADING, false);
    }
  },

  [ActionListWaitingCorrection.RESET_FILTERS]({ commit }) {
    commit(
      'filter/' + MutationFilterByRoute.SAVE_FILTER,
      {
        route: PagesName.WAITING_CORRECTION,
        filters: { ...emptyFiltersWaitingCorrection },
      },
      {
        root: true,
      }
    );
  },
  async [ActionListWaitingCorrection.GET_STATUS_OPTIONS]({ commit, rootGetters }) {
    const store = rootGetters['store/store'];

    await Vue.$apiClient
      .getHttpClient()
      .get(`/stores/${store.id}/tags?type=offer_error`)
      .then((response) => {
        const data = response.data.data as Array<TagsError>;
        const options = data.map((tag) => {
          return {
            label: tag.title,
            value: tag.slug,
          };
        });

        commit(MutationListWaitingCorrection.SET_STATUS_OPTIONS, options);
      });
  },

  async [ActionListWaitingCorrection.GET_OFFER_ERRORS]({ commit }, id: string) {
    await Vue.$apiClient
      .getHttpClient()
      .get(`/draft-offers/${id}/errors`)
      .then((response: any) => {
        commit(MutationListWaitingCorrection.SET_OFFER_ERRORS, response.getData());
      });
  },

  async [ActionListWaitingCorrection.GET_OFFER_WITH_ERROR]({ commit }, id: string) {
    await Vue.$apiClient
      .getHttpClient()
      .get(`offers/${id}`)
      .then((response: any) => {
        commit(MutationListWaitingCorrection.SET_OFFER_WITH_ERROR, response.getData());
      });
  },

  async [ActionListWaitingCorrection.GET_OFFER_VARIANTS]({ commit }, id: string) {
    await Vue.$apiClient
      .getHttpClient()
      .get(`offers/${id}`)
      .then((response: any) => {
        commit(MutationListWaitingCorrection.SET_OFFER_VARIANTS, response.getData().variants);
      });
  },

  async [ActionListWaitingCorrection.UPDATE_TITLE]({ commit }, newTitleData: OfferNewTitle) {
    const apiUrl = `draft-offers/${newTitleData.id}`;

    await Vue.$apiClient
      .getHttpClient()
      .put(apiUrl, { title: newTitleData.title })
      .then(() => {
        commit(MutationListWaitingCorrection.SET_OFFER_WITH_ERROR_HAS_ACTIONS, false);
      });
  },

  async [ActionListWaitingCorrection.REMOVE_OFFER]({ dispatch }, id: string) {
    return await Vue.$apiClient
      .getHttpClient()
      .delete(`/draft-offers/${id}`)
      .then(() => {
        return true;
      })
      .catch(() => false);
  },
};

export default actions;
