import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FiltersModule } from './filters';
import { OrderState } from './types';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

const namespaced = true;

export const state: OrderState = {
  quantityPendingOrders: null,
};

export const ordersModule: Module<any, RootState> = {
  state,
  getters,
  actions,
  mutations,
  namespaced,
  modules: {
    filters: FiltersModule,
  },
};

export default ordersModule;
