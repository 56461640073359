import { NavigationGuard } from 'vue-router/types/router';
import vuex from '@/store';
import { getAllAccounts } from './get-all-accounts';
import { Store } from '@/entities';
import { pricingNewFlow } from './pricing-new-flow';

export const MarketplaceLoader: NavigationGuard = async (to, from, next) => {
  await vuex.dispatch('marketplace/setActiveMarketplaceRef', to.params.marketplace);
  await vuex.dispatch('marketplace/loadActiveMarketplace');

  const store = vuex.getters['store/store'] as Store;

  if (!store.id) {
    return next();
  }

  const accounts = await getAllAccounts();
  if (!accounts || !accounts.length || to.name?.startsWith('wizard')) {
    return next();
  }

  return pricingNewFlow(to, from, next);
};
