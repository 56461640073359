import { RootState } from '@/store/types';
import { Module } from 'vuex';
import getters from './getters';
import { QuantityOfListsState } from './types';
import { mutations } from './mutations';
import { actions } from './actions';
import { PagesName } from '@/router/routes/pages-enum';

/**
 * Estado vuex do módulo de ofertas publicadas pertencentes ao new-flow.
 */
export const state: QuantityOfListsState = {
  highlightsOffers: {
    active: 0,
    paused: 0,
    isLoading: false,
  },
  tabs: {
    [PagesName.OFFERS_IN_PROCESSING]: 0,
    [PagesName.OFFERS_AWAITING_CORRECTION]: 0,
    [PagesName.PENDING_SALES]: 0,
  },
  forceShowPendingSalesTab: false,
  isLoading: false,
};

const namespaced = true;

export const QuantityOfListsModules: Module<QuantityOfListsState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
