import { DirectiveFunction } from 'vue/types/options';

export const testIdDirective: DirectiveFunction = (rootElement, binding) => {
  const qualifiedName = 'data-tray-tst';

  if (!binding.arg) {
    rootElement.setAttribute(qualifiedName, binding.value);
    return;
  }

  if (binding.arg === 'child' && binding.modifiers) {
    Object.keys(binding.modifiers).forEach((modifiers) => {
      Array.from(rootElement.children).forEach((childElement) => {
        if (modifiers === childElement.tagName.toLocaleLowerCase()) {
          childElement.setAttribute(qualifiedName, binding.value);
        }
      });
    });
  }
};
