import { GetterTree } from 'vuex';
import {
  AttributeValue,
  AttributeValuesLinkedLeftRight,
  BindingAttribute,
  CategoriesState,
  ErrorBvToast,
  UnitToComponent,
} from './types';
import { RootState } from '@/store/types';
import { Getters } from './enum';
import CategoryAttribute from '@/entities/category-attributes';
import { TypeAttributeBinding } from '@/components/category/type-attribute-binding.enum';

export const gettersAttributes: GetterTree<CategoriesState, RootState> = {
  [Getters.SELECTED_ATTRIBUTE_MARKETPLACE_IN_BINDING](state): CategoryAttribute | null {
    return state.toBinding.marketplace.attribute;
  },

  [Getters.ATTRIBUTES_MARKETPLACE_OPTIONAL](state): CategoryAttribute[] {
    return state.attributes.marketplace.filter((attribute) => {
      const isOptional = !attribute.isRequired();
      return isOptional;
    });
  },

  [Getters.ATTRIBUTES_MARKETPLACE_REQUIRED](state): CategoryAttribute[] {
    return state.attributes.marketplace.filter((attribute) => attribute.isRequired());
  },

  [Getters.ATTRIBUTES_VALUES_MARKETPLACE_IN_BINDING](state): AttributeValue[] {
    return state.toBinding.marketplace.attributesValues;
  },

  [Getters.ATTRIBUTES_PLATFORM_IN_BINDING](state): CategoryAttribute[] {
    return state.toBinding.platform.attributes;
  },

  [Getters.ATTRIBUTES_VALUES_PLATFORM_IN_BINDING](state): AttributeValue[] {
    return state.toBinding.platform.attributesValues;
  },

  [Getters.ATTRIBUTES_LINKED_IN_BINDING](state): BindingAttribute {
    return state.toBinding.linked.attributes;
  },

  [Getters.ATTRIBUTES_VALUES_LINKED_IN_BINDING](state) {
    return state.toBinding.linked.attributes.values;
  },

  [Getters.ATTRIBUTES_VALUES_LEFT_RIGHT_LINKED_IN_BINDING](state) {
    if (state.toBinding.linked.attributes.values) {
      const valuesLeftRight: AttributeValuesLinkedLeftRight[] =
        state.toBinding.linked.attributes.values?.map((value) => {
          let unitLabel = '';

          if (
            value.marketplace_attribute_value.unit &&
            value.marketplace_attribute_value.unit_symbol
          ) {
            unitLabel = value.marketplace_attribute_value.unit_symbol;
          } else if (value.marketplace_attribute_value.unit) {
            unitLabel = value.marketplace_attribute_value.unit;
          }

          const objectValues: AttributeValuesLinkedLeftRight = {
            left: {
              ref: value.platform_attribute_value.ref.toString(),
              value: value.platform_attribute_value.value,
              label: value.platform_attribute_value.label,
            },
            right: {
              ref: value.marketplace_attribute_value.ref ?? '',
              value: value.marketplace_attribute_value.value,
              label: value.marketplace_attribute_value.label,
              unit: {
                label: unitLabel,
                value: value.marketplace_attribute_value.unit ?? '',
              },
            },
          };

          if (
            state.toBinding.linked.attributes.marketplace_attribute?.type ===
            TypeAttributeBinding.NUMBER_UNIT
          ) {
            return objectValues;
          }

          delete objectValues.right?.unit;

          return objectValues;
        });

      return valuesLeftRight;
    }
    return null;
  },

  [Getters.UNITS_ATTRIBUTE_MARKETPLACE_IN_BINDING](state): UnitToComponent[] {
    if (state.toBinding.marketplace.attribute?.units) {
      const unitsToComponent = state.toBinding.marketplace.attribute?.units.map((unit) => {
        let unitLabel;
        if (unit.symbol) {
          unitLabel = unit.symbol;
        } else {
          unitLabel = unit.value;
        }

        return {
          label: unitLabel,
          value: unit.value,
        };
      });

      return unitsToComponent;
    }

    return [];
  },

  [Getters.ERROR_IN_BINDING](state): ErrorBvToast | null {
    return state.toBinding.error;
  },
};
