import Vue from 'vue';
import { endpoints } from './endpoints';
import { ApiResponse } from '@/components/api/client/http';
import { ApiCategoryRoot, CategoryByRef } from '@/store/modules/categories/types';
import { Account } from '@/entities';

export class CategoryLevelsFinder {
  /**
   * Identificador da conta ativa
   * Ainda não compatível com multi-contas por isso é o primeiro item da lista.
   */
  protected accountId: string | null = (
    Vue.$apiClient.getStore().getters['account/accounts'][0] as Account
  )?.id.toString();

  protected client = Vue.$apiClient.getHttpClient();

  /**
   * Busca todas as categorias do marketplace atual ativo
   *
   * @return {Promise<ApiCategoryRoot[]>} - lista de categorias do marketplace
   */
  async all(): Promise<ApiCategoryRoot[]> {
    if (!this.accountId) throw new Error('Account ID not found');

    const apiUrl = endpoints.getAll.replace(':accountId', this.accountId);

    return this.client.get<ApiCategoryRoot[], ApiResponse>(apiUrl).then((response) => {
      return response.getData();
    });
  }

  /**
   * Busca a categoria do marketplace por Ref
   *
   * @param categoryRef - Referência da categoria do marketplace a ser buscada
   * @return {Promise<CategoryByRef>} - a categoria do marketplace
   * */
  async findByRef(categoryRef: string): Promise<CategoryByRef> {
    if (!this.accountId) throw new Error('Account ID not found');

    const apiUrl = endpoints.getByRef
      .replace(':accountId', this.accountId)
      .replace(':categoryRef', categoryRef);

    return this.client.get<CategoryByRef, ApiResponse>(apiUrl).then((response) => {
      return response.getData();
    });
  }
}
