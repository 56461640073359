import { MutationTree } from 'vuex';

import { Account, Marketplace, OfferImport } from '@/entities';
import { WizardState, WizardConfig, WizardActiveStep } from './types';
import { Mutations } from './enum';

export const mutations: MutationTree<WizardState> = {
  /**
   * Define o marketplace fornecido.
   * */
  [Mutations.SET_NEW_ACCOUNT](state, account: Account) {
    state.newAccount = account;
  },

  /**
   * Atualiza o marketplace do wizard
   * */
  [Mutations.SET_OFFER_IMPORT](state, offerImport: OfferImport) {
    state.offerImport = offerImport;
  },

  /**
   * Atualiza o marketplace do wizard
   * */
  [Mutations.SET_MARKETPLACE](state, marketplace: Marketplace) {
    state.marketplace = marketplace;
  },

  /**
   * Atualiza as configurações contendo os passos do wizard
   * */
  [Mutations.SET_WIZARD_CONFIG](state, wizardConfig: WizardConfig) {
    state.wizardConfig = wizardConfig;
  },

  /**
   * Atualiza a etapa ativa do wizard
   * */
  [Mutations.SET_ACTIVE_STEP](state, step: WizardActiveStep) {
    state.activeStep = step;
  },
};

export default mutations;
