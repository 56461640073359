import { CanSticky } from './contracts/can-sticky';
import { StickerManagerConstructor, StickerManagerInterface } from './contracts/sticker-manager';

export const StickerManager: StickerManagerConstructor = class implements StickerManagerInterface {
  /**
   * Elemento que será fixado.
   *
   * @var {CanSticky} sticklable
   * */
  protected stickable: CanSticky;

  /**
   * Função que anexa/remove o comportamento de fixo no elemento fornecido.
   *
   * @var {any} scrollEvent$
   * */
  protected scrollEvent$: any = null;

  /**
   * StickerManager's constructor.
   *
   * @param {CanSticky} stickable
   * */
  constructor(stickable: CanSticky) {
    this.stickable = stickable;
  }

  /**
   * @inheritDoc
   * */
  start(): void {
    const scrollableEl = this.stickable.getScrollableContent();
    const stickableEl = this.stickable.getElement();
    const scrollTopMargin = this.stickable.getTopDistance();

    this.scrollEvent$ = () => {
      const currentScrollTop = scrollableEl.scrollTop;
      if (currentScrollTop >= scrollTopMargin) {
        stickableEl.classList.add('sticky');
        return;
      }
      stickableEl.classList.remove('sticky');
    };

    scrollableEl.addEventListener('scroll', this.scrollEvent$);
  }

  /**
   * @inheritDoc
   * */
  destroy(): void {
    if (!this.scrollEvent$) {
      return;
    }

    this.stickable.getScrollableContent().removeEventListener('scroll', this.scrollEvent$);
    this.scrollEvent$ = null;
  }
};
