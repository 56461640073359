export enum GetterMarketplaces {
  /**
   * Retorna se a lista de anúncios está carregando
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Lista de marketplaces.
   */
  LIST = 'LIST',

  /**
   * Verifica se atingiu o limite de contas possíveis
   */
  REACHED_ACCOUNT_LIMIT = 'REACHED_ACCOUNT_LIMIT',
}
