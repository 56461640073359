import { GetterTree } from 'vuex';
import { ConfigMarketplace, MarketplacesState } from './types';
import { RootState } from '@/store/types';
import { GetterMarketplaces } from './enum-getter';

export const getters: GetterTree<MarketplacesState, RootState> = {
  [GetterMarketplaces.LIST]: (state): ConfigMarketplace[] => {
    return state.marketplaces;
  },

  [GetterMarketplaces.IS_LOADING]: (state): boolean => {
    return state.isLoading;
  },

  [GetterMarketplaces.REACHED_ACCOUNT_LIMIT]: (state): boolean => {
    if (!state.configsCurrentMarketplace) {
      return false;
    }

    if (state.configsCurrentMarketplace.accounts_limit === null) {
      return state.configsCurrentMarketplace.accounts_quantity === 1;
    }

    return (
      state.configsCurrentMarketplace.accounts_quantity ===
      state.configsCurrentMarketplace.accounts_limit
    );
  },
};

export default getters;
