import { Module } from 'vuex';

import { RootState } from '@/store/types';
import { CreateOfferState } from './types';
import mutations from './mutations';
import getters from './getters';
import { TypePricingRuleStrategy, TypePricingScope } from '@/entities/pricing-rule';

export const state: CreateOfferState = {
  saleChannel: null,
  selectedCategories: [],
  selectedProducts: [],
  pricing: null,
  pricingEdit: null,
  configPricingEdit: null,
  configPricingSummary: {
    scope_type: TypePricingScope.ACCOUNT,
    strategy_slug: TypePricingRuleStrategy.DISABLED,
    configToSave: null,
  },
  errorLoadPricing: false,
};

const namespaced = true;

export const AddOfferModule: Module<CreateOfferState, RootState> = {
  namespaced,
  state,
  getters,
  mutations,
};
