<script lang="ts">
import { Vue, Component, Watch, Prop } from 'vue-property-decorator';
import { Marketplace } from '@/entities';
import capitalize from 'lodash/capitalize';

type TitleBadge = 'new' | 'beta' | null;

interface MarketplacesBadges {
  [key: string]: TitleBadge;
}

@Component
export default class AppHubPageTitle extends Vue {
  @Prop() title?: string;

  /**
   * O marketplace ativo
   *
   * @var {Marketplace} activeMarketplace
   */
  get activeMarketplace(): Marketplace {
    return this.$store.getters['marketplace/activeMarketplace'];
  }

  /**
   * Retorna a badge do título da página.
   *
   * @var {TitleBadge} titleBadge
   */
  get titleBadge(): TitleBadge {
    const marketplaceName = this.activeMarketplace.name.toLowerCase();

    if (marketplaceName in this.marketplacesBadges) {
      return this.marketplacesBadges[marketplaceName];
    }
    return null;
  }

  /**
   * Retorna o componente da badge correta.
   *
   * @var {Promise<any>}
   * */
  get component() {
    if (!this.titleBadge) {
      return null;
    }

    return () =>
      import(
        `@tray-libs/design-system-components/src/components/ui/badge/${this.titleBadge}-badge/${this.titleBadge}-badge.vue`
      );
  }

  /**
   * Lista de marketplaces e badges que devem ser exibidos no título da página.
   *
   * @var {MarketplacesBadges} marketplacesBadges
   */
  marketplacesBadges: MarketplacesBadges = {
    shopee: null,
    shein: 'new',
    amazon: 'beta',
  };

  /**
   * Títulos da página
   *
   * @var {String}
   */
  titles: Array<string> = [];

  mounted() {
    this.setTitle();
  }

  /**
   * Observa por alterações da rota para atualizar o título
   *
   * @return {void}
   */
  @Watch('$route')
  onChange(): void {
    this.setTitle();
  }

  /**
   * Define o título da página
   *
   * @return {void}
   */
  setTitle(): void {
    this.titles.length = 0;
    this.titles.push(capitalize(this.activeMarketplace.name));

    if (this.$route.meta && this.$route.meta.title && !this.title) {
      this.titles.push(this.$route.meta.title);
    }

    if (this.title) {
      this.titles.push(this.title);
    }
  }

  /**
   * Define o título da página
   *
   * @return {boolean}
   */
  isTheLastTitle(index: number): boolean {
    if (index === undefined || !this.titles.length) {
      return true;
    }
    return index + 1 >= this.titles.length;
  }
}
</script>

<template>
  <div class="app-hub-page-title">
    <template v-if="!title && !!titles.length">
      <h2
        v-for="(title, index) in titles"
        class="app-hub-page-title__content"
        :class="[index === titles.length - 1 ? 'app-hub-page-title__content--last' : '']"
        v-bind:key="title"
      >
        {{ title }}
        <i
          v-if="!isTheLastTitle(index)"
          class="app-hub-page-title__content-icon mdi mdi-chevron-right mr-1"
        />
      </h2>
    </template>
    <h3 v-else class="app-hub-page-title__content">{{ title }}</h3>

    <component v-if="titleBadge" class="app-hub-page-title__badge" :is="component" />
  </div>
</template>

<style lang="scss">
@import 'hub-page-title';
</style>
