import Entity from './entity';
import { BroadcastingEntity } from './contracts/broadcasting-entity';

class AuthenticatedUser extends Entity<AuthenticatedUser> implements BroadcastingEntity {
  /**
   * Nome do utilizador.
   *
   * @var string
   * */
  readonly name: string = '';

  /**
   * Username do utilizador.
   *
   * @var string
   * */
  readonly username: string = '';

  /**
   * Endereço eletrónico do utilizador.
   *
   * @var string
   * */
  readonly email: string = '';

  get firstName(): string {
    return this.name.split(' ')[0];
  }

  /**
   * @inheritDoc
   * */
  getChannelPrefix(): string {
    return `user-${this.id}`;
  }
}

export default AuthenticatedUser;
