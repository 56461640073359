export enum MutationHighlightsWaitingCorrection {
  /**
   * Salva o total de anúncios não relacionados.
   */
  SET_UNRELATED = 'SET_UNRELATED',

  /**
   * Salva o total de anúncios com falha na criação.
   */
  SET_FAILED = 'SET_FAILED',

  /**
   * Salva o total de anúncios com infrações no canal de venda.
   */
  SET_WITH_ISSUES = 'SET_WITH_ISSUES',

  /**
   * Salva o status de carregamento dos destaques de anúncios publicados.
   */
  SET_LOADING = 'SET_LOADING',
}
