export enum MutationUiSettings {
  SET_COMPATIBILITY_WITH_NEW_PRICING_RULE = 'SET_COMPATIBILITY_WITH_NEW_PRICING_RULE',

  /**
   * Armazena a regra de preço da conta temporariamente para solucionar exibição na
   * listagem
   *
   * TODO: essa funcionalidade deve ser removida após total conclusão da migração de
   * regra de preço
   */
  SET_PRICING_ACCOUNT = 'SET_PRICING_ACCOUNT',
}

export enum GetterUiSettings {
  /**
   * Retorna se é compatível com a nova regra de precificação
   *
   * TODO: deve ser removida após total conclusão da migração de regra de preço
   */
  IS_COMPATIBLE_WITH_NEW_PRICING_RULE = 'IS_COMPATIBLE_WITH_NEW_PRICING_RULE',

  /**
   * Retorna a primeira regra de preço da conta
   *
   * TODO: deve ser removida após total conclusão da migração de regra de preço
   */
  ACCOUNT_PRICING_RULE = 'ACCOUNT_PRICING_RULE',
}
