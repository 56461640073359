import { VueConstructor } from 'vue';
import moment from 'moment';
import { Filter } from './filter';

export class DateFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Filtro para formatar data.
     *
     * @example {{ text | date }}
     * */
    vue.filter('date', function (value: string, format = 'DD/MM/YYYY') {
      if (!value) {
        return;
      }

      const date = moment(value);

      if (date.isValid()) {
        return date.format(format);
      }

      return value;
    });

    /**
     * Filtro para formatar data & hora.
     *
     * @example {{ text | datetime }}
     * */
    vue.filter('datetime', function (value: string, format = 'DD/MM/YYYY - HH:mm:ss') {
      if (!value) {
        return;
      }

      const date = moment(value);

      if (date.isValid()) {
        return date.format(format);
      }

      return value;
    });

    /**
     * Filtro para formatar data & hora do novo fluxo
     *
     * @example {{ text | datetime_new_flow }}
     * */
    vue.filter('datetime_new_flow', function (value: string, format = 'DD/MM/YY - HH[h]mm') {
      if (!value) {
        return;
      }

      const date = moment(value);

      if (date.isValid()) {
        return date.format(format);
      }

      return value;
    });
  }
}

export default DateFilter;
