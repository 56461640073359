import { NavigationGuard } from 'vue-router/types/router';
import store from '@/store';

export const ThemeSetter: NavigationGuard = async (to, from, next) => {
  const theme = to.query.active_theme as string;
  if (theme) {
    store.dispatch('uiSettings/setTheme', theme);
  }
  next();
};
