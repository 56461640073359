import { VueConstructor } from 'vue';
import { Filter } from './filter';

export class PhoneFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para celular/telefone.
     *
     * @example {{ text | phone }}
     * */
    vue.filter('phone', function (value: string) {
      if (!value) {
        return;
      }

      if (value.length >= 13) {
        return value.replace(/(\d{2})(\d{2})(\d{4,5})(\d{4})/, '+$1 ($2) $3-$4');
      }

      if (value.length >= 10) {
        return value.replace(/(\d{2})(\d{4,5})(\d{4})/, '($1) $2-$3');
      }

      if (value.length >= 8) {
        return value.replace(/(\d{4,5})(\d{4})/, '$1-$2');
      }

      return value;
    });
  }
}

export default PhoneFilter;
