export enum MutationQuantityOfLists {
  /**
   * Salva o total de anúncios ativos.
   */
  SET_HIGHLIGHT_ACTIVE = 'SET_HIGHLIGHT_ACTIVE',

  /**
   * Salva o total de anúncios pausados.
   */
  SET_HIGHLIGHT_PAUSED = 'SET_HIGHLIGHT_PAUSED',

  /**
   * Salva a quantidade de anúncios para as abas.
   */
  SET_QUANTITY_OFFERS = 'SET_QUANTITY_OFFERS',

  /**
   * Salva a quantidade de vendas/pedidos para as abas.
   */
  SET_QUANTITY_SALES = 'SET_QUANTITY_SALES',

  /**
   * Salva o status de carregamento dos destaques de anúncios publicados.
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Força a exibição da aba de vendas pendentes.
   */
  SET_FORCE_SHOW_PENDING_SALES = 'SET_FORCE_SHOW_PENDING_SALES',

  /**
   * Salva o status de carregamento dos destaques de anúncios publicados.
   */
  SET_LOADING_HIGHLIGHTS = 'SET_LOADING_HIGHLIGHTS',
}
