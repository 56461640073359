import Entity from './entity';

export default class OrderLabelFile extends Entity<OrderLabelFile> {
  /**
   * ID do arquivo
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * ID do grupo pertencente
   *
   * @var {number} order_label_group_id
   * */
  order_label_group_id!: number;

  /**
   * Título do arquivo
   *
   * @var {string} title
   * */
  title!: string;

  /**
   * Tipo do arquivo
   *
   * @var {string} file_type
   * */
  file_type!: string;

  /**
   * Link para download do arquivo
   *
   * @var {string} file_url
   * */
  file_url!: string;

  /**
   * Determina se o arquivo é do tipo PDF.
   * */
  isPDF(): boolean {
    return this.file_type.toLowerCase() === 'pdf';
  }

  /**
   * Determina se o arquivo é do tipo Zebra.
   * */
  isZebra(): boolean {
    return this.file_type.toLowerCase() === 'zpl';
  }
}
