export enum MutationFilterByRoute {
  /**
   * Salva o filtro atual da lista por rota.
   *
   * @param payload.route - Rota atual
   * @param payload.filter - filtro atual
   */
  SAVE_FILTER = 'SAVE_FILTER',

  /**
   * Salva o filtro atual dos anúncios publicados.
   *
   * @param payload.route - rota atual
   * @param payload.key - nome da chave do filtro a atualizar
   * @param payload.value - valor do filtro a atualizar
   */
  SAVE_FILTER_BY_KEY = 'SAVE_FILTER_BY_KEY',

  /**
   * Limpa todos os filtros de todas as rotas.
   */
  CLEAR_FILTERS = 'CLEAR_FILTERS',
}
