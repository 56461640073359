import { Module } from 'vuex';
import { RootState } from '@/store/types';
import { FilterState } from '@tray-libs/design-system-components/src/components/ui/filter/vuex/types';
import { Storage } from '@/components/storage';
import {
  GenericFilter,
  SimpleFilterEqCondition,
} from '@tray-libs/design-system-components/src/components/ui/filter';

import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

const ApiProductAttributes = {
  product: '[product]',
  product_title: '[title]',
};

export const state: FilterState = {
  /**
   * Contexto de lista de produtos.
   */
  context: 'offer-relation-product-list',

  /**
   * Ordenação da lista de produtos.
   * */
  sort: Storage.get('offer-relation-product-list-sorting') || '-id',

  /**
   * Determina se o filtro avançado está aberto.
   */
  isAdvancedFilterOpen: false,

  /**
   * Número de itens por paginação.
   * */
  pageSize: +Storage.get('offer-relation-product-list-paging') || 5,

  /**
   * Número da página.
   * */
  pageNumber: 1,

  /**
   * Lista de possíveis filtros da lista de produtos.
   * */
  instances: {
    product: new GenericFilter({
      label: 'Código, Nome ou Referência',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.product,
        value: '',
      }),
    }),
    product_title: new GenericFilter({
      label: 'Nome do Produto',
      condition: SimpleFilterEqCondition.from({
        apiAttribute: ApiProductAttributes.product_title,
        isPartialSearch: true,
        value: '',
      }),
    }),
  },
};

const namespaced = true;

export const FiltersModule: Module<FilterState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
