import { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';
import { Store } from 'vuex';
import { Cancelable } from './http';
import {
  RequestInterceptor,
  ResponseInterceptor,
  ResponseErrorInterceptor,
  Client,
} from './contracts';

export type ClientOptions = {
  requestInterceptors?: Array<RequestInterceptor>;
  responseInterceptors?: Array<ResponseInterceptor>;
  responseErrorInterceptors?: Array<ResponseErrorInterceptor>;
  withResponse?: (response: AxiosResponse) => any;
};

export abstract class IClient implements Client {
  protected readonly httpClient: AxiosInstance;

  protected readonly store: Store<any>;

  protected options: ClientOptions = {};

  /**
   * Cria uma instância do cliente HTTP do Hub.
   * */
  constructor(store: Store<any>, options: ClientOptions = {}) {
    this.options = options;
    this.store = store;
    this.httpClient = this.createDefaultHttpClient();
    this.appendRequestInterceptors();
    this.appendResponseInterceptors();
  }

  /**
   * {@inheritDoc}
   * */
  getHttpClient(): AxiosInstance {
    return this.httpClient;
  }

  /**
   * {@inheritDoc}
   * */
  getStore(): Store<any> {
    return this.store;
  }

  /**
   * {@inheritDoc}
   * */
  cancelPendingRequests(reason = ''): void {
    Cancelable.cancel(reason);
  }

  /**
   * Cria a instância padrão do client.
   *
   * @return {AxiosInstance}
   * */
  protected abstract createDefaultHttpClient(): AxiosInstance;

  protected appendRequestInterceptors(): void {
    const { requestInterceptors } = this.options;
    if (requestInterceptors) {
      this.httpClient.interceptors.request.use((config: AxiosRequestConfig) => {
        requestInterceptors.forEach((interceptor: RequestInterceptor) => {
          config = interceptor.handle(this, config) as AxiosRequestConfig;
        });
        return config;
      });
    }
  }

  protected appendResponseInterceptors(): void {
    this.httpClient.interceptors.response.use(
      (response: AxiosResponse) => {
        const { responseInterceptors } = this.options;
        if (responseInterceptors) {
          responseInterceptors.forEach((interceptor: ResponseInterceptor) => {
            response = interceptor.handle(this, response);
          });
        }

        const { withResponse } = this.options;
        if (withResponse) {
          return withResponse(response);
        }
        return response;
      },
      (error: AxiosError) => {
        const { responseErrorInterceptors } = this.options;
        if (responseErrorInterceptors) {
          responseErrorInterceptors.forEach((interceptor: ResponseErrorInterceptor) => {
            error = interceptor.handle(this, error);
          });
        }
        return Promise.reject(error);
      }
    );
  }
}

export default IClient;
