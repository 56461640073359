import axios, { CancelToken, CancelTokenSource } from 'axios';

export class CancelTokenHandler {
  /**
   * Token para cancelamento da requisição.
   *
   * @var {CancelTokenSource | null} cancelTokenSource
   * */
  protected cancelTokenSource: CancelTokenSource | null = null;

  /**
   * Retorna um source para cancelamento de requisições.
   *
   * @var {CancelTokenSource} source
   * */
  get source(): CancelTokenSource {
    if (!this.cancelTokenSource) {
      this.cancelTokenSource = axios.CancelToken.source();
    }

    return this.cancelTokenSource;
  }

  /**
   * Token para realizar o cancelamento das requisições do axios.
   *
   * @var {CancelToken} token
   * */
  get token(): CancelToken {
    return this.source.token;
  }

  /**
   * Cancela o token caso ele já exista e cria um novo.
   *
   * @var {CancelToken} token
   * */
  get freshToken(): CancelToken {
    this.cancel();
    return this.source.token;
  }

  /**
   * Cancela as requisições usando o token.
   *
   * @return {void}
   * */
  cancel(): void {
    if (this.cancelTokenSource) {
      this.cancelTokenSource.cancel('Operation canceled by the user.');
      this.complete();
    }
  }

  /**
   * Limpa o token de cancelamento.
   *
   * @return {void}
   * */
  complete(): void {
    this.cancelTokenSource = null;
  }
}
