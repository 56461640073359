import { AxiosError } from 'axios';
import config from '@/config';
import { ResponseErrorInterceptor, Client } from '../../contracts';

export class ErrorHandler implements ResponseErrorInterceptor {
  /**
   * Intercepta o erro da request.
   *
   * {@inheritDoc}
   * */
  handle(client: Client, error: AxiosError): AxiosError {
    if (config('env') !== 'development') {
      if (!error.request) {
        return error;
      }
    }
    return error;
  }
}

export default ErrorHandler;
