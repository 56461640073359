import { GetterTree } from 'vuex';
import { WaitingCorrectionListState } from './types';
import { RootState } from '@/store/types';
import { GetterListWaitingCorrection } from './enum-getter';
import Offer from '@/entities/offer-new';
import { Option } from '@/components/ui/multiple-select/multiple-select';

export const getters: GetterTree<WaitingCorrectionListState, RootState> = {
  [GetterListWaitingCorrection.LIST]: (state): Offer[] => state.list,
  [GetterListWaitingCorrection.IS_LOADING]: (state): boolean => {
    return state.isLoading;
  },
  [GetterListWaitingCorrection.OPTIONS_STATUS]: (state): Array<Option> => {
    return state.optionsStatus;
  },

  [GetterListWaitingCorrection.OFFER_WITH_ERROR](state) {
    return state.offerWithError;
  },
  [GetterListWaitingCorrection.OFFER_WITH_ERROR_HAS_ACTIONS](state) {
    return state.offerWithErrorHasActions;
  },
  [GetterListWaitingCorrection.OFFER_WITH_ERROR_CAN_SAVE](state) {
    return state.offerWithErrorCanSave;
  },

  [GetterListWaitingCorrection.OFFER_ERRORS](state) {
    return state.offerErrors;
  },
  [GetterListWaitingCorrection.OFFER_VARIANTS](state) {
    return state.offerVariants;
  },
  [GetterListWaitingCorrection.OFFER_NEW_TITLE](state) {
    return state.offerNewTitle;
  },
  [GetterListWaitingCorrection.OFFER_REMOVE_MODAL](state) {
    return state.offerRemoveModal;
  },
};

export default getters;
