import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import { PricingRuleState, PricingStrategyAPI } from './types';
import { ActionPricingRule } from './enum-actions';
import Vue from 'vue';
import { HubApiResponse } from '@/components/api/hub-client';
import { MutationPricingRule } from './enum';
import { PricingRule } from '@/entities';
import { MutationUiSettings } from '../ui-settings/enum';
import { MutationAccount } from '../account/enum';

const actions: ActionTree<PricingRuleState, RootState> = {
  async [ActionPricingRule.GET_ALL_STRATEGIES]({ commit, rootGetters }): Promise<void> {
    try {
      const storeId = rootGetters['store/store'].id;

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<PricingStrategyAPI, HubApiResponse>(
        `/stores/${storeId}/pricing-strategies`
      );

      const data = response.getData();

      commit(MutationPricingRule.SAVE_STRATEGIES, data);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action GET All Pricing Strategies', error },
        { root: true }
      );
    }
  },

  async [ActionPricingRule.CREATE_NEW_STANDARD]({
    commit,
    rootGetters,
  }): Promise<PricingRule | null> {
    try {
      const storeId = rootGetters['store/store'].id;
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;
      const accountId = rootGetters['account/accounts'][0].id;

      const payloadNewPricingStandard = {
        marketplace_id: marketplaceId,
        strategy_id: 1,
        title: 'Preço igual da loja',
        scope_type: 'account',
        scope_ids: [accountId],
      };

      const client = Vue.$apiClient.getHttpClient();
      const newPricing = await client.post<PricingRule, HubApiResponse>(
        `/stores/${storeId}/pricing`,
        payloadNewPricingStandard
      );

      return PricingRule.from(newPricing.getData());
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action Create New Standard Pricing Rule', error },
        { root: true }
      );

      return null;
    }
  },

  async [ActionPricingRule.GET_CURRENT_ACCOUNT_PRICING_RULES_BY_ACCOUNT_ID](
    { commit, rootGetters },
    accountId: string
  ): Promise<PricingRule[] | null> {
    try {
      const storeId = rootGetters['store/store'].id;
      const marketplaceId = rootGetters['marketplace/activeMarketplace'].id;

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<PricingRule, HubApiResponse>(
        `/stores/${storeId}/pricing?filter[scope][type]=account&filter[scope][ids]=${accountId}&filter[marketplace]=${marketplaceId}`
      );

      const data = response.getData();

      const hasPricingOfNewFlow =
        data?.length > 0 &&
        data?.some((pricing: PricingRule) => pricing.scope.scope_type === 'account');
      commit(
        'uiSettings/' + MutationUiSettings.SET_COMPATIBILITY_WITH_NEW_PRICING_RULE,
        hasPricingOfNewFlow,
        {
          root: true,
        }
      );

      if (!data || !data.length) {
        return null;
      }

      commit('account/' + MutationAccount.SET_PRICING_ALERT, data.length > 1, { root: true });

      return data.map((pricing: PricingRule) => PricingRule.from(pricing));
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action GET Current Pricing Account', error },
        { root: true }
      );

      return null;
    }
  },
};

export default actions;
