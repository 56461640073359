import { GetterTree } from 'vuex';

import AuthenticatedUser from '@/entities/authenticated-user';
import { AuthState, Platform } from './types';
import { RootState } from '../../types';
import { GetterAuth } from './enum';

export const getters: GetterTree<AuthState, RootState> = {
  /**
   * Determina se o usuário está autenticado.
   * */
  isAuthenticated(state): boolean {
    return !!state.token;
  },

  /**
   * Retorna o token da autenticação.
   * */
  token(state): string | null {
    return state.token;
  },

  /**
   * Retorna o usuário autenticado.
   * */
  user(state): AuthenticatedUser | null {
    return state.user;
  },

  /**
   * Retorna o token de processo.
   * */
  processToken(state): string | null {
    return state.processToken?.token ?? null;
  },

  /**
   * Retorna a atual plataforma carregada.
   */
  platform(state): Platform.TRAY | Platform.BAGY | null {
    return state.platform;
  },

  [GetterAuth.PLATFORM_ID](state): number | null {
    if (!state.platform) {
      return null;
    }

    return state.platform === Platform.TRAY ? 1 : 2;
  },
};

export default getters;
