import { GetterTree } from 'vuex';
import { Store } from '@/entities';

import { StoreState } from './types';
import { RootState } from '../../types';

export const getters: GetterTree<StoreState, RootState> = {
  /**
   * Retorna a loja da sessão.
   * */
  store(state): Store | null {
    return state.store;
  },
};

export default getters;
