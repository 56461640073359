import Entity from './entity';

export default class OrderPayment extends Entity<OrderPayment> {
  /**
   * Id do pagamento.
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * Documento do cliente.
   *
   * @var {string} document
   * */
  document!: string;

  /**
   * Método do pagamento.
   *
   * @var {string} method
   * */
  method!: string;

  /**
   * Total pago.
   *
   * @var {number} value
   * */
  value!: number;

  /**
   * Data do pagamento
   *
   * @var {string} date
   * */
  date!: string;
}
