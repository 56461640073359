export enum MutationMarketplaces {
  /**
   * Salva lista de marketplaces.
   */
  SAVE_LIST = 'SAVE_LIST',

  /**
   * Altera o status de carregamento da página.
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Salva as configurações do marketplace atual.
   */
  SAVE_CONFIGS_CURRENT_MARKETPLACE = 'SAVE_CONFIGS_CURRENT_MARKETPLACE',
}
