import Entity from './entity';

export type ImageThumb = {
  /**
   * Url para a thumb pequena pequena
   *
   * @var {string | null}
   * */
  small: string | null;

  /**
   * Url para a thumb média pequena
   *
   * @var {string | null}
   * */
  medium: string | null;

  /**
   * Url para a imagem grande
   *
   * @var {string | null}
   * */
  large: string | null;
};

export default class Image extends Entity<Image> {
  /**
   * Id da imagem.
   *
   * @var number
   * */
  id!: number;

  /**
   * Url principal da imagem.
   *
   * @var string
   * */
  url!: string;

  /**
   * Thumbs da imagem
   *
   * @var {ImageThumb}
   * */
  thumbs: ImageThumb = {
    small: null,
    medium: null,
    large: null,
  };
}
