import { AxiosRequestConfig, AxiosResponse } from 'axios';

export interface ApiPaging {
  from: number;
  to: number;
  number: number;
  size: number;
  total: number;
  links: {
    first_page: string | null;
    prev_page: string | null;
    next_page: string | null;
    last_page: string | null;
  };
}

export interface ApiResponsePayload {
  data: unknown;
  meta: {
    version: string;
    [key: string]: unknown;
  };
  paging: ApiPaging;
  filtering?: Record<string, string | number>;
}

export class ApiResponse implements AxiosResponse {
  config: AxiosRequestConfig;

  data: ApiResponsePayload;

  headers: any;

  request: any;

  status: number;

  statusText: string;

  constructor(response: AxiosResponse) {
    this.config = response.config;
    this.data = response.data;
    this.headers = response.headers;
    this.request = response.request;
    this.status = response.status;
    this.statusText = response.statusText;
  }

  getMeta(): any {
    return this.data.meta;
  }

  getData(): any {
    return this.data.data;
  }

  getPaging(): any {
    return this.data.paging;
  }
}
