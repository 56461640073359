import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { GetterQuantityOfLists } from './enum-getter';
import { QuantityOfTabs, QuantityOfListsState } from './types';
import { PagesName } from '@/router/routes/pages-enum';

export const getters: GetterTree<QuantityOfListsState, RootState> = {
  [GetterQuantityOfLists.HIGHLIGHT_ACTIVE]: (state): number => state.highlightsOffers.active,

  [GetterQuantityOfLists.HIGHLIGHT_PAUSED]: (state): number => state.highlightsOffers.paused,

  [GetterQuantityOfLists.OFFERS_IN_PROCESSING]: (state): number => {
    return state.tabs[PagesName.OFFERS_IN_PROCESSING];
  },

  [GetterQuantityOfLists.OFFERS_AWAITING_CORRECTION]: (state): number => {
    return state.tabs[PagesName.OFFERS_AWAITING_CORRECTION];
  },

  [GetterQuantityOfLists.PENDING_SALES]: (state): number => {
    return state.tabs[PagesName.PENDING_SALES];
  },

  [GetterQuantityOfLists.TABS]: (state): QuantityOfTabs => state.tabs,

  [GetterQuantityOfLists.IS_LOADING]: (state): boolean => state.isLoading,

  [GetterQuantityOfLists.FORCE_SHOW_PENDING_SALES_TAB]: (state): boolean => {
    return state.forceShowPendingSalesTab;
  },

  [GetterQuantityOfLists.IS_LOADING_HIGHLIGHTS]: (state): boolean => {
    return state.highlightsOffers.isLoading;
  },
};

export default getters;
