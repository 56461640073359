import { VueConstructor } from 'vue';
import map from 'lodash/map';
import capitalize from 'lodash/capitalize';
import { Filter } from './filter';

export class CapitalizeWordsFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para capitalize de palavras.
     *
     * @example {{ text | cpf }}
     * */
    vue.filter('capitalizeWords', function (value: string) {
      const text = value.split(' ');
      return map(text, capitalize).join(' ');
    });
  }
}

export default CapitalizeWordsFilter;
