import { NavigationGuard } from 'vue-router/types/router';

import store from '@/store';
import { AuthenticatedUser } from '@/entities';

export const FetchStore: NavigationGuard = async (routeTo, routeFrom, next) => {
  const storeIsRequiredForRoute = routeTo.matched.some((route) => route.meta.storeRequired);
  if (!storeIsRequiredForRoute) {
    return next();
  }

  const user = store.getters['auth/user'] as AuthenticatedUser;
  // Registro da store separado para facilitar cenários futuros de multiplas lojas.
  store.commit('store/setStore', user.store);

  return next();
};
