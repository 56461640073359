export enum GetterListWaitingCorrection {
  /**
   * Retorna se a lista de anúncios está carregando
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Retorna todos os anúncios aguardando correção.
   */
  LIST = 'LIST',

  /**
   * Verifica se existe algum campo preenchido no filtro da página.
   */
  HAS_FILTERS_APPLY = 'HAS_FILTERS_APPLY',

  /**
   * Opções para o campo de status do filtro.
   */
  OPTIONS_STATUS = 'OPTIONS_STATUS',

  /**
   * Retorna a oferta com erro
   */
  OFFER_WITH_ERROR = 'OFFER_WITH_ERROR',
  /**
   * Retorna se a oferta com erro tem ações
   */
  OFFER_WITH_ERROR_HAS_ACTIONS = 'OFFER_WITH_ERROR_HAS_ACTIONS',
  /**
   * Retorna a confirmação de que a oferta com erro pode ser salva
   */
  OFFER_WITH_ERROR_CAN_SAVE = 'OFFER_WITH_ERROR_CAN_SAVE',
  /**
   * Retorna as variações de uma draft offer
   */
  OFFER_VARIANTS = 'OFFER_VARIANTS',
  /**
   * Retorna os erros de uma draft offer
   */
  OFFER_ERRORS = 'OFFER_ERRORS',
  /**
   * Retorna os erros das variações de uma draft offer
   */
  OFFER_VARIANTS_ERRORS = 'OFFER_VARIANTS_ERRORS',
  /**
   * Retorna o novo titulo da oferta com erro
   */
  OFFER_NEW_TITLE = 'OFFER_NEW_TITLE',
  /**
   * Oferta a ser removida.
   */
  OFFER_REMOVE_MODAL = 'OFFER_REMOVE_MODAL',
}
