import { NavigationGuard } from 'vue-router/types/router';
import vuex from '@/store';
import router from '@/router';
import { Account, Marketplace, Store } from '@/entities';
import { CheckPendingOrder } from './check-pending-order';
import { PagesName } from '@/router/routes/pages-enum';

/**
 * Guarda de rota para verificar se o cliente tem uma conta para o marketplace atual.
 *
 * Responsável por efetuar redirects caso o usuário não possua conta, ou possua uma conta
 * pendente.
 */
export const CheckAccount: NavigationGuard = async (to, from, next) => {
  const marketplace = (await vuex.getters['marketplace/activeMarketplace']) as Marketplace;
  const store = vuex.getters['store/store'] as Store;

  const currentAccount = vuex.getters['account/accounts'][0] as Account;
  if (!currentAccount) {
    const hasAccounts = await vuex.dispatch('account/hasAccounts', {
      storeId: store.id,
      marketplaceId: marketplace.id,
    });

    if (!hasAccounts) {
      return router.push({ name: 'wizard', params: to.params });
    }
  }

  const pendingAccount = await vuex.dispatch('account/findPendingAccount', {
    storeId: store.id,
    marketplaceId: marketplace.id,
  });
  if (pendingAccount) {
    return router.push({ name: 'wizard', params: to.params });
  }

  const routesConfiguration = [`${PagesName.ACCOUNTS}`, `${PagesName.CATEGORY_RELATIONSHIP}`];
  if (to.name && routesConfiguration.includes(to.name)) return next();

  return CheckPendingOrder(to, from, next);
};
