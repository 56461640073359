import { MarginPricingValue, StepSidebarPricingRule } from '@/components/pricing-rule/type';
import { PricingRuleState } from './types';
import { PricingRuleDisplayConfig } from '@/components/pricing-rule/pricing-configs.enum';
import {
  AdjustmentValueAPI,
  FixedPriceAPI,
  TypePricingRuleStrategy,
  TypePricingScope,
} from '@/entities/pricing-rule';

/**
 * Valores padrões da config de precificação.
 * Usado para resetar os valores padrões no tipo "adjustment-value"
 */
export const defaultAdjustmentValues: AdjustmentValueAPI = {
  type: MarginPricingValue.NUMERIC,
  value: 0,
  calc_base: 'sale_price',
};

/**
 * Valores padrões da config de precificação.
 * Usado para resetar os valores padrões no tipo "fixed-price"
 */
export const defaultFixedPriceValues: FixedPriceAPI = {
  fixed_price: 0,
  fixed_sale_price: null,
  fixed_sale_starts_on: null,
  fixed_sale_ends_on: null,
};

/**
 * Valores base padrão antes de ser definido um pricing para configuração de precificação.
 * Usado também para resetar os valores de estado da sidebar de precificação.
 */
export const defaultValuesStatePricingRule: PricingRuleState = {
  pricing: null,
  config: { ...defaultAdjustmentValues },
  displayConfig: PricingRuleDisplayConfig.DISABLED,
  step: StepSidebarPricingRule.CONFIG,
  statusButtonNext: false,
  strategies: [],
  strategyBySlug: null,
  accountName: null,
  marketplaceName: null,
  marketplaceId: null,
  quantityToApply: 0,
  configSummary: {
    scope_type: TypePricingScope.ACCOUNT,
    strategy_slug: TypePricingRuleStrategy.DISABLED,
    configToSave: null,
  },
  typeOfValueAdjustment: TypePricingRuleStrategy['ADDITIONAL-VALUE'],
  isLoading: false,
};
