export enum ActionOrderLabels {
  /**
   * Obtém os nomes de todos marketplaces selecionados no lote de etiquetas
   */
  GET_ALL_MARKETPLACES = 'GET_ALL_MARKETPLACES',

  /**
   * Inicia o processo de criação de um novo lote de etiquetas para impressão preparando
   * também os dados de todos os marketplaces
   */
  START_BATCH_BY_PAYLOAD = 'START_BATCH_BY_PAYLOAD',

  /**
   * Obtém o atual estado do lote de etiquetas pelo ID
   */
  UPDATE_BATCH = 'UPDATE_BATCH',
}
