import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';
import router from '@/router';

import { HubApiResponse } from '@/components/api/hub-client/http';
import { MarketplaceState } from './types';
import { RootState } from '../../types';
import { PagesName } from '@/router/routes/pages-enum';

export const actions: ActionTree<MarketplaceState, RootState> = {
  /**
   * Define o marketplace ativo.
   * */
  setActiveMarketplaceRef(
    { commit }: ActionContext<MarketplaceState, RootState>,
    activeMarketplaceRef?: string
  ): void {
    commit('setActiveMarketplaceRef', activeMarketplaceRef);
  },

  /**
   * Busca o marketplace com o nome fornecido.
   * */
  async loadActiveMarketplace({ commit, getters, dispatch }) {
    const ref = getters['activeMarketplaceRef'];
    try {
      const marketplace = await dispatch('fetchMarketplace', ref);
      commit('setActiveMarketplace', marketplace);

      return getters['activeMarketplace'];
    } catch (e) {
      const hasInitFlow = Vue.$toggle.isEnabled('new-init-flow');

      if (hasInitFlow) {
        router.push({ name: PagesName.MARKEPLACES_LIST });
        return;
      }
      router.push({ name: 'not-found', query: { marketplaceNotFound: ref } });
    }
  },

  /**
   * Busca o marketplace com o nome fornecido.
   * */
  async fetchMarketplace({ commit, getters }, ref: string) {
    const marketplace = getters.getMarketplace(ref);
    if (marketplace) {
      return Promise.resolve(marketplace);
    }

    return Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/marketplaces/${ref}`)
      .then((response) => {
        commit('setMarketplace', { ref: response.getData().name, attributes: response.getData() });

        return getters.getMarketplace(ref);
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 404) {
          throw new Error('Not found');
        }

        throw new Error('Server error');
      });
  },
};

export default actions;
