import { GetterTree } from 'vuex';

import { RootState } from '../../../types';
import { CreateOfferState } from './types';

export const getters: GetterTree<CreateOfferState, RootState> = {
  /**
   * Retorna a oferta salva na store;
   *
   * @param state
   * @return {CreateOfferState['items']}
   * */
  items(state): CreateOfferState['items'] {
    return state.items;
  },

  /**
   * Retorna a loja salva na store;
   *
   * @param state
   * @return {CreateOfferState['storeId']}
   * */
  storeId(state): CreateOfferState['storeId'] {
    return state.storeId;
  },
};

export default getters;
