import { PluginFunction } from 'vue';
import filters from './partials';

const AppFiltersInstaller: PluginFunction<any> = (vue) => {
  filters.forEach((filter) => {
    filter.install(vue);
  });
};

export default AppFiltersInstaller;
