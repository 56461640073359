export enum ActionPricingRule {
  /**
   * Obtém todos Ids das stratégias de precificação.
   *
   * @return {Promise<void>}
   */
  GET_ALL_STRATEGIES = 'GET_ALL_STRATEGIES',

  /**
   * Cria uma nova regra de precificação do tipo `standard` padrão para a conta atual.
   *
   * @return {Promise<void>}
   */
  CREATE_NEW_STANDARD = 'CREATE_NEW_STANDARD',

  /**
   * Busca uma lista com as regras de precificação pelo id da conta
   *
   * @param accountId - Id da conta a obter a regra de precificação
   */
  GET_CURRENT_ACCOUNT_PRICING_RULES_BY_ACCOUNT_ID = 'GET_CURRENT_ACCOUNT_PRICING_RULES_BY_ACCOUNT_ID',
}
