import { installers } from './installers';
import { Installer, Locale } from './installers/installer';

export class LangLoader {
  /**
   * Handler's constructor.
   *
   * @param {Locale} locale
   * */
  constructor(locale: Locale = 'pt-br') {
    this.loadLocale(locale);
  }

  /**
   * Inicializa e aplica as configurações dos locales
   */
  loadLocale(locale: Locale) {
    installers.forEach((installer: Installer) => installer.setLocale(locale));
  }
}

export default LangLoader;
