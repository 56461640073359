import Vue from 'vue';
import { ActionTree } from 'vuex';
import { AxiosResponse, AxiosError } from 'axios';

import { Storage } from '@/components/storage';
import { HubApiResponse } from '@/components/api/hub-client';
import { AuthState, Platform, ProcessToken } from './types';
import { RootState } from '../../types';
import { AuthenticatedUser } from '@/entities';

function hasProcessTokenExpired(token: ProcessToken) {
  const expiresAt = new Date(token.expires_at);
  const fiveMinutes = 1000 * 60 * 5;
  return expiresAt.getTime() - fiveMinutes - new Date().getTime() > 0;
}

export const actions: ActionTree<AuthState, RootState> = {
  /**
   * Busca o profile do utilizador autenticado.
   * */
  async fetchUser({ commit, getters }): Promise<AuthenticatedUser> {
    const { user } = getters;
    if (user && user.id) {
      return user;
    }

    return Vue.$apiClient
      .getHttpClient()
      .get('/auth')
      .then((response: AxiosResponse) => {
        commit('user', response.data.data);

        if (response.data.data.store.platform_id === 1) {
          commit('platform', Platform.TRAY);
          return getters.user;
        }

        commit('platform', Platform.BAGY);
        return getters.user;
      })
      .catch((error: AxiosError) => {
        Vue.$apiClient.cancelPendingRequests('Unauthorized');

        if (error.response && error.response.status === 401) {
          commit('user', null);

          return getters.user;
        }

        throw error;
      });
  },

  /**
   * Define o token de autenticação.
   * */
  setToken({ commit }, token: string) {
    if (token) {
      token = token.replace(/^[Bb]earer\s+/, '');
    }

    commit('token', token);
    Storage.set('token', token);
  },

  /**
   * Define o token de autenticação.
   * */
  clearToken({ commit }) {
    commit('token', null);
    Storage.set('token', '');
  },

  /**
   * Busca o profile do utilizador autenticado.
   * */
  async makeProcessToken({ commit, getters }) {
    const { processToken } = getters;
    if (processToken && !hasProcessTokenExpired(processToken)) {
      return Promise.resolve(processToken);
    }

    return Vue.$apiClient
      .getHttpClient()
      .post<any, HubApiResponse>('/process-token')
      .then((response) => {
        commit('processToken', response.getData());

        return getters.processToken;
      })
      .catch((error: AxiosError) => {
        if (error.response && error.response.status === 401) {
          commit('processToken', null);
          return getters.processToken;
        }
      });
  },
};

export default actions;
