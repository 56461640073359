import { MutationTree } from 'vuex';
import { OffersInProcessingState, ListOffersInProcessingApi } from './types';
import { Mutations } from './enum';

export const mutations: MutationTree<OffersInProcessingState> = {
  [Mutations.SAVE_LIST_OFFERS_PROCESSING](state, categoriesList: ListOffersInProcessingApi) {
    state.list.paging = categoriesList.paging;
    state.list.content = categoriesList.data;
    state.list.filters = categoriesList.filtering;
  },
  [Mutations.SET_LOADING_LIST](state, isLoading: boolean) {
    state.list.loading = isLoading;
  },
};
