<script lang="ts">
import { Vue, Component, Prop } from 'vue-property-decorator';
import { CanSticky, StickerManagerInterface, StickerManager } from './behaviors';

@Component
export default class AppPageHeader extends Vue implements CanSticky {
  /**
   * Determina se o cabeçalho irá utilizar o título da página.
   *
   * @var {boolean} usePageTitle
   * */
  @Prop({ default: false }) usePageTitle!: boolean;

  /**
   * Determina se cabeçalho será fixado na página quando ocorrer o scroll.
   *
   * @var {boolean} stickable
   * */
  @Prop({ default: false }) stickable!: boolean;

  /**
   * Determina se o breadcrumb irá ser demonstrado no mobile
   *
   * @var {boolean} showBreadCrumbOnMobile
   */
  @Prop({ default: false }) showBreadCrumbOnMobile!: boolean;

  /**
   * Determina se o breadcrumb irá ser demonstrado no desktop
   *
   * @var {boolean} showBreadCrumbOnDesktop
   */
  @Prop({ default: true }) showBreadCrumbOnDesktop!: boolean;

  /**
   * Manipulador do comportamento que fixa o cabeçalho na página.
   *
   * @var {StickerManagerInterface} stickerManager
   * */
  stickerManager!: StickerManagerInterface;

  mounted(): void {
    if (this.stickable) {
      this.stickerManager = new StickerManager(this);
      this.stickerManager.start();
    }
  }

  destroyed(): void {
    if (this.stickerManager) {
      this.stickerManager.destroy();
    }
  }

  /**
   * @inheritDoc
   * */
  getElement(): HTMLElement {
    return this.$refs['page-header'] as HTMLElement;
  }

  /**
   * @inheritDoc
   * */
  getScrollableContent(): HTMLElement {
    return document.querySelector('.app-main') as HTMLElement;
  }

  /**
   * @inheritDoc
   * */
  getTopDistance(): number {
    return 50;
  }
}
</script>

<template>
  <div ref="page-header" class="page-header" new-flow>
    <div class="page-header__title-wrapper">
      <slot name="page-title">
        <app-page-title v-if="usePageTitle" />
      </slot>
      <slot name="additional-content" />
    </div>

    <div v-test-id="'page-header-slot-actions-wrapper'" class="page-header__actions-wrapper">
      <slot name="actions" />
    </div>
  </div>
</template>

<style lang="scss">
@import 'page-header';
</style>
