export enum MutationPricingRule {
  /**
   * Salva a regra de precificação a ser aplicada.
   */
  SAVE_PRICING = 'SAVE_PRICING',

  /**
   * Salva a configuração de exibição selecionada na sidebar de precificação.
   */
  SAVE_DISPLAY_CONFIG = 'SAVE_DISPLAY_CONFIG',

  /**
   * Salva o status de interação do botão "avançar".
   */
  DISABLED_BUTTON = 'DISABLED_BUTTON',

  /**
   * Define os valores padrões ao abrir a sidebar.
   */
  SET_DEFAULT_VALUES = 'SET_DEFAULT_VALUES',

  /**
   * Salva o tipo de acréscimo do campo selecionado.
   */
  SET_ADDITIONAL_MARGIN_TYPE = 'SET_ADDITIONAL_MARGIN_TYPE',

  /**
   * Salva o valor base de cálculo do campo selecionado.
   */
  SET_ADDITIONAL_BASE_PRICE = 'SET_ADDITIONAL_BASE_PRICE',

  /**
   * Salva apenas o valor da config de valor adicional
   */
  SET_ADDITIONAL_VALUE = 'SET_ADDITIONAL_VALUE',

  /**
   * Salva a config no padrão de valor adicional
   */
  SET_CONFIG_ADJUSTMENT_VALUE = 'SET_CONFIG_ADJUSTMENT_VALUE',

  /**
   * Salva a config no padrão de preço fixo para a regra de precificação.
   */
  SET_CONFIG_FIXED_PRICE = 'SET_CONFIG_FIXED_PRICE',

  /**
   * Salva o tipo de ajuste selecionado para margem
   */
  SET_TYPE_OF_VALUE_ADJUSTMENT = 'SET_TYPE_OF_VALUE_ADJUSTMENT',

  /**
   * Define o status de carregamento da sidebar de precificação
   */
  SET_LOADING = 'SET_LOADING',

  /**
   * Salva os ids de todas as estratégias possíveis.
   */
  SAVE_STRATEGIES = 'SAVE_STRATEGIES',

  /**
   * Salva o nome da conta e do marketplace.
   */
  SAVE_ACCOUNT_AND_MARKETPLACE = 'SAVE_ACCOUNT_AND_MARKETPLACE',

  /**
   * Salva o nome da conta e do marketplace.
   */
  SAVE_MARKETPLACE_ID = 'SAVE_MARKETPLACE_ID',

  /**
   * Salva a quantidade de anúncios selecionados que serão aplicadas a regra.
   */
  SAVE_QUANTITY_TO_APPLY = 'SAVE_QUANTITY_TO_APPLY',

  /**
   * Salva a etapa atual da sidebar de precificação.
   */
  SET_STEP = 'SET_STEP',

  /**
   * Salva a configuração para exibição do resumo da regra de precificação.
   */
  SET_CONFIG_SUMMARY = 'SET_CONFIG_SUMMARY',
}

export enum GetterPricingRule {
  /**
   * Configuração de precificação salva.
   */
  PRICING = 'PRICING',

  /**
   * Retorna o tipo de configuração de exibição selecionado.
   */
  DISPLAY_CONFIG = 'DISPLAY_CONFIG',

  /**
   * Status do botão para avanço de etapa.
   */
  IS_DISABLED_BUTTON_NEXT = 'IS_DISABLED_BUTTON_NEXT',

  /**
   * Valor de acréscimo do campo adicional.
   */
  ADDITIONAL_VALUE = 'ADDITIONAL_VALUE',

  /**
   * Tipo de acréscimo do campo selecionado.
   */
  ADDITIONAL_MARGIN_TYPE = 'ADDITIONAL_MARGIN_TYPE',

  /**
   * Valor base de calculo do campo selecionado.
   */
  ADDITIONAL_BASE_PRICE = 'ADDITIONAL_BASE_PRICE',

  /**
   * Tipo de ajuste do valor selecionado para margem
   */
  TYPE_OF_VALUE_ADJUSTMENT = 'TYPE_OF_VALUE_ADJUSTMENT',

  /**
   * Todos os campos da configuração de regra de precificação.
   */
  CONFIG = 'CONFIG',

  /**
   * Status de carregamento.
   */
  IS_LOADING = 'IS_LOADING',

  /**
   * Retorna uma objeto com todas estratégias de precificação armazenadas pelo nome da
   * estratégia.
   */
  STRATEGIES_BY_SLUG = 'STRATEGIES_BY_SLUG',

  /**
   * Retorna uma lista com todas estratégias de precificação e seus respectivos ids.
   */
  LIST_STRATEGIES = 'LIST_STRATEGIES',

  /**
   * Retorna o slug da estratégia de precificação pelo id da estratégia passado por
   * parâmetro.
   *
   * @param strategy_id - Id da estratégia de precificação.
   */
  GET_SLUG_BY_STRATEGY_ID = 'GET_SLUG_BY_STRATEGY_ID',

  /**
   * Retorna o nome da conta em edição na atual regra de precificação.
   */
  ACCOUNT_NAME = 'ACCOUNT_NAME',

  /**
   * Retorna o nome do marketplace em edição na atual regra de precificação.
   */
  MARKETPLACE_NAME = 'MARKETPLACE_NAME',

  /**
   * Retorna o id do marketplace em edição na atual regra de precificação.
   */
  MARKETPLACE_ID = 'MARKETPLACE_ID',

  /**
   * Retorna a quantidade de anúncios em que será aplicada a regra de precificação.
   */
  QUANTITY_TO_APPLY = 'QUANTITY_TO_APPLY',

  /**
   * Retorna a etapa atual da sidebar de precificação.
   */
  STEP = 'STEP',

  /**
   * Retorna a configuração para exibição do resumo da regra de precificação.
   */
  CONFIG_SUMMARY = 'CONFIG_SUMMARY',
}
