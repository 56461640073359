import { ActionTree } from 'vuex';
import { PendingSalesListState } from './types';
import { RootState } from '@/store/types';
import { ActionPendingSales } from './enum-action';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import { MutationPendingSales } from './enum-mutation';
import { MutationPagingByRoute } from '../paging-by-route/enum-mutation';
import Vue from 'vue';
import { ApiResponse } from '@tray-libs/design-system-components/src/components/api/client/http/response';
import { ParamsSetPaging } from '../paging-by-route/types';
import { ApiPaging } from '@/components/api/client/http';
import { Order } from '@/entities';
import { PagesName } from '@/router/routes/pages-enum';
import { MutationFilterByRoute } from '../filter-by-route/enum-mutation';
import { emptyFiltersPendingSales } from '.';

export const actions: ActionTree<PendingSalesListState, RootState> = {
  async [ActionPendingSales.GET_LIST](
    { commit, rootGetters },
    params: URLSearchParams
  ): Promise<any> {
    try {
      commit(MutationPendingSales.SET_LOADING, true);
      const storeId = rootGetters['store/store'].id.toString();
      const apiUrl = `/stores/${storeId}/orders`;

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<Order[], ApiResponse>(apiUrl, {
        params,
      });

      const data = response.getData().map((item: Order) => {
        return Order.from(item);
      });

      commit(MutationPendingSales.SAVE_LIST, data);

      const paramsPaging: ParamsSetPaging = {
        route: PagesName.PENDING_SALES,
        paging: response.getPaging() as ApiPaging,
      };
      commit('paging/' + MutationPagingByRoute.SAVE_PAGING, paramsPaging, {
        root: true,
      });

      return response;
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        handlerErrorActions({ where: 'Action GET LIST Offers', error }),
        { root: true }
      );
    } finally {
      commit(MutationPendingSales.SET_LOADING, false);
    }
  },
  [ActionPendingSales.RESET_FILTERS]({ commit }) {
    commit(
      'filter/' + MutationFilterByRoute.SAVE_FILTER,
      {
        route: PagesName.PENDING_SALES,
        filters: { ...emptyFiltersPendingSales },
      },
      {
        root: true,
      }
    );
  },
};

export default actions;
