import { MutationTree } from 'vuex';
import { WaitingCorrectionListState } from './types';
import { MutationListWaitingCorrection } from './enum-mutation';
import Offer from '@/entities/offer-new';
import { Option } from '@/components/ui/multiple-select/multiple-select';

export const mutations: MutationTree<WaitingCorrectionListState> = {
  [MutationListWaitingCorrection.SAVE_LIST](state, list: Offer[]) {
    state.list = list;
  },

  [MutationListWaitingCorrection.SET_STATUS_OPTIONS](state, options: Option[]) {
    state.optionsStatus = options;
  },

  [MutationListWaitingCorrection.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  [MutationListWaitingCorrection.SET_OFFER_WITH_ERROR](state, response) {
    state.offerWithError = response;
  },

  [MutationListWaitingCorrection.SET_OFFER_WITH_ERROR_HAS_ACTIONS](state, response) {
    state.offerWithErrorHasActions = response;
  },

  [MutationListWaitingCorrection.SET_OFFER_WITH_ERROR_CAN_SAVE](state, response) {
    state.offerWithErrorCanSave = response;
  },

  [MutationListWaitingCorrection.SET_OFFER_ERRORS](state, response) {
    state.offerErrors = response;
  },

  [MutationListWaitingCorrection.SET_OFFER_VARIANTS](state, response) {
    state.offerVariants = response;
  },

  [MutationListWaitingCorrection.SET_OFFER_NEW_TITLE](state, response) {
    state.offerNewTitle = response;
  },

  [MutationListWaitingCorrection.RESET_ERRORS](state) {
    state.offerWithError = null;
    state.offerErrors = [];
    state.offerVariants = [];
    state.offerWithErrorHasActions = false;
    state.offerWithErrorCanSave = false;
    state.offerNewTitle = null;
  },

  [MutationListWaitingCorrection.SET_OFFER_REMOVE](state, offer) {
    state.offerRemoveModal = offer;
  },
};

export default mutations;
