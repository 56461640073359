import Entity from './entity';
import { BroadcastingEntity } from './contracts/broadcasting-entity';

export type StoreStatus = 'creating' | 'created';

export default class Store extends Entity<Store> implements BroadcastingEntity {
  /**
   * Estado em que a loja se encontra.
   *
   * @var {StoreStatus}
   * */
  status!: StoreStatus;

  /**
   * @inheritDoc
   * */
  getChannelPrefix(): string {
    return `store-${this.id}`;
  }

  /**
   * Determina se a loja está pronta para uso.
   *
   * @return boolean
   * */
  isProvisioned(): boolean {
    return this.status === 'created';
  }
}
