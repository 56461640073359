import { Module } from 'vuex';

import { PricingRuleState } from './types';
import { RootState } from '../../types';
import mutations from './mutations';
import actions from './actions';
import getters from './getters';
import { defaultValuesStatePricingRule } from '@/store/modules/pricing-rule/default-values-state-pricing-rule';

/**
 * Vuex da Sidebar de Regra de Precificação, responsável por controlar o estado da
 * sidebar.
 *
 * Qualquer valor a ser exibido baseado na regra de precificação deve ser obtida dessa
 * vuex, mas o controle de salvar esses dados em um estado local é do componente que usar
 * a `pricing-rule-sidebar`, pois essa vuex é limpa a cada fechamento da sidebar.
 */
export const state: PricingRuleState = { ...defaultValuesStatePricingRule };

const namespaced = true;

export const pricingRuleModule: Module<PricingRuleState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
