import Entity from './entity';
import OfferRelationItem from './offer-relation-item';

export default class OfferRelation extends Entity<OfferRelation> {
  /**
   * Id do relacionamento.
   * */
  id!: number;

  /**
   * Id do produto
   * */
  product_id!: string;

  /**
   * Id da oferta
   * */
  offer_id!: number;

  /**
   * lista de relacionamentos entre a oferta e o produto.
   *
   * @var {Array<OfferRelationItem>}
   * */
  items: Array<OfferRelationItem> = [];

  /**
   * Adiciona um novo relacionamento
   *
   * @param {Array<OfferRelationItem>} item
   * */
  addRelation(...item: OfferRelationItem[]): void {
    item.forEach((item) => {
      this.items.push(item);
    });
  }

  /**
   * Retorna o número total de relacionamentos
   *
   * @var {number}
   * */
  getTotalOfRelations(): number {
    return this.items.length;
  }
}
