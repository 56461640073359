import Entity from './entity';
import Account from './account';
import OrderPayment from './order-payment';
import OrderOffer from './order-offer';

export default class Order extends Entity<Order> {
  /**
   * Id do pedido.
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * Anúncios do pedido.
   */
  offers!: OrderOffer[];

  /**
   * Status do pedido.
   *
   * @var {string} status
   * */
  status!: string;

  /**
   * Total do pedido.
   *
   * @var {number} total
   * */
  total!: number;

  /**
   * Subtotal do pedido.
   *
   * @var {number} subtotal
   * */
  subtotal!: number;

  /**
   * Desconto do pedido.
   *
   * @var {number} discount
   * */
  discount!: number;

  /**
   * Id do cliente do pedido.
   *
   * @var {number} marketplace_customer_id
   * */
  marketplace_customer_id!: number;

  /**
   * Id do ponto de venda do pedido.
   *
   * @var {number} marketplace_sale_point_id
   * */
  marketplace_sale_point_id!: number;

  /**
   * Id da conta do pedido.
   *
   * @var {number} marketplace_account_id
   * */
  marketplace_account_id!: number;

  /**
   * Id do pedido no marketplace.
   *
   * @var {number} marketplace_order_id
   * */
  marketplace_order_id!: number;

  /**
   * Última atualização do pedido no marketplace.
   *
   * @var {number} marketplace_updated_at
   * */
  marketplace_updated_at!: string;

  /**
   * Conta do marketplace.
   * */
  account = Account.from({});

  /**
   * Aptidão para criar etiqueta.
   * */
  is_ready_to_create_label!: number;

  /**
   * Data da criação da etiqueta.
   * */
  label_created_at!: string;

  /**
   * Lista de pagamento do pedido
   *
   * @var Array<OrderPayment>
   * */
  protected _payments: Array<OrderPayment> = [];

  /**
   * Setter para as groups.
   * Garante que as groups sejam instâncias de OrderLabelGroup.
   * */
  set payments(payments: Array<OrderPayment>) {
    if (!payments) {
      this._payments = [];
      return;
    }

    this._payments = payments.map((payment) => {
      if ((payment as unknown) instanceof OrderPayment) {
        return payment;
      }

      return OrderPayment.from(payment as object);
    });
  }

  get payments(): Array<OrderPayment> {
    return this._payments;
  }
}
