import Vue from 'vue';
import { ActionContext, ActionTree } from 'vuex';

import vuex from '@/store';
import { Account, Marketplace } from '@/entities';
import { HubApiResponse } from '@/components/api/hub-client/http';
import { WizardState } from './types';
import { RootState } from '../../../types';

type InitializerPayload = {
  storeId: number;
  marketplaceRef: string;
};

type NewAccountPayload = {
  storeId: number;
  marketplaceId: number;
};

export class MarketplaceNotFoundError extends Error {}

export const actions: ActionTree<WizardState, RootState> = {
  fresh({ commit }) {
    commit('setNewAccount', null);
    commit('setMarketplace', null);
    commit('setOfferImport', null);
  },

  async init({ commit, dispatch }, { storeId, marketplaceRef }: InitializerPayload): Promise<void> {
    await dispatch('fresh');
    const marketplace = await dispatch('findMarketplace', marketplaceRef);

    const accounts = vuex.getters['account/accounts'];
    if (accounts.length && accounts[0].isWizardCompleted()) {
      return;
    }

    // Se tem a instalação de uma conta em andamento, retornamos a conta.
    const pendingAccount = await dispatch(
      'account/findPendingAccount',
      {
        storeId,
        marketplaceId: marketplace.id,
      },
      { root: true }
    );
    if (pendingAccount) {
      commit('setNewAccount', pendingAccount);
      return;
    }

    // Cria uma conta para o marketplace
    await dispatch('makeNewAccount', {
      storeId,
      marketplaceId: marketplace.id,
    });
  },

  /**
   * Define o marketplace.
   * */
  setMarketplace(
    { commit }: ActionContext<WizardState, RootState>,
    marketplace: Marketplace
  ): void {
    commit('setMarketplace', marketplace);
  },

  /**
   * Busca e define o marketplace com o nome fornecido.
   *
   * @return Promise<Marketplace>
   * */
  async findMarketplace({ commit, getters }, marketplaceRef: string): Promise<Marketplace> {
    const { marketplace } = getters;
    if (marketplace && marketplace.name === marketplaceRef) {
      return Promise.resolve(marketplace);
    }

    return Vue.$apiClient
      .getHttpClient()
      .get<any, HubApiResponse>(`/marketplaces/${marketplaceRef}`)
      .then((response) => {
        const marketplace = Marketplace.from(response.getData());
        commit('setMarketplace', marketplace);

        return marketplace;
      })
      .catch((error) => {
        const { status } = error.response;
        if (status === 404) {
          throw new MarketplaceNotFoundError();
        }

        throw new Error('Server error');
      });
  },

  /**
   * Cria uma conta para o marketplace.
   *
   * @return void
   * */
  async makeNewAccount({ commit }, { storeId, marketplaceId }: NewAccountPayload) {
    return Vue.$apiClient
      .getHttpClient()
      .post<any, HubApiResponse>(`/stores/${storeId}/accounts`, {
        marketplace_id: marketplaceId,
      })
      .then(async (response) => {
        const account = Account.from(response.getData());
        commit('setNewAccount', account);

        return account;
      });
  },

  /**
   * Define a conta sendo autenticada.
   * */
  setNewAccount({ commit }, account: Account) {
    commit('setNewAccount', account);
  },
};

export default actions;
