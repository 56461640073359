import Entity from './entity';
import Application from './application';

export default class Marketplace extends Entity<Marketplace> {
  /**
   * Identificação do marketplace.
   *
   * @var id
   * */
  id!: number;

  /**
   * Nome do utilizador.
   *
   * @var string
   * */
  name!: string;

  /**
   * App do marketplace.
   *
   * @var {Application}
   * */
  private _application!: Application;

  set application(app: Application) {
    this._application = app;
  }

  get application(): Application {
    return this._application;
  }

  isOfferCreationEnabled(): boolean {
    return this.name === 'shopee';
  }
}
