import { MutationTree } from 'vuex';
import {
  CategoriesState,
  CategoryByRef,
  CategoryToAPI,
  ErrorBvToast,
  IdentifyPathCategory,
  PlatformCategory,
} from './types';
import { Mutations } from './enum';
import Category from '@/entities/category';
import { mutationsAtttributes } from './mutations-attributes';
import { ListCategoryApi } from './type-categories-list';

export const mutations: MutationTree<CategoriesState> = {
  [Mutations.SET_CATEGORY_PLATFORM_IN_BINDING](state, category: PlatformCategory) {
    state.toBinding.platform.category = category;
  },

  [Mutations.SET_CATEGORY_MARKETPLACE_IN_BINDING](state, category: PlatformCategory) {
    if (category.linked_category) {
      state.toBinding.marketplace.selectedCategory = category.linked_category;
    }
  },

  [Mutations.SAVE_CATEGORIES_MARKETPLACE_ROOT](state, categoriesList: Category[]) {
    state.categories.marketplace.root = categoriesList;
  },

  [Mutations.SAVE_CATEGORIES_MARKETPLACE_BY_REF](state, data: CategoryByRef) {
    state.categories.marketplace.byRef[data.ref] = data;
  },

  [Mutations.CLEAR_CATEGORIES_BINDING](state) {
    state.categories.marketplace.root = [];
    state.categories.marketplace.byRef = {};
    state.toBinding.platform.category = {};
    state.toBinding.marketplace.path = [];
    state.toBinding.linked.categories.platform_category = null;
    state.toBinding.linked.categories.marketplace_category = null;
    state.toBinding.marketplace.selectedCategory = null;
  },

  [Mutations.CLEAR_CATEGORIES_SELECTION](state) {
    state.toBinding.marketplace.categories = [];
  },

  [Mutations.SAVE_CATEGORIES_MARKETPLACE_IN_BINDING](state, categories: Category[]) {
    state.toBinding.marketplace.categories = categories;
  },

  [Mutations.SET_LINKED_CHANGED_TO](state, changed: boolean) {
    state.toBinding.linked.changed = changed;
  },

  [Mutations.SET_LOADING_CATEGORIES_MARKETPLACE_IN_BINDING](state, isLoading: boolean) {
    state.toBinding.isLoading = isLoading;
  },

  [Mutations.SET_CATEGORIES_MARKETPLACE_PATH_IN_BINDING](state, path: IdentifyPathCategory[]) {
    state.toBinding.marketplace.path = path;
  },

  [Mutations.LOAD_CATEGORIES_MARKETPLACE_IN_BINDING_BY_REF](state, ref: number) {
    if (state.categories.marketplace.byRef[ref].children) {
      state.toBinding.marketplace.categories =
        state.categories.marketplace.byRef[ref].children || [];

      state.toBinding.marketplace.path = state.categories.marketplace.byRef[ref].path_from_root;
    }
  },

  [Mutations.LOAD_CATEGORIES_ROOT_MARKETPLACE_IN_BINDING](state) {
    if (state.categories.marketplace.root.length > 0) {
      state.toBinding.marketplace.categories = state.categories.marketplace.root;

      state.toBinding.marketplace.path = [];
    }
  },

  [Mutations.ADD_CATEGORY_PLATFORM_IN_BINDIG_TO_LINKED](state) {
    if (state.toBinding.platform.category) {
      state.toBinding.linked.categories.platform_category = {
        ref: state.toBinding.platform.category.ref,
        name: state.toBinding.platform.category.name,
        original_name: state.toBinding.platform.category.original_name,
      };
    }
  },

  [Mutations.ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED](state) {
    if (state.toBinding.marketplace.selectedCategory) {
      state.toBinding.linked.categories.marketplace_category = {
        ref: state.toBinding.marketplace.selectedCategory.ref,
        name: state.toBinding.marketplace.selectedCategory.name,
        original_name: state.toBinding.marketplace.selectedCategory.original_name,
        parent_ref: state.toBinding.marketplace.selectedCategory.parent_ref,
        fullpath: state.toBinding.marketplace.selectedCategory.fullpath,
        path_from_root: state.toBinding.marketplace.selectedCategory.path_from_root,
      };
    }
  },

  [Mutations.ADD_CATEGORY_MARKETPLACE_IN_BINDIG_TO_LINKED_BY_REF](state, ref: number) {
    const category = state.toBinding.marketplace.categories.find(
      (category) => category.ref === ref
    );

    if (category) {
      state.toBinding.linked.categories.marketplace_category = {
        ref: category.ref,
        name: category.name,
        original_name: category.original_name,
        parent_ref: category.parent_ref,
        fullpath: '',
        path_from_root: [],
      };
    }
  },

  [Mutations.CLEAR_CATEGORY_MARKETPLACE_LINKED](state) {
    state.toBinding.linked.categories.marketplace_category = null;
  },

  [Mutations.PREPARES_DATA_FOR_LINKED_CATEGORY](state, ref: string) {
    if (!state.toBinding.linked.categories.marketplace_category) return;
    if (state.toBinding.linked.categories.marketplace_category.ref !== ref) return;

    const parentRef = state.toBinding.linked.categories.marketplace_category.parent_ref;

    let categoryParent: null | CategoryByRef | Category | undefined =
      state.categories.marketplace.byRef[parentRef] ?? null;

    if (!categoryParent) {
      categoryParent = state.categories.marketplace.root.find(
        (category) => category.ref === parentRef
      );
    }

    if (!categoryParent) return;

    let pathFromRoot: IdentifyPathCategory[] = categoryParent.path_from_root || [];
    pathFromRoot = pathFromRoot.concat([
      {
        ref: ref,
        name: state.toBinding.linked.categories.marketplace_category.name,
      },
    ]);

    state.toBinding.linked.categories.marketplace_category.path_from_root = pathFromRoot;

    const fullpath = pathFromRoot.reduce((acc, cur, index) => {
      if (index === 0) return cur.name;

      return `${acc} > ${cur.name}`;
    }, '');

    state.toBinding.linked.categories.marketplace_category.fullpath = fullpath;
  },

  [Mutations.SET_ERROR_IN_BINDIG](state, error: ErrorBvToast | null) {
    state.toBinding.error = error;

    setTimeout(() => {
      state.toBinding.error = null;
    }, error?.autoHideDelay);
  },

  ...mutationsAtttributes,

  /**
   * Define as categorias selecionadas para criação de ofertas.
   * */
  [Mutations.SAVE_LIST_CATEGORIES_CREATE_OFFERS](state, categories: Array<Category>) {
    state.selectedCategoriesToOffers = categories;
  },

  [Mutations.UPDATE_CATEGORY_IN_LIST](state, ref: string) {
    let categoryLinked;
    const refCategoryMktpl = state.toBinding.linked.categories.marketplace_category?.ref;
    const { parent_ref, fullpath, path_from_root } = state.toBinding.linked.categories
      .marketplace_category as CategoryToAPI;

    if (!refCategoryMktpl) return;

    const childrenCategories = state.categories.marketplace.byRef[parent_ref]?.children;
    categoryLinked = childrenCategories?.find((category) => category.ref === refCategoryMktpl);

    if (!categoryLinked) {
      categoryLinked = state.categories.marketplace.root.find(
        (category) => category.ref === refCategoryMktpl
      );
    }

    if (!categoryLinked) return;

    const index = state.categories.list.content.findIndex((category) => category.ref === ref);
    if (index === -1) return;

    state.categories.list.content[index] = {
      ...state.categories.list.content[index],
      is_linked: true,
      linked_category: {
        ...categoryLinked,
        fullpath,
        path_from_root,
      },
    };

    /** Resolve a reatividade do componente b-table na listagem e não pode ser removido */
    const newListReferenceToUpdateTable = state.categories.list.content.slice();
    state.categories.list.content = newListReferenceToUpdateTable;
  },

  [Mutations.SAVE_LIST_CATEGORY](state, categoriesList: ListCategoryApi) {
    state.categories.list.paging = categoriesList.paging;
    state.categories.list.content = categoriesList.data;
    state.categories.list.filters = categoriesList.filtering;
  },

  [Mutations.SET_LOADING_LIST_CATEGORY](state, isLoading: boolean) {
    state.categories.list.loading = isLoading;
  },
};

export default mutations;
