import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { OrderState } from './types';

export const getters: GetterTree<OrderState, RootState> = {
  /**
   * Retorna a quantidade de pedidos pendentes.
   *
   * @param {state}
   * @return {number | null}
   * */
  quantityPendingOrders(state): number | null {
    return state.quantityPendingOrders;
  },
};

export default getters;
