import { VueConstructor } from 'vue';
import { Filter } from './filter';

export class CNPJFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para cnpj.
     *
     * @example {{ text | cnpj }}
     * */
    vue.filter('cnpj', function (value: string) {
      if (!value) {
        return;
      }
      return value
        .replace(/\D/g, '')
        .replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5');
    });
  }
}

export default CNPJFilter;
