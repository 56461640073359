import Entity from './entity';
import { LinkedAttribute, Unit } from './types/category-attribute';

/**
 * Entidade de atributos de categoria, conhecido como características na plataforma.
 */
export default class CategoryAttribute extends Entity<CategoryAttribute> {
  /**
   * Referência da categoria.
   *
   * @var {number} ref
   * */
  ref!: string;

  /**
   * Nome do atributo.
   */
  name!: string;

  /**
   * Texto Label usado para exibição do nome do atributo.
   */
  label!: string;

  /**
   * Tipo do atributo que garante como será a exibição do mesmo para relacionamento.
   */
  type!: string;

  /**
   * Regras de validação do atributo, quando for obrigatório virá um texto 'required'
   * se não for vem um array vazio.
   */
  rules!: string[];

  /**
   * Unidades de medida do atributo.
   */
  units!: Unit[] | null;

  /**
   * Se já está relacionado a algum atributo da plataforma.
   */
  linked_attribute!: LinkedAttribute | null;

  /**
   * Retorna se o atributo é obrigatório.
   */
  is_required!: boolean;

  /**
   * Retorna se o atributo é obrigatório.
   * @returns {boolean}
   */
  isRequired(): boolean {
    // TODO: alterar quando a API estiver pronta e definida
    return this.is_required ?? this.rules.includes('required');
  }
}
