import { forkJoin } from 'rxjs/internal/observable/forkJoin';
import { Subject } from 'rxjs/internal/Subject';
import store from '@/store';
import { RootState } from '@/store/types';
import { AuthenticatedUser } from '@/entities';

declare global {
  interface Window {
    dataLayer: Array<any>;
  }
}

type ObservableValues = {
  user: AuthenticatedUser;
};

export class TagManager {
  /**
   * Código do contêiner.
   *
   * @var {string}
   * */
  private code: string;

  /**
   * TagManager's constructor.
   *
   * */
  constructor() {
    if (!(window.dataLayer as unknown)) {
      window.dataLayer = [];
    }

    this.code = 'GTM-WJ92FN7';
    this.init();
  }

  /**
   * Cria uma observable para escutar as alterações de um determinado atributo do vuex.
   *
   * @param {string} type
   * @param {(state: RootState) => any} map
   * @return void
   * */
  private makeSubject(type: string, map: (state: RootState) => any): Subject<any> {
    const subject$: Subject<any> = new Subject();

    store.subscribe((mutation, state) => {
      if (!mutation.payload || subject$.isStopped) {
        return;
      }

      if (mutation.type === type) {
        subject$.next(map(state));
        subject$.complete();
      }
    });

    return subject$;
  }

  /**
   * Inicializa o data layer.
   *
   * @return {void}
   * */
  private init(): void {
    const subjects$ = forkJoin({
      user: this.makeSubject('auth/user', (state) => state.auth.user),
    });

    subjects$.subscribe(({ user }: ObservableValues) => {
      window.dataLayer.push({
        user: user.only('name', 'username', 'email'),
      });

      this.appendSnippet();
      this.appendNoScriptSnippet();
    });
  }

  /**
   * Adiciona o trecho de código do google tag manager.
   *
   * @return {void}
   * */
  private appendSnippet(): void {
    const script: HTMLElement = document.createElement('script');
    script.innerHTML = `
         (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
          new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
          j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
          'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
          })(window,document,'script','dataLayer','${this.code}');
    `;

    document.head.appendChild(script);
  }

  /**
   * Adiciona o trecho de código para suporte em browsers sem o JavaScript habilitado.
   *
   * @return {void}
   * */
  private appendNoScriptSnippet(): void {
    const noScript: HTMLElement = document.createElement('noscript');
    noScript.innerHTML = `
         <iframe
            src="https://www.googletagmanager.com/ns.html?id=${this.code}"
            height="0"
            width="0"
            style="display: none; visibility: hidden"
          ></iframe>;
    `;

    document.body.insertAdjacentElement('afterbegin', noScript);
  }
}

export default TagManager;
