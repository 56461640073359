import Entity from './entity';

export type OfferPricingStrategy = {
  id: number;
  slug: 'disabled' | 'standard' | 'discount-value' | 'additional-value';
  title: string;
  description: string;
};

export type OfferPricingConfig = {
  [key: string]: any;
  type?: 'percentage' | 'numeric';
  value?: number;
  calc_base?: 'sale_price' | 'cost_price';
};

export default class OfferPricing extends Entity<OfferPricing> {
  /**
   * Id da precificação.
   *
   * @var {number} id
   * */
  id!: number;

  /**
   * Id da estratégia.
   *
   * @var {number} strategy_id
   * */
  strategy_id!: number;

  /**
   * Título da regra.
   *
   * @var {string} title
   * */
  title!: string;

  /**
   * Se a regra é reutilizável.
   *
   * @var {boolean} is_reusable
   * */
  is_reusable!: boolean;

  /**
   * Configuração da regra selecionada.
   *
   * @var {OfferPricingConfig} config
   * */
  private _config!: OfferPricingConfig | null;

  set config(value: OfferPricingConfig | null) {
    if (Array.isArray(value as unknown)) {
      value = {};
    }

    this._config = value;
  }

  get config(): OfferPricingConfig | null {
    return this._config;
  }

  /**
   * Estratégia da precificação
   *
   * @var {Strategy} strategy
   * */
  strategy!: OfferPricingStrategy;

  /**
   * Valor adicional da precificação formatado em Real.
   *
   * @returns {string} preço adicional
   */
  adjustmentPrice(): string {
    if (this.config?.type === 'percentage') {
      return `${this.config.value}%`;
    }

    return new Intl.NumberFormat('pt-BR', {
      style: 'currency',
      currency: 'BRL',
    }).format(this.config?.value || 0);
  }
}
