import { MutationTree } from 'vuex';
import { OrderLabelsState, PayloadSaveMarketplace } from './types';
import { MutationsOrderLabels } from './mutations.enum';
import { OrderLabelGroup } from '@/entities';
import Vue from 'vue';

export const mutations: MutationTree<OrderLabelsState> = {
  [MutationsOrderLabels.SAVE_ACCOUNTS](state, accounts: OrderLabelGroup[]) {
    state.accounts = accounts;
  },

  [MutationsOrderLabels.SAVE_MARKETPLACE](state, { id, name }: PayloadSaveMarketplace) {
    const accounts = state.accounts.filter((current) => {
      return current.account.marketplace_id.toString() === id.toString();
    });

    state.marketplaces.push({
      id,
      name,
      accounts,
    });
  },

  [MutationsOrderLabels.UPDATE_ACCOUNTS_IN_MARKETPLACES](state) {
    if (state.accounts.length === 0) return;

    state.accounts.forEach((currentAccount: OrderLabelGroup) => {
      const marketplaceIndex = state.marketplaces.findIndex((marketplace) => {
        return marketplace.id.toString() === currentAccount.account.marketplace_id.toString();
      });

      if (marketplaceIndex === -1) return;

      const accountIndex = state.marketplaces[marketplaceIndex].accounts.findIndex((current) => {
        return current.account.id === currentAccount.account.id;
      });

      if (accountIndex === -1) return;

      /** Resolve a reatividade dos dados em profundidade e não pode ser removido */
      const newObject = Object.assign({}, currentAccount);
      Vue.set(state.marketplaces[marketplaceIndex].accounts, accountIndex, newObject);
    });
  },

  [MutationsOrderLabels.SET_ERROR](state, hasErrors) {
    state.hasErrors = hasErrors;
  },

  [MutationsOrderLabels.SET_BATCH_STATUS](state, batchStatus: string) {
    state.batchStatus = batchStatus;
  },

  [MutationsOrderLabels.SET_BATCH_ID](state, batchId: string) {
    state.batchId = batchId;
  },

  [MutationsOrderLabels.SET_LOADING](state, isLoading: boolean) {
    state.isLoading = isLoading;
  },

  [MutationsOrderLabels.CLEAR_ALL](state) {
    state.batchId = '';
    state.batchStatus = 'pending';
    state.accounts = [];
    state.marketplaces = [];
    state.hasErrors = false;
    state.isLoading = true;
  },
};
