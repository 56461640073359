export enum ActionQuantityOfList {
  /**
   * Obtém as quantidade para os destaques e abas de anúncios.
   *
   * @param withNotificationInPage - Se deve emitir uma notificação de atualização na
   * página.
   * TODO: adicionado temporariamente para solução das quantidades de abas
   */
  OFFERS = 'OFFERS',

  /**
   * Obtém as quantidade de vendas/pedidos.
   */
  SALES = 'SALES',

  /**
   * Obtém as quantidade de todas abas
   */
  ALL = 'ALL',

  /**
   * Se a página atual tiver uma alteração na quantidade das abas, emite uma notificação
   * para o usuário atualizar a página.
   *
   * @param oldValueTabs - quantidade antiga das abas.
   */
  NOTIFY_CURRENT_PAGE_UPDATE_BY_OLD_VALUES = 'NOTIFY_CURRENT_PAGE_UPDATE_BY_OLD_VALUES',

  /**
   * Atualiza todas as quantidades das listas de anúncios e emite uma notificação de
   * atualização caso a página atual tenha sido alterada.
   */
  UPDATE_OFFERS = 'UPDATE_OFFERS',

  /**
   * Atualiza todas as quantidades das listas de vendas/pedidos e emite uma notificação de
   * atualização caso a página atual tenha sido alterada.
   */
  UPDATE_SALES = 'UPDATE_SALES',
}
