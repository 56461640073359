import { Module } from 'vuex';

import { MarketplaceState } from './types';
import { RootState } from '../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';

export const state: MarketplaceState = {
  marketplaces: {},
  activeMarketplaceRef: 'shopee',
  activeMarketplace: null,
};

const namespaced = true;

export const marketplaceModule: Module<MarketplaceState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
