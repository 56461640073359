import { GetterTree } from 'vuex';

import { RootState } from '../../../../types';
import { ShopeeReviewState } from './types';

export const getters: GetterTree<ShopeeReviewState, RootState> = {
  /**
   * Retorna o estado do componente de seleção de categorias.
   *
   * @param state
   * @return {ShopeeReviewState['categoryPicker']}
   * */
  categoryPicker(state): ShopeeReviewState['categoryPicker'] {
    return state.categoryPicker;
  },
};

export default getters;
