/**
 * Actions da Vuex no módulo de store.
 */
export enum ActionsStore {
  /**
   * Busca a loja pelo id fornecido.
   *
   * @param payload.storeId - Id da loja.
   * @param payload.forceLoad - Força o carregamento da loja.
   */
  BY_ID = 'BY_ID',

  /**
   * Verifica se a loja da plataforma existe.
   *
   * @param payload.storeId - Id da loja.
   * @param payload.platform - Plataforma da loja.
   */
  CHECK_IF_EXISTS = 'CHECK_IF_EXISTS',
}
