import { FilterByRouteState, FilterURL } from './types';
import getters from './getters';
import { mutations } from './mutations';
import { PagesName } from '@/router/routes/pages-enum';
import { emptyFiltersPublishedOffers } from '../published-offers/list';
import { emptyFiltersPendingSales } from '../offers-pending-sales';
import { emptyFiltersWaitingCorrection } from '../offers-waiting-correction/list';

/**
 * Módulo de filtros por rota, armazena todos os filtros atuais de acordo com a rota.
 */
export const state: FilterByRouteState = {
  route: {
    [PagesName.PUBLISHED_OFFERS]: {
      ...emptyFiltersPublishedOffers,
    } as FilterURL,
    [PagesName.PENDING_SALES]: {
      ...emptyFiltersPendingSales,
    } as FilterURL,
    [PagesName.WAITING_CORRECTION]: {
      ...emptyFiltersWaitingCorrection,
    } as FilterURL,
  },
};

const namespaced = true;

export const FilterByRouteModule = {
  namespaced,
  state,
  getters,
  mutations,
};
