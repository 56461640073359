import { RouteConfig } from 'vue-router';
import { PagesName } from '../../pages-enum';
import { CheckAccount } from '../offers/guards';

const routesConfiguration: RouteConfig[] = [
  {
    path: 'configurations',
    name: 'configurations',
    meta: {
      title: 'Configurações',
      flow: 'new-flow',
    },
    redirect: { name: PagesName.ACCOUNTS },
    component: () => import('@/pages/configurations/configurations.vue'),
    beforeEnter: CheckAccount,
    children: [
      {
        path: 'accounts',
        name: PagesName.ACCOUNTS,
        component: () => import('@/pages/configurations/accounts/accounts.vue'),
        meta: {
          title: 'Configurações',
          flow: 'new-flow',
        },
      },
      {
        path: 'category-relationship',
        name: PagesName.CATEGORY_RELATIONSHIP,
        meta: {
          title: 'Configurações',
          flow: 'new-flow',
        },
        component: () =>
          import('@/pages/configurations/category-relationship/category-relationship.vue'),
      },
    ],
  },
];

export default routesConfiguration;
