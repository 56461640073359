import { RootState } from '@/store/types';
import { WaitingCorrectionListState } from './types';
import { Module } from 'vuex';
import getters from './getters';
import actions from './actions';
import mutations from './mutations';

export const emptyFiltersWaitingCorrection = {
  title: '',
  store: '',
  code: '',
  status: '',
};

/**
 * Estado vuex do módulo de ofertas publicadas pertencentes ao new-flow.
 */
export const state: WaitingCorrectionListState = {
  list: [],
  optionsStatus: [],
  isLoading: false,
  offerWithError: null,
  offerWithErrorHasActions: false,
  offerWithErrorCanSave: false,
  offerErrors: [],
  offerVariants: [],
  offerNewTitle: null,
  offerRemoveModal: null,
};

const namespaced = true;

export const ListOffersModule: Module<WaitingCorrectionListState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
};
