import { VueConstructor } from 'vue';
import { Filter } from './filter';

export class CPFFilter implements Filter {
  install(vue: VueConstructor): void {
    /**
     * Pipeline para cpf.
     *
     * @example {{ text | cpf }}
     * */
    vue.filter('cpf', function (value: string) {
      if (!value) {
        return;
      }
      return value.replace(/\D/g, '').replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4');
    });
  }
}

export default CPFFilter;
