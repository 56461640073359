import { QueryParamsForApiRequest } from '@/components/handle-listing/handle-query-params/enum-query-params-api-request';
import {
  FilterList,
  PaginateList,
  ValuesOfOptions,
} from '@/store/modules/categories/type-categories-list';

export const generatesQueryParamsForCategoryList = (
  paging?: PaginateList,
  filter?: FilterList
): URLSearchParams => {
  const queryParams = new URLSearchParams();

  if (paging) {
    const { size, number } = paging;

    if (number) {
      queryParams.append(QueryParamsForApiRequest.PAGE, String(number));
    }

    if (size) {
      queryParams.append(QueryParamsForApiRequest.SIZE, String(size));
    }
  }

  if (filter) {
    const { word, optionSelected, categoryName } = filter;

    if (word) {
      queryParams.append(QueryParamsForApiRequest.FULLPATH, String(word));
    }

    if (optionSelected.value === ValuesOfOptions.IS_LINKED) {
      queryParams.append(QueryParamsForApiRequest.IS_LINKED, '1');
    }

    if (optionSelected.value === ValuesOfOptions.NOT_LINKED) {
      queryParams.append(QueryParamsForApiRequest.IS_LINKED, '0');
    }

    if (categoryName) {
      queryParams.append(QueryParamsForApiRequest.FULLPATH, categoryName);
    }
  }

  return queryParams;
};
