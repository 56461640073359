import { RootState } from '@/store/types';
import { ActionTree } from 'vuex';
import Vue from 'vue';
import { OrderLabelsState, PayloadStartBatch } from './types';
import { ActionOrderLabels } from './actions.enum';
import { HubApiResponse } from '@/components/api/hub-client';
import { MutationsOrderLabels } from './mutations.enum';
import OrderLabelBatchNew from '@/entities/order-label-batch-new';
import { Marketplace } from '@/entities';
import { GetterOrderLabels } from './getters-enum';

export const actions: ActionTree<OrderLabelsState, RootState> = {
  async [ActionOrderLabels.GET_ALL_MARKETPLACES]({ state, commit }) {
    try {
      const allMarketplacesId = state.accounts.map((current) => {
        return current.account.marketplace_id;
      });
      const uniqueMarketplaces = Array.from(new Set(allMarketplacesId));

      const client = Vue.$apiClient.getHttpClient();
      await Promise.all(
        uniqueMarketplaces.map(async (id) => {
          const name = await client
            .get<Marketplace, HubApiResponse>(`/marketplaces/${id}`)
            .then((response) => {
              return response.getData().name;
            })
            .catch(() => {
              throw new Error(`Marketplace ${id} not found`);
            });

          commit(MutationsOrderLabels.SAVE_MARKETPLACE, {
            id,
            name,
          });
        })
      );
    } catch (error) {
      commit(MutationsOrderLabels.SET_ERROR, true);
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action GET all-marketplaces',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    }
  },

  async [ActionOrderLabels.START_BATCH_BY_PAYLOAD](
    { commit, dispatch, state },
    { orders, findBy, storeId }: PayloadStartBatch
  ) {
    try {
      commit(MutationsOrderLabels.SET_LOADING, true);
      commit(MutationsOrderLabels.SET_ERROR, false);

      if (state.accounts.length > 0) return;

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.post<OrderLabelBatchNew, HubApiResponse>(
        `/stores/${storeId}/orders-labels-batches`,
        {
          orders,
          find_by: findBy,
        }
      );

      const batch = OrderLabelBatchNew.from(response.getData());

      commit(MutationsOrderLabels.SET_BATCH_ID, batch.id);
      commit(MutationsOrderLabels.SET_BATCH_STATUS, batch.status);
      commit(MutationsOrderLabels.SAVE_ACCOUNTS, batch.groups);
      commit(MutationsOrderLabels.UPDATE_ACCOUNTS_IN_MARKETPLACES);

      await dispatch(ActionOrderLabels.GET_ALL_MARKETPLACES);

      return batch;
    } catch (error) {
      commit(MutationsOrderLabels.SET_ERROR, true);
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action START batch',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );

      return null;
    } finally {
      commit(MutationsOrderLabels.SET_LOADING, false);
    }
  },

  async [ActionOrderLabels.UPDATE_BATCH]({ commit, rootGetters, getters }) {
    try {
      const storeId = rootGetters['store/store'].id;
      const batchId = getters[GetterOrderLabels.BATCH_ID];

      const response = await Vue.$apiClient
        .getHttpClient()
        .get<
          OrderLabelBatchNew,
          HubApiResponse
        >(`/stores/${storeId}/orders-labels-batches/${batchId}`);
      const batch = response.getData();

      commit(MutationsOrderLabels.SET_BATCH_STATUS, batch.status);
      commit(MutationsOrderLabels.SAVE_ACCOUNTS, batch.groups);
      commit(MutationsOrderLabels.UPDATE_ACCOUNTS_IN_MARKETPLACES);
    } catch (error) {
      commit(MutationsOrderLabels.SET_ERROR, true);
      commit(
        'uiSettings/setNotification',
        {
          where: 'Action GET batch by id',
          error,
          toaster: 'b-toaster-top-center',
        },
        { root: true }
      );
    }
  },
};
