import { IdentifyPathCategory } from '@/store/modules/categories/types';
import Entity from './entity';

export default class Category extends Entity<Category> {
  /**
   * Identificador único da categoria.
   *
   * Disponível em algumas requisições como na da listagem de categorias.
   *
   * @var {number} id
   * */
  id?: number;

  /**
   * Referência da categoria.
   *
   * @var {string} ref
   * */
  ref!: string;

  /**
   * Referência do pai da categoria.
   *
   * @var {string} parent_ref
   * */
  parent_ref!: null | string;

  /**
   * Nome tratado da categoria.
   *
   * @var {string} name
   * */
  name!: string;

  /**
   * Caminho da categoria.
   *
   * @var {string} fullpath
   * */
  fullpath!: string;

  /**
   * Nome original da categoria.
   *
   * @var {string} original_name
   * */
  original_name!: string;

  /**
   * Exibe se a categoria está linkada.
   *
   * @var {boolean} is_linked
   * */
  is_linked!: boolean;

  /**
   * Exibe a qual categoria está linkada.
   *
   * @var {Category} linked_category
   * */
  linked_category!: null | Category;

  /**
   * Caminho da categoria até a raiz.
   *
   * @var {Path} path_from_root
   * */
  path_from_root!: Array<IdentifyPathCategory>;

  /**
   * Valida se a categoria possui filhos.
   *
   * @returns {boolean}
   */
  has_children!: boolean;
}
