import { AxiosRequestConfig } from 'axios';
import { Client } from '../../contracts/client';
import { RequestInterceptor } from '../../contracts';

export class Authorization implements RequestInterceptor {
  /**
   * Adiciona o token de autorização
   * */
  handle(client: Client, config: AxiosRequestConfig): AxiosRequestConfig {
    const token = client.getStore().getters['auth/token'];

    if (token) {
      if (!config.headers) {
        config.headers = {};
      }
      config.headers.Authorization = `Bearer ${token}`;
    }

    return config;
  }
}

export default Authorization;
