import { RouteConfig } from 'vue-router';
import { wizardLegacyRoutes } from '@/router/routes/legacy-routes';
import { ValidatesWizardStep } from './guards/validates-wizard-step';
import { PagesName } from '../../pages-enum';

const routes: RouteConfig[] = [
  {
    name: 'wizard',
    path: 'wizard',
    component: () => import('@/pages/wizard/wizard-new/wizard.vue'),
    beforeEnter: ValidatesWizardStep,
    redirect: 'wizard/auth',
    meta: {
      title: 'Wizard',
    },
    children: [
      {
        name: PagesName.WIZARD_AUTH,
        path: 'auth',
        component: () =>
          import('@/pages/wizard/wizard-new/wizard-steps/authorization/authorization.vue'),
      },
      {
        name: PagesName.WIZARD_STATUS,
        path: 'status',
        component: () =>
          import('@/pages/wizard/wizard-new/wizard-steps/account-connect/account-connect.vue'),
      },
      {
        name: PagesName.WIZARD_IMPORT,
        path: 'import',
        component: () =>
          import('@/pages/wizard/wizard-new/wizard-steps/offers-importing/offers-importing.vue'),
      },
      {
        name: PagesName.WIZARD_PRICE_SYNC,
        path: 'price-sync',
        component: () => import('@/pages/wizard/wizard-new/wizard-steps/price-sync/price-sync.vue'),
      },
      {
        name: PagesName.WIZARD_RELATE,
        path: 'relate',
        component: () => import('@/pages/wizard/wizard-new/wizard-steps/relation/relation.vue'),
      },
      ...wizardLegacyRoutes,
    ],
  },
];

export default routes;
