import { endpoints } from './endpoints';
import { ApiResponse } from '@/components/api/client/http';

import { ParamsBindingByRefsAndPage } from './types/params-binding-by-refs-and-page';
import { ApiBinding } from './types/api-binding';
import Vue from 'vue';
import { BindingAttribute } from '@/store/modules/categories/types';

/**
 * Classe com os métodos de API para obter e salvar os atributos que são as
 * características do marketplace e plataforma.
 */
export class CategoryAttributesBinding {
  /**
   * Identificador do marketplace ativo.
   */
  protected marketplaceId: string | null = Vue.$apiClient
    .getStore()
    .getters['marketplace/activeMarketplace']?.id.toString();

  protected client = Vue.$apiClient.getHttpClient();

  /**
   * Busca todas as características já relacionadas entre marketplace e plataforma
   * por página "quando nenhuma página for informada busca a primeira" e por refs
   *
   * @param params - Dados para buscar as características por referência e página desejada
   * @returns {Promise<ApiBinding[]>} - Retorna a lista de características relacionadas
   */
  async allByRefsAndPage(params: ParamsBindingByRefsAndPage): Promise<ApiBinding[]> {
    if (!this.marketplaceId) throw new Error('Marketplace ID not found');

    const { marketplace_attribute_ref, platform_attribute_ref, page } = params;
    const apiUrl = endpoints.attributesBinding.replace(':marketplaceId', this.marketplaceId);

    return this.client
      .get<ApiBinding[], ApiResponse>(apiUrl, {
        params: {
          'page[number]': page || 1,
          'page[size]': 50,
          'filter[marketplace_attribute_ref]': marketplace_attribute_ref,
          'filter[platform_attribute_ref]': platform_attribute_ref,
        },
      })
      .then((response) => {
        return response.getData();
      });
  }

  /**
   * Salva o relacionamento de atributos
   *
   * @param data - Dados para salvar o relacionamento de atributos
   * @returns {Promise<ApiBinding>} - Retorna o relacionamento de atributos salvo
   */
  async saveBinding(data: BindingAttribute): Promise<ApiBinding> {
    if (!this.marketplaceId) throw new Error('Marketplace ID not found');

    const apiUrl = endpoints.attributesBinding.replace(
      ':marketplaceId',
      this.marketplaceId.toString()
    );

    return this.client.post<ApiBinding[], ApiResponse>(apiUrl, data).then((response) => {
      return response.getData();
    });
  }
}
