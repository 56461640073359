import Vue from 'vue';
import { ActionTree } from 'vuex';
import { RootState } from '@/store/types';
import { HighlightsState } from './types';
import { Marketplace } from '@/entities';

export const actions: ActionTree<HighlightsState, RootState> = {
  /**
   * Busca o valor atualizado dos highlights.
   *
   * */
  async loadHighlights({ commit, rootGetters }) {
    const activeMarketplace = rootGetters['marketplace/activeMarketplace'] as Marketplace;
    return Vue.$apiClient
      .getHttpClient()
      .get(`/offers-highlights`, {
        params: {
          'filter[marketplace]': activeMarketplace.id,
        },
      })
      .then((response) => {
        const { active, unrelated, paused, under_review } = response.data.data;
        const highlights = { active, paused, under_review, unrelated };
        commit('setHighlightsCounts', highlights);
      });
  },
};

export default actions;
