import { Installer, Locale } from './installer';

export class ApexChartInstaller implements Installer {
  /**
   * @inheritDoc
   */
  getLocale(locale: Locale): Promise<any> {
    return import(`../locales/${locale}/apex-chart-locale.ts`);
  }

  /**
   *  @inheritDoc
   */
  setLocale(locale: Locale): void {
    const appWindow: any = window;

    if (!appWindow.Apex) {
      return console.error(
        'ApexChart não foi localizado, verifique o "main.ts", é necessário utilizar Vue.use(ApexChart) antes do ' +
          "Vue.use(LocalesInstaller, {locale: 'pt-br'});"
      );
    }

    this.getLocale(locale).then((res: any) => {
      appWindow.Apex.chart = {
        locales: [res.default],
        defaultLocale: 'pt-br',
      };
    });
  }
}

export default ApexChartInstaller;
