import { GetterTree } from 'vuex';

import { RootState } from '@/store/types';
import { Category, PricingRule, Product } from '@/entities';
import { CreateOfferState, SaleChannel } from './types';
import { Getters } from './enum';
import { ConfigPricingRuleToSaveAPI, PricingConfigSummary } from '@/components/pricing-rule/type';

export const getters: GetterTree<CreateOfferState, RootState> = {
  [Getters.SALE_CHANNEL](state): SaleChannel {
    return state.saleChannel;
  },

  [Getters.CATEGORIES_CREATE_OFFERS](state): Array<Category> {
    return state.selectedCategories;
  },

  [Getters.PRODUCTS_CREATE_OFFERS](state): Array<Product> {
    return state.selectedProducts;
  },

  [Getters.GET_CONFIG_PRICING](state): ConfigPricingRuleToSaveAPI | null {
    return state.configPricingEdit;
  },

  [Getters.GET_PRECIFICATION_ACCOUNT_SELECTED](state): PricingRule | null {
    return state.pricing;
  },

  [Getters.GET_PRECIFICATION_EDIT](state): PricingRule | null {
    return state.pricingEdit;
  },

  [Getters.GET_CONFIG_SUMMARY_CREATE_OFFER](state): PricingConfigSummary {
    return state.configPricingSummary;
  },

  [Getters.GET_ERROR_LOAD_PRICING](state): boolean {
    return state.errorLoadPricing;
  },
};

export default getters;
