import { CNPJFilter } from './cnpj';
import { CPFFilter } from './cpf';
import { CurrencyFilter } from './currency';
import { DateFilter } from './date';
import { PhoneFilter } from './phone';
import { NumeralFilter } from './numeral';
import { CapitalizeWordsFilter } from './capitalize-words';
import { Filter } from './filter';

export {
  CNPJFilter,
  CPFFilter,
  CurrencyFilter,
  DateFilter,
  PhoneFilter,
  NumeralFilter,
  CapitalizeWordsFilter,
};

const filters: Array<Filter> = [
  new CapitalizeWordsFilter(),
  new CNPJFilter(),
  new CPFFilter(),
  new CurrencyFilter(),
  new DateFilter(),
  new PhoneFilter(),
  new NumeralFilter(),
];

export default filters;
