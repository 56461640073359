import { RootState } from '@/store/types';
import {
  QuantityTabsName,
  QuantityOfListsState,
  ResponseHighlightsOffers,
  ResponseHighlightsSales,
  QuantityOfTabs,
} from './types';
import { ActionTree } from 'vuex';
import { ActionQuantityOfList } from './enum-actions';
import Vue from 'vue';
import { ApiResponse } from '@/components/api/client/http';
import { MutationQuantityOfLists } from './enum-mutation';
import router from '@/router';

export const actions: ActionTree<QuantityOfListsState, RootState> = {
  async [ActionQuantityOfList.OFFERS](
    { commit, dispatch, rootGetters },
    withNotificationInPage = false
  ): Promise<void> {
    try {
      commit(MutationQuantityOfLists.SET_LOADING_HIGHLIGHTS, true);
      const activeMarketplace = rootGetters['marketplace/activeMarketplace'];

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<ResponseHighlightsOffers, ApiResponse>(
        `/offers-highlights`,
        {
          params: {
            'filter[marketplace]': activeMarketplace.id,
          },
        }
      );
      const data: ResponseHighlightsOffers = response.getData();

      if (withNotificationInPage) {
        dispatch(ActionQuantityOfList.UPDATE_OFFERS, data);
        return;
      }

      commit(MutationQuantityOfLists.SET_HIGHLIGHT_ACTIVE, data.active);
      commit(MutationQuantityOfLists.SET_HIGHLIGHT_PAUSED, data.paused);

      commit(MutationQuantityOfLists.SET_QUANTITY_OFFERS, data);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action GET quantity of offers', error },
        { root: true }
      );
    } finally {
      commit(MutationQuantityOfLists.SET_LOADING_HIGHLIGHTS, false);
    }
  },

  async [ActionQuantityOfList.SALES]({ commit, rootGetters }): Promise<void> {
    try {
      const activeMarketplace = rootGetters['marketplace/activeMarketplace'];

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<ResponseHighlightsSales, ApiResponse>(
        `/orders-dispatching-state-highlights`,
        {
          params: {
            'filter[marketplace]': activeMarketplace.id,
          },
        }
      );
      commit(
        MutationQuantityOfLists.SET_QUANTITY_OFFERS,
        response.getData() as ResponseHighlightsSales
      );

      const data: ResponseHighlightsSales = response.getData();

      if (
        typeof data.awaiting_offers_relations !== 'number' ||
        data.awaiting_offers_relations < 0
      ) {
        return;
      }

      commit(MutationQuantityOfLists.SET_QUANTITY_SALES, data);
      commit(MutationQuantityOfLists.SET_FORCE_SHOW_PENDING_SALES, false);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        { where: 'Action GET quantity of sales', error, onlyLog: true },
        { root: true }
      );

      commit(MutationQuantityOfLists.SET_FORCE_SHOW_PENDING_SALES, true);
    }
  },

  async [ActionQuantityOfList.ALL]({ commit, dispatch }): Promise<void> {
    commit(MutationQuantityOfLists.SET_LOADING, true);

    await Promise.all([
      dispatch(ActionQuantityOfList.OFFERS),
      dispatch(ActionQuantityOfList.SALES),
    ]).then(() => {
      commit(MutationQuantityOfLists.SET_LOADING, false);
    });
  },

  [ActionQuantityOfList.NOTIFY_CURRENT_PAGE_UPDATE_BY_OLD_VALUES](
    { commit, state },
    oldValueTabs: QuantityOfTabs
  ): void {
    const currentPage = router.currentRoute.name;
    if (!currentPage) return;
    const newValue = state.tabs[currentPage as QuantityTabsName];
    const oldValue = oldValueTabs[currentPage as QuantityTabsName];

    if (newValue !== oldValue && newValue >= 0) {
      commit(
        'uiSettings/setNotification',
        {
          title: 'Alterações disponíveis',
          message: 'A listagem atual sofreu mudanças. Por favor, recarregue a página.',
          variant: 'primary',
          toaster: 'b-toaster-top-center',
          appendToast: true,
          noAutoHide: true,
        },
        { root: true }
      );
    }
  },

  [ActionQuantityOfList.UPDATE_OFFERS](
    { commit, dispatch, state },
    data: ResponseHighlightsOffers
  ): void {
    const oldValues = Object.assign({}, state.tabs);

    commit(MutationQuantityOfLists.SET_HIGHLIGHT_ACTIVE, data.active);
    commit(MutationQuantityOfLists.SET_HIGHLIGHT_PAUSED, data.paused);

    commit(MutationQuantityOfLists.SET_QUANTITY_OFFERS, data);

    dispatch(ActionQuantityOfList.NOTIFY_CURRENT_PAGE_UPDATE_BY_OLD_VALUES, oldValues);
  },

  [ActionQuantityOfList.UPDATE_SALES](
    { commit, dispatch, state },
    data: ResponseHighlightsSales
  ): void {
    const oldValues = Object.assign({}, state.tabs);

    commit(MutationQuantityOfLists.SET_QUANTITY_SALES, data);

    dispatch(ActionQuantityOfList.NOTIFY_CURRENT_PAGE_UPDATE_BY_OLD_VALUES, oldValues);
  },
};
