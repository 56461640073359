import { ApexChartInstaller } from './apex-chart-installer';
import { FlatpickrInstaller } from './flatpickr-installer';
import { MomentInstaller } from './moment-installer';
import { NumeralInstaller } from './numeral-installer';
import { Installer } from './installer';

const installers: Array<Installer> = [
  new ApexChartInstaller(),
  new FlatpickrInstaller(),
  new MomentInstaller(),
  new NumeralInstaller(),
];

export { installers };
