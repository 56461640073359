import { RouteConfig } from 'vue-router';
import { wizard, catalog, offers, configurations } from './marketplace';
import { hubLegacyRoutes } from '@/router/routes/legacy-routes';
import { MarketplaceLoader } from './guards';
import { PagesName } from './pages-enum';
import { initializeAuth } from './guards/initialize-auth';
import { loadInitialPage } from './guards/load-initial-page';

const routes: Array<RouteConfig> = [
  {
    path: '',
    name: 'app',
    meta: {
      authRequired: true,
      storeRequired: true,
    },
    component: () => import('@/pages/main.vue'),
  },
  ...hubLegacyRoutes,
  {
    path: '/init',
    name: PagesName.INIT,
    component: () => import('@/pages/landing-page/landing-page.vue'),
    beforeEnter: initializeAuth,
  },
  {
    path: '/auth',
    name: PagesName.AUTH,
    meta: {
      authRequired: true,
      storeRequired: true,
    },
    beforeEnter: loadInitialPage,
  },
  {
    path: '/stores/:id/sales/orders-labels',
    name: PagesName.ORDERS_LABELS,
    meta: {
      authRequired: true,
      storeRequired: true,
      title: 'Geração de etiquetas de marketplaces',
    },
    component: () => import('@/pages/sales/orders-labels/orders-labels.vue'),
    props: (route) => ({
      storeId: route.params.id,
      orders: route.query.orders,
      findBy: route.query.find_by,
    }),
  },
  {
    path: '/lets-start',
    name: PagesName.LETS_START,
    component: () => import('@/pages/lets-start/lets-start.vue'),
  },
  {
    path: '/marketplaces',
    name: PagesName.MARKEPLACES_LIST,
    meta: {
      title: 'Marketplaces',
      authRequired: true,
      storeRequired: true,
    },
    component: () => import('@/pages/marketplaces-page/marketplaces-page.vue'),
  },
  {
    path: '/marketplaces/:marketplace',
    name: PagesName.MARKETPLACE_MAIN,
    meta: {
      authRequired: true,
      storeRequired: true,
    },
    beforeEnter: MarketplaceLoader,
    component: () => import('@/pages/main.vue'),
    redirect: { name: PagesName.LEGACY_PUBLISHED_OFFERS },
    children: [...wizard, ...catalog, ...offers, ...configurations],
  },
  {
    path: '/unauthorized',
    name: PagesName.UNAUTHORIZED,
    component: () => import('@/pages/unauthorized.vue'),
  },
  {
    path: '/internal-error',
    name: PagesName.INTERNAL_ERROR,
    component: () => import('@/pages/500-internal-error/500-internal-error.vue'),
  },
  {
    path: '*',
    name: PagesName.NOT_FOUND,
    component: () => import('@/pages/404-not-found/404-not-found.vue'),
    props: (route) => ({
      marketplaceNotFound: route.query.marketplaceNotFound,
    }),
  },
];

export default routes;
