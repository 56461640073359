import { GetterTree } from 'vuex';
import { RootState } from '@/store/types';
import { GetterHighlightsWaitingCorrection } from './enum-getter';
import { HighlightsOffersState } from './types';

export const getters: GetterTree<HighlightsOffersState, RootState> = {
  [GetterHighlightsWaitingCorrection.UNRELATED]: (state): number => state.unrelated,
  [GetterHighlightsWaitingCorrection.WITH_ISSUES]: (state): number => state.with_issues,
  [GetterHighlightsWaitingCorrection.FAILED]: (state): number => state.failures,
  [GetterHighlightsWaitingCorrection.IS_LOADING]: (state): boolean => state.isLoading,
};

export default getters;
