import { Module } from 'vuex';

import { OfferRelationState } from './types';
import { RootState } from '../../../types';
import { getters } from './getters';
import { actions } from './actions';
import { mutations } from './mutations';
import { productsModule } from './products';

export const state: OfferRelationState = {
  error: null,
  offer: null,
  product: null,
  relation: null,
  saveActionEnabled: true,
  relationStep: 'relation_view',
  optionsVariantsProduct: [],
};

const namespaced = true;

export const OfferRelationModule: Module<OfferRelationState, RootState> = {
  namespaced,
  state,
  getters,
  actions,
  mutations,
  modules: {
    products: productsModule,
  },
};
