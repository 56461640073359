import {
  PricingStrategy,
  ScopePricingRule,
  TypePricingRuleStrategySlug,
  TypePricingScope,
} from '@/entities/pricing-rule';
import PricingRule, { PricingRuleConfigAPI } from '@/entities/pricing-rule';

export enum MarginPricingValue {
  PERCENTAGE = 'percentage',
  NUMERIC = 'numeric',
}

export type MarginType = Lowercase<keyof typeof MarginPricingValue>;

export type CalcBaseType = 'cost_price' | 'sale_price';

export type PayloadPricing = {
  config: PricingRuleConfigAPI;
  strategySlug: PricingStrategy['slug'];
};

export enum StepSidebarPricingRule {
  CONFIG = 'config',
  SUMMARY = 'summary',
}

export type StepsPricingRule = Lowercase<keyof typeof StepSidebarPricingRule>;

/**
 * Tipos de exibição para a sidebar de regras de precificação.
 */
export enum TypeSidebarPricingRule {
  STANDARD = 'standard',
  OFFER = 'offer',
  MULTIPLE_OFFERS = 'multiple_offers',
}

export type TypesPricingRule = Lowercase<keyof typeof TypeSidebarPricingRule>;

/**
 * Configuração da regra de precificação emitida ao salvar a regra definida na sidebar.
 */
export interface ConfigPricingRuleToSaveAPI {
  title?: string;
  marketplace_id: number;
  strategy_id: number;
  config?: PricingRuleConfigAPI | null;
}

export interface PricingConfigSummary {
  scope_type: ScopePricingRule;
  strategy_slug?: TypePricingRuleStrategySlug;
  configToSave: ConfigPricingRuleToSaveAPI | null;
}

export interface PricingResolverDisplayConfig {
  sidebar_type: TypesPricingRule;
  strategy_slug: TypePricingRuleStrategySlug;
  scope_type: ScopePricingRule;
  configToSave: ConfigPricingRuleToSaveAPI | null;
}

/**
 * Configurações para a sidebar de regras de precificação.
 */
export interface SidebarPricingConfig {
  /** Obrigatório apenas em sidebar que deve possuir resumo */
  accountName?: string;
  /** Obrigatório apenas em sidebar que deve possuir resumo */
  marketplaceName?: string;
  /**
   * Obrigatório apenas em sidebar do tipo TypeSidebarPricingRule.MULTIPLE_OFFERS
   * Define a quantidade de anúncios selecionados que serão aplicadas a regra, valor
   * exibido na sidebar
   */
  quantityToApply?: number;
  marketplaceId: number;
  pricing: PricingRule;
}

export interface PayloadPricingRuleRequest
  extends Omit<ConfigPricingRuleToSaveAPI, 'config' | 'strategy_id'> {
  scope_type?: TypePricingScope;
  scope_ids: number[];
  strategy_id?: number | undefined;
  config?: PricingRuleConfigAPI | null | undefined;
}
