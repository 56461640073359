import Pusher from 'pusher-js';
import omit from 'lodash/omit';

import { Channel, WebSocket } from '../contracts';
import { PusherChannel } from './pusher-channel';
import { BroadcastingEntity } from '@/entities/contracts/broadcasting-entity';

type Channels<T> = { [key: string]: PusherChannel<T> };

export class PusherWebSocket<T> implements WebSocket<T> {
  /**
   * Instância do websocket do pacote Pusher js.
   *
   * @param {Pusher} pusher
   * */
  protected pusher: Pusher;

  /**
   * Lista de canais já resolvidos.
   *
   * @param {Channels<T>} channels
   * */
  protected channels: Channels<T> = {};

  /**
   * Prefixo dos canais.
   *
   * @param string|null prefix
   * */
  protected prefix?: string;

  /**
   * PusherWebSocket's constructor
   *
   * @param {Pusher} pusher
   * @param {string} prefix
   * */
  constructor(pusher: Pusher, prefix = '') {
    this.pusher = pusher;
    this.prefix = prefix;
  }

  /**
   * @inheritDoc
   * */
  scopeOf(entity: BroadcastingEntity | string): WebSocket<T> {
    const prefix = (() => {
      if (typeof entity === 'string') {
        return entity;
      }

      return entity.getChannelPrefix();
    })();
    return new PusherWebSocket(this.pusher, prefix);
  }

  /**
   * @inheritDoc
   * */
  subscribe(channel: string): Channel<T> {
    channel = this.resolveChannelName(channel);
    if (!this.channels[channel]) {
      this.channels[channel] = new PusherChannel(this.pusher.subscribe(channel));
    }

    return this.channels[channel];
  }

  /**
   * @inheritDoc
   * */
  unsubscribe(channel: string): void {
    channel = this.resolveChannelName(channel);
    if (!this.channels[channel]) {
      return;
    }

    this.channels[channel].getDispatcher().unsubscribe();
    this.channels = omit(this.channels, channel);
  }

  /**
   * @return string
   * */
  protected resolveChannelName(channel: string): string {
    if (!this.prefix) {
      return channel;
    }

    return `${this.prefix}-${channel}`;
  }
}
