import { GetterTree } from 'vuex';
import { PublishedOffersListState } from './types';
import { RootState } from '@/store/types';
import { GetterListPublishedOffers } from './enum-getter';
import Offer from '@/entities/offer-new';
import { PricingRule } from '@/entities';

export const getters: GetterTree<PublishedOffersListState, RootState> = {
  [GetterListPublishedOffers.LIST]: (state): Offer[] => state.list,

  [GetterListPublishedOffers.LIST_ALL_SELECTED]: (state): Offer[] => {
    return state.list.filter((item) => item.selected);
  },
  [GetterListPublishedOffers.IS_LOADING]: (state): boolean => {
    return state.isLoading;
  },

  [GetterListPublishedOffers.PRICING_TO_EDIT]: (state): PricingRule | null => {
    return state.pricing.toEdit;
  },

  [GetterListPublishedOffers.PRICING_IDS_OFFER]: (state): number[] | null => {
    return state.pricing.idsOffer;
  },

  [GetterListPublishedOffers.RELOAD_LIST]: (state): boolean => {
    return state.reloadList;
  },
};

export default getters;
