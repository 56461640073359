import { MutationTree } from 'vuex';
import {
  PayloadMutationNamesAccountMarketplace,
  PricingRuleState,
  PricingStrategyAPI,
} from './types';
import { MutationPricingRule } from './enum';
import { defaultValuesStatePricingRule } from '@/store/modules/pricing-rule/default-values-state-pricing-rule';
import {
  AdjustmentValueAPI,
  FixedPriceAPI,
  TypePricingRuleStrategySlug,
} from '@/entities/pricing-rule';
import { PricingRuleDisplayConfig } from '@/components/pricing-rule/pricing-configs.enum';
import {
  CalcBaseType,
  MarginType,
  PricingConfigSummary,
  StepsPricingRule,
} from '@/components/pricing-rule/type';
import { OfferPricingConfig } from '@/entities/offer-pricing';
import ConfigDefaultValues from './default-adjustment-values.json';

const mutations: MutationTree<PricingRuleState> = {
  [MutationPricingRule.SAVE_PRICING](state, pricing) {
    state.pricing = pricing;
  },

  [MutationPricingRule.SAVE_DISPLAY_CONFIG](state, slug: PricingRuleDisplayConfig) {
    state.displayConfig = slug;
  },

  [MutationPricingRule.DISABLED_BUTTON](state, status) {
    state.statusButtonNext = status;
  },

  [MutationPricingRule.SET_DEFAULT_VALUES](state) {
    const {
      pricing,
      statusButtonNext,
      displayConfig,
      accountName,
      marketplaceName,
      marketplaceId,
      quantityToApply,
      typeOfValueAdjustment,
    } = {
      ...defaultValuesStatePricingRule,
    };

    state.config.value = ConfigDefaultValues.value;
    state.config.type = ConfigDefaultValues.type as MarginType;
    state.config.calc_base = ConfigDefaultValues.calc_base as CalcBaseType;

    state.displayConfig = displayConfig;
    state.pricing = pricing;
    state.statusButtonNext = statusButtonNext;
    state.accountName = accountName;
    state.marketplaceName = marketplaceName;
    state.marketplaceId = marketplaceId;
    state.quantityToApply = quantityToApply;
    state.typeOfValueAdjustment = typeOfValueAdjustment;
  },

  [MutationPricingRule.SET_ADDITIONAL_VALUE](state, value: number) {
    state.config.value = value;
  },

  [MutationPricingRule.SET_ADDITIONAL_BASE_PRICE](state, value: OfferPricingConfig['calc_base']) {
    state.config.calc_base = value;
  },

  [MutationPricingRule.SET_CONFIG_ADJUSTMENT_VALUE](state, value: AdjustmentValueAPI) {
    state.config = value;
  },

  [MutationPricingRule.SET_ADDITIONAL_MARGIN_TYPE](state, value: MarginType) {
    state.config.type = value;
  },

  [MutationPricingRule.SET_CONFIG_FIXED_PRICE](state, config: FixedPriceAPI) {
    /** As datas de inicio e fim da promação e o fixed_sale_price é passado como null enquanto não tivermos a evolução da sidebar de regras de precificação */
    const configFixed: FixedPriceAPI = {
      fixed_price: config.fixed_price,
      fixed_sale_price: null,
      fixed_sale_starts_on: null,
      fixed_sale_ends_on: null,
    };
    state.config = configFixed;
  },

  [MutationPricingRule.SET_TYPE_OF_VALUE_ADJUSTMENT](
    state,
    value: Exclude<TypePricingRuleStrategySlug, 'disabled' | 'standard'>
  ) {
    state.typeOfValueAdjustment = value;
  },

  [MutationPricingRule.SET_LOADING](state, value: boolean) {
    state.isLoading = value;
  },

  [MutationPricingRule.SAVE_STRATEGIES](state, value: PricingStrategyAPI[]) {
    const strategies: Record<string | TypePricingRuleStrategySlug, PricingStrategyAPI> = {};
    state.strategies = value;

    value.forEach((strategy) => {
      strategies[strategy.slug] = strategy;
    });

    state.strategyBySlug = strategies;
  },

  [MutationPricingRule.SAVE_ACCOUNT_AND_MARKETPLACE](
    state,
    { accountName, marketplaceName }: PayloadMutationNamesAccountMarketplace
  ) {
    state.accountName = accountName;
    state.marketplaceName = marketplaceName;
  },

  [MutationPricingRule.SAVE_MARKETPLACE_ID](state, id: number) {
    state.marketplaceId = id;
  },

  [MutationPricingRule.SAVE_QUANTITY_TO_APPLY](state, quantity: number) {
    state.quantityToApply = quantity;
  },

  [MutationPricingRule.SET_STEP](state, step: StepsPricingRule) {
    state.step = step;
  },

  [MutationPricingRule.SET_CONFIG_SUMMARY](state, configSummary: PricingConfigSummary) {
    state.configSummary = configSummary;
  },
};

export default mutations;
