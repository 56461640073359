import { RootState } from '@/store/types';
import { HighlightsOffersState } from './types';
import { ActionTree } from 'vuex';
import { ActionHighlightsWaitingCorrection } from './enum-actions';
import { handlerErrorActions } from '@/store/utils/handler-error-actions';
import Vue from 'vue';
import { ApiResponse } from '@/components/api/client/http';
import { MutationHighlightsWaitingCorrection } from './enum-mutation';

export const actions: ActionTree<HighlightsOffersState, RootState> = {
  async [ActionHighlightsWaitingCorrection.GET_ALL]({ commit, rootGetters }): Promise<any> {
    try {
      commit(MutationHighlightsWaitingCorrection.SET_LOADING, true);
      const activeMarketplace = rootGetters['marketplace/activeMarketplace'];

      const client = Vue.$apiClient.getHttpClient();
      const response = await client.get<any, ApiResponse>(`/offers-errors-highlights`, {
        params: {
          'filter[marketplace]': activeMarketplace.id,
        },
      });

      const { failures, unrelated, with_issues } = response.getData();

      commit(MutationHighlightsWaitingCorrection.SET_UNRELATED, unrelated);
      commit(MutationHighlightsWaitingCorrection.SET_FAILED, failures);
      commit(MutationHighlightsWaitingCorrection.SET_WITH_ISSUES, with_issues);
    } catch (error) {
      commit(
        'uiSettings/setNotification',
        handlerErrorActions({ where: 'Action GET Highlights Published Offers', error }),
        { root: true }
      );
    } finally {
      commit(MutationHighlightsWaitingCorrection.SET_LOADING, false);
    }
  },
};
