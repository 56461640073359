import { GetterTree } from 'vuex';
import { FilterByRouteState, FilterURL, RouteWithFilter } from './types';
import { RootState } from '@/store/types';
import { GetterFilterByRoute } from './enum-getter';

export const getters: GetterTree<FilterByRouteState, RootState> = {
  [GetterFilterByRoute.ALL_FILTERS]:
    (state) =>
    (nameOfRoute: RouteWithFilter): FilterURL | null => {
      return state.route[nameOfRoute] ?? null;
    },

  [GetterFilterByRoute.FILTER_BY_KEY]:
    (state) =>
    (params: { nameOfRoute: RouteWithFilter; key: string }): string | null => {
      const currentFilter = state.route[params.nameOfRoute];
      if (!currentFilter) return null;

      return currentFilter[params.key] ?? null;
    },
};

export default getters;
