import { Account } from '@/entities';
import vuex from '@/store';

/**
 * Obtém a atual conta ativa na loja por id, mas pegando o primeiro item da lista de
 * contas, solução provisória não compatível com multiplas contas.
 *
 * @param storeId - identificador da loja
 */
export async function getAllAccounts(): Promise<Account[] | null> {
  const getterAccounts = vuex.getters['account/accounts'] as Account[];

  if (!getterAccounts || getterAccounts.length === 0) {
    const accounts = await vuex.dispatch('account/fetchAll');

    return accounts;
  }

  return getterAccounts;
}
